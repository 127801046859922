import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { updateSoapNote, updateSoapNotes } from '../actions/soap-notes';
import { addInjurySoapNote, removeInjurySoapNote } from '../actions/injuries';

const getSoapNoteAsync = (userId, injuryId, soapId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/soap_notes/${soapId}`).then(({ data: results }) => {
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getSoapNotesAsync = (userId, injuryId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/soap_notes`).then(({ data: results }) => {
      dispatch(updateSoapNotes(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getFullSoapNotesAsync = (userId, injuryId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/soap_notes/full_index`).then(({ data: results }) => {
      dispatch(updateSoapNotes(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createSoapNoteAsync = (userId, injuryId, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'soap_notes', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/soap_notes`, data, { query }).then(({ data: results }) => {
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateSoapNoteAsync = (userId, injuryId, soapId, attributes) => {
  const data = { type: 'soap_notes', id: soapId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}/soap_notes/${soapId}`, data).then(({ data: results }) => {
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getArchivedSoapNotesAsync = (userId, injuryId) => {
  return dispatch => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/soap_notes/archived`).then(({ data: results }) => {
      dispatch(updateSoapNotes(userId, injuryId, results));
      return Promise.resolve(results);
    });
  };
};

const archiveSoapNoteAsync = (userId, injuryId, soapId) => {
  const data = { type: 'soap_notes' };

  return dispatch => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/soap_notes/${soapId}/archive`, data).then(({ data: results }) => {
      dispatch(removeInjurySoapNote(userId, injuryId, soapId));
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    });
  };
};

const unarchiveSoapNoteAsync = (userId, injuryId, soapId) => {
  const data = { type: 'soap_notes' };

  return dispatch => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/soap_notes/${soapId}/unarchive`, data).then(({ data: results }) => {
      dispatch(addInjurySoapNote(userId, injuryId, results));
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    });
  };
};

export {
  getSoapNoteAsync,
  getSoapNotesAsync,
  createSoapNoteAsync,
  updateSoapNoteAsync,
  getFullSoapNotesAsync,
  getArchivedSoapNotesAsync,
  archiveSoapNoteAsync,
  unarchiveSoapNoteAsync
};
