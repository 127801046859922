import React, { useCallback } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Icon from '@/components/icon';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { confirmation } from '@/components/confirmation';
import api from '@/lib/api-request';
import { showAlert } from '@/components/alert-notifications';
import { getMethodTypeLabel, getVerificationStatusLabel, METHOD_TYPE_ICON } from '../utils';
import Strings from '../lang';

const STATUS_ICON = {
  verified: 'check-circle',
  pending: 'exclamation-triangle'
};

const MfaMethodCardHeader = ({ type, status }) => {
  return (
    <div className="mfa-method-header">
      <div className="method-title">
        <Icon name={METHOD_TYPE_ICON[type]} />&nbsp;&nbsp;
        <span>{getMethodTypeLabel(type)}</span>
      </div>
      <div className={classNames('status', status)}>
        <Icon name={STATUS_ICON[status]} />&nbsp;
        <span className="text-muted">{getVerificationStatusLabel(status)}</span>
      </div>
    </div>
  );
};

const UnverifiedMfaMethodCard = ({ method, onRemove, onVerifySetup }) => {
  return (
    <div className="mfa-method-card">
      <div className="mfa-method-card-inner">
        <MfaMethodCardHeader type={method.method_type} status="pending" />
        <div>
          {Strings.formatString(
            Strings.mfaSetupLabel,
            dayjs(method.created_at).format(DISPLAY_DATE_FORMAT)
          )}
        </div>
        <div className="mfa-method-card-actions">
          <div />
          <div>
            <button type="button" className="btn btn-danger btn-sm" onClick={onRemove}>
              <Icon name="trash-can" />&nbsp;&nbsp;
              <span>{Strings.removeLabel}</span>
            </button>
            <button type="button" className="btn btn-primary btn-sm" onClick={onVerifySetup}>
              <Icon name="check-circle" />&nbsp;&nbsp;
              <span>
                {Strings.formatString(
                  Strings.continueVerificationLabel,
                  getMethodTypeLabel(method.method_type)
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const VerifidMfaMethodCard = ({ method, onRemove }) => {
  const onConfirmRemoveDevices = useCallback(() => {
    api.auth.delete('/mfa/devices/clear').then(() => {
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3000,
        message: Strings.mfaDevicesRemovedMessage
      });
    }).catch(error => {
      showAlert('error', {
        dismissable: true,
        autoDismiss: 6000,
        message: Strings.formatString(Strings.mfaDevicesErrorMessage, error.message)
      });
    });
  }, []);

  const onClearTrustedDevices = useCallback(() => {
    confirmation(Strings.confirmRemoveDevicesMessage, {
      title: Strings.confirmRemoveDevicesTitle,
      className: 'mfa-remove-devices',
      confirmButtonTitle: Strings.confirmRemoveDeviceButton,
      onConfirm: onConfirmRemoveDevices
    });
  }, [onConfirmRemoveDevices]);

  return (
    <div className="mfa-method-card">
      <div className="mfa-method-card-inner">
        <MfaMethodCardHeader type={method.method_type} status="verified" />
        <div>
          {Strings.formatString(
            Strings.mfaAddedLabel,
            dayjs(method.created_at).format(DISPLAY_DATE_FORMAT)
          )}
        </div>
        {method.method_type === 'totp' && (
          <div>{Strings.recoveryCodesRemainingLabel}: {method.recovery_codes_remaining}</div>
        )}
        <div className="mfa-method-card-actions">
          <button type="button" className="edit-button" onClick={onClearTrustedDevices}>
            {Strings.removeTrustedDevicesLabel}
          </button>
          <button type="button" className="btn btn-danger btn-sm" onClick={onRemove}>
            <Icon name="trash-can" />&nbsp;&nbsp;
            <span>{Strings.removeLabel}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export const MfaMethodCard = ({ method, onRemove, onVerifySetup }) => {
  return (
    <>
      {method.verified_at ? (
        <VerifidMfaMethodCard method={method} onRemove={onRemove} />
      ) : (
        <UnverifiedMfaMethodCard
          method={method}
          onVerifySetup={onVerifySetup}
          onRemove={onRemove}
        />
      )}
    </>
  );
};
