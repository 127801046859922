import dayjs from 'dayjs';
import { batch } from 'react-redux';
import { getCurrentClinicId } from '@/tokens';
import { updateBaselineTest } from '../actions/baseline-tests';
import { removeUserBaselineTest, addUserBaselineTest, updateUserBaselineTest } from '../actions/user-baseline-tests';
import { getUserBaselineTestsAsync } from './user-baseline-tests';
import isBaselineExpired from '@/utilities/is-baseline-expired';
import api from '@/lib/api-request';

const createBaselineTestAsync = (userId, attributes, withImpact = false) => {
  const query = { current_clinic_id: getCurrentClinicId(), with_impact_code: withImpact };
  const data = { type: 'baselines', attributes };

  return (dispatch, getState) => {
    return api.v1.post(`/users/${userId}/baselines`, data, { query }).then(({ data: results }) => {
      batch(() => {
        const { userBaselineTests = [] } = getState();
  
        dispatch(updateBaselineTest(results));
  
        if (userBaselineTests?.[userId]?.length) {
          dispatch(addUserBaselineTest(userId, results));
        }
      });

      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getBaselineTestAsync = (userId, baselineId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/baselines/${baselineId}`).then(({ data: baseline }) => {
      dispatch(updateBaselineTest(baseline));
      return Promise.resolve(baseline);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateBaselineTestAsync = (userId, id, attributes) => {
  const data = { type: 'baselines', id, attributes };

  return (dispatch, getState) => {
    return api.v1.patch(`/users/${userId}/baselines/${id}`, data).then(({ data: baseline }) => {
      batch(() => {
        const { userBaselineTests = [] } = getState();
  
        dispatch(updateBaselineTest(baseline));
  
        if (userBaselineTests?.[userId]?.length) {
          dispatch(updateUserBaselineTest(userId, baseline));
        }
      });

      return Promise.resolve(baseline);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getActiveBaselineTestAsync = (userId) => {
  return (dispatch) => {
    return dispatch(getUserBaselineTestsAsync(userId)).then(baselines => {
      const active = baselines.find(baseline => !isBaselineExpired(baseline.created_at));
      if (!active) {
        return Promise.resolve({});
      }

      return dispatch(getBaselineTestAsync(userId, active.id));
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

/** default the beforeDate to the current date */
const getBaselineBeforeDateAsync = (userId, beforeDate = dayjs().format()) => {
  const query = { before_date: beforeDate };
  
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/baselines/comparison`, query).then(({ data: results }) => {
      dispatch(updateBaselineTest(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const archiveBaselineTestAsync = (userId, baselineId, reason) => {
  const data = { type: 'baselines', attributes: { reason } };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/baselines/${baselineId}/archive`, data).then(({ data: results }) => {
      dispatch(removeUserBaselineTest(userId, baselineId));
      dispatch(updateBaselineTest(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const unarchiveBaselineTestAsync = (userId, baselineId) => {
  const data = { type: 'baselines' };

  return (dispatch, getState) => {
    return api.v1.post(`/users/${userId}/baselines/${baselineId}/unarchive`, data).then(({ data: results }) => {
      const { userBaselineTests } = getState();

      if (userBaselineTests[userId]?.length > 0) {
        dispatch(addUserBaselineTest(userId, results));
      }

      dispatch(updateBaselineTest(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createBaselineTestAsync,
  getBaselineTestAsync,
  updateBaselineTestAsync,
  getActiveBaselineTestAsync,
  getBaselineBeforeDateAsync,
  archiveBaselineTestAsync,
  unarchiveBaselineTestAsync
};
