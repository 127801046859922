const EN = {
  timeoutLockTitle: 'Sign In',
  timeoutLockMessage: 'You have been signed out after a period of inactivity. Please sign in to continue.',
  manualLockTitle: 'Locked',
  manualLockMessage: 'The system has been locked. Please sign in to continue.',
  lockoutLinkText: 'Lock Screen',
  switchAccountsText: 'Switch Accounts',
  notUserText: 'Not {0}?',
  passwordPlaceholder: 'Password',
  mfaChallengeTitle: '2-Step Verification'
};

export default EN;
