import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Authentication, AuthenticationClinics } from '@/components/authentication';
import { Dashboard } from '@/components/dashboard';
import { Referral } from '@/components/referrals';
import { Authorization, ClinicFeatureBlock, ProtectedRoute } from './authorization';
import { InviteAccept } from '@/components/invite';
import TaxRates from '@/components/tax-rates';
import { Lockout } from '@/components/lockout';
import { PasswordReset, PasswordResetRequest } from '@/components/passwords';
import { StartPostInjuryAssessment } from '@/components/post-injury-assessment';
import PatientCheckIn from '@/components/patient-check-in';
import { ContinueBaseline, StartBaseline } from '@/components/baseline';
import { ClinicUserRegistration, ClinicUserMfaOnboarding } from '@/components/registration';
import ClinicRoutes from './clinics';
import RedirectRoutes from './redirect-routes';
import ResourcesRoutes from './resources';
import BagtagsRoutes from './bag-tags';
import MarketplaceRoutes from './marketplace';
import TeamLeadersRoutes from './team-leaders';
import UserInjuriesRoutes from './user-injuries';
import BaselineRoutes from './baselines';
import RegistrationRoutes from './registration';
import PageNotFound from './not-found-page';
import {
  AdminAndClinicRoleDescriptions,
  AdminRoleDescriptions,
  IDTRoleDescriptions,
  RoleDescriptions
} from '@/utilities/user-roles';
import {
  IdtClaims,
  IdtDischargedClaims,
  IdtClaim,
  IdtResources
} from '@/components/idt-claims';
import { UserProfile } from '@/components/user-profile';

const routes = [
  {
    index: true,
    element: <Navigate to="/login" replace />
  },
  {
    path: 'register/*',
    children: RegistrationRoutes
  },
  {
    path: 'invite/accept/:token',
    element: <InviteAccept />
  },
  {
    path: 'password/reset/*',
    children: [
      {
        index: true,
        element: <PasswordResetRequest />
      },
      {
        path: ':token',
        element: <PasswordReset />
      }
    ]
  },
  {
    path: 'login',
    element: <Authentication />
  },
  {
    path: '*',
    element: (
      <ProtectedRoute>
        <Lockout>
          <Outlet />
        </Lockout>
      </ProtectedRoute>
    ),
    children: [
      ...RedirectRoutes,
      {
        path: 'login/clinics',
        element: (
          <Authorization allowUnconfirmed allowUntrained>
            <AuthenticationClinics />
          </Authorization>
        )
      },
      {
        path: 'onboarding',
        element: (
          <Authorization
            allowUntrained
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicOwner,
              RoleDescriptions.ClinicFrontDesk,
              RoleDescriptions.ClinicStaff,
              RoleDescriptions.IDTClinician,
              RoleDescriptions.IDTFrontDesk
            ]}
          >
            <ClinicUserRegistration />
          </Authorization>
        )
      },
      {
        path: 'onboarding/two-step-verification',
        element: (
          <Authorization
            allowUntrained
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicOwner,
              RoleDescriptions.ClinicFrontDesk,
              RoleDescriptions.ClinicStaff,
              RoleDescriptions.IDTClinician,
              RoleDescriptions.IDTFrontDesk
            ]}
          >
            <ClinicUserMfaOnboarding />
          </Authorization>
        )
      },
      {
        path: 'dashboard',
        element: (
          <Authorization
            allowUntrained
            allowRoles={[
              ...AdminAndClinicRoleDescriptions,
              ...IDTRoleDescriptions
            ]}
            allowRolesForDeactivatedUser={[
              RoleDescriptions.ClinicOwner,
              RoleDescriptions.Specialist,
              RoleDescriptions.Clinician
            ]}
          >
            <Dashboard />
          </Authorization>
        )
      },
      {
        path: 'taxes',
        element: (
          <Authorization allowRoles={AdminRoleDescriptions}>
            <TaxRates />
          </Authorization>
        )
      },
      {
        path: 'account',
        element: (
          <Authorization
            allowDeactivated
            allowUntrained
            allowRoles={AdminAndClinicRoleDescriptions}
          >
            <UserProfile />
          </Authorization>
        )
      },
      {
        path: 'resources/*',
        element: (
          <Authorization
            allowRoles={[
              ...AdminRoleDescriptions,
              RoleDescriptions.Clinician,
              RoleDescriptions.ClinicOwner,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk,
              RoleDescriptions.ClinicStaff
            ]}
          >
            <ClinicFeatureBlock feature="resources" />
          </Authorization>
        ),
        children: ResourcesRoutes
      },
      {
        path: 'clinics/*',
        children: ClinicRoutes
      },
      {
        path: 'referrals/:referralId',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.ClinicFrontDesk,
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist
            ]}
          >
            <Referral />
          </Authorization>
        )
      },
      {
        path: 'axia/claims/*',
        element: (
          <Authorization
            allowUntrained
            allowRoles={[
              RoleDescriptions.ClinicFrontDesk,
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.IDTClinician,
              RoleDescriptions.IDTFrontDesk
            ]}
          >
            <ClinicFeatureBlock feature="idt" />
          </Authorization>
        ),
        children: [
          {
            index: true,
            element: <IdtClaims />
          },
          {
            path: 'discharged',
            element: <IdtDischargedClaims />
          },
          {
            path: 'resources',
            element: <IdtResources />
          },
          {
            path: ':claimId',
            element: <IdtClaim />
          }
        ]
      },
      {
        path: 'post-injury/start/*',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.ClinicFrontDesk
            ]}
          />
        ),
        children: [
          {
            index: true,
            element: <StartPostInjuryAssessment />
          },
          {
            path: 'check-in/:userId',
            element: <PatientCheckIn context="injury" />
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: 'baseline/*',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.BaselineTester,
              RoleDescriptions.ClinicStaff,
              RoleDescriptions.ClinicFrontDesk
            ]}
          />
        ),
        children: [
          {
            path: 'continue',
            element: (
              <Authorization
                allowRoles={[
                  RoleDescriptions.Clinician,
                  RoleDescriptions.BaselineTester,
                  RoleDescriptions.ClinicStaff
                ]}
              >
                <ContinueBaseline />
              </Authorization>
            )
          },
          {
            path: 'start/*',
            element: (
              <Authorization
                allowRoles={[
                  RoleDescriptions.Clinician,
                  RoleDescriptions.BaselineTester,
                  RoleDescriptions.ClinicFrontDesk
                ]}
              />
            ),
            children: [
              {
                index: true,
                element: <StartBaseline />
              },
              {
                path: 'check-in/:userId',
                element: <PatientCheckIn context="baseline" />
              }
            ]
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: 'users/:userId/*',
        children: [
          {
            path: 'injuries/*',
            element: (
              <Authorization
                allowRoles={[
                  RoleDescriptions.Clinician,
                  RoleDescriptions.Specialist,
                  RoleDescriptions.ClinicFrontDesk,
                  RoleDescriptions.ClinicStaff
                ]}
              />
            ),
            children: UserInjuriesRoutes
          },
          {
            path: 'baselines/*',
            children: BaselineRoutes
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: 'bagtags/*',
        children: BagtagsRoutes
      },
      {
        path: 'marketplace/*',
        children: MarketplaceRoutes
      },
      {
        path: 'users/leaders/*',
        children: TeamLeadersRoutes
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export default routes;
