import { UserSelector } from '@/redux/selectors';
import Store from '@/redux/store';
import api from '@/lib/api-request';
import isEmpty from '@/utilities/is-empty';
import log from '@/utilities/log';
import { userHasAdminRole } from '@/utilities/user-roles';
import Strings from './lang';

export const ProductCategories = {
  assessment_tools: Strings.assessmentToolsCategory,
  rehab_tools: Strings.rehabToolsCategory,
  vision_therapy: Strings.visionTherapyCategory,
  vestibular_therapy: Strings.vestibularTherapyCategory,
  vr_headset: Strings.vrHeadsetCategory,
  supplies: Strings.medicalSuppliesCategory,
  neck_rehab: Strings.neckRehabCategory,
  clinical_software: Strings.clinicalSoftwareCategory,
  sports: Strings.sportsEquipementCategory,
  hr_monitors: Strings.heartRateMonitorsCategory,
  fitness: Strings.fitnessEquipmentCategory,
  misc: Strings.miscellaneousCategory
};

export const filterParams = (params) => (
  Object
    .keys(params)
    .reduce((acc, key) => {
      return !isEmpty(params[key])
        ? { ...acc, [key]: params[key] }
        : acc;
    }, {})
);

export const getPage = (page) => {
  const safePage = parseInt(page || 1, 10) || 1;
  return safePage >= 1 ? safePage : 1;
};

export const sendEvent = (productId, type) => {
  const currentUser = UserSelector.getCurrentUser(Store.getState());

  if (userHasAdminRole(currentUser)) return;

  const data = { type: 'events', attributes: { name: type } };

  api.v1.post(`/marketplace/products/${productId}/event`, data, { deserialize: false }).catch(error => {
    log(`${type} event failed:`, error.message);
  });
};
