const UPDATE_MFA_METHOD = 'UPDATE_MFA_METHOD';
const REMOVE_MFA_METHOD = 'REMOVE_MFA_METHOD';

const updateMfaMethod = (userId, method) => ({
  type: UPDATE_MFA_METHOD,
  userId,
  method
});

const removeMfaMethod = (userId) => ({
  type: REMOVE_MFA_METHOD,
  userId
});

export {
  UPDATE_MFA_METHOD,
  updateMfaMethod,
  REMOVE_MFA_METHOD,
  removeMfaMethod
};
