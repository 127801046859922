import dayjs from 'dayjs';
import api from '@/lib/api-request';
import { updateCurrentUserId, updateUser } from '../actions/users';
import { addClinicUser } from '../actions/clinic-users';
import { setActivity, setAuthToken, updateCurrentClinicForUser } from '@/tokens';
import { INVITATION_URL } from '@/utilities/urls';

const formatClinicUser = (clinicId, user) => {
  const { 
    id, active, person, roles 
  } = user;
  const roleStrings = roles.reduce((acc, role) => {
    if (role.resource_type === 'Clinic' && String(role.resource_id) === String(clinicId)) {
      return [...acc, role.name];
    }
    return acc;
  }, []);

  return {
    id,
    active,
    person,
    roles: roleStrings
  };
};

const createInviteAsync = (userAttributes = {}) => {
  // Inject the invitation url into all invite create calls:
  const data = { type: 'users', attributes: { ...userAttributes, invitation_url: INVITATION_URL } };

  return (dispatch) => {
    return api.v1.post('/users/invitation', data).then(({ data: user }) => {
      const clinicId = userAttributes.clinic_id;
      const clinicUser = formatClinicUser(clinicId, user);
      dispatch(addClinicUser(clinicId, clinicUser));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const acceptInviteAsync = (attributes = {}) => {
  const data = { type: 'users', attributes };

  return (dispatch) => {
    return api.v1.patch('/users/invitation/accept', data).then(({ data: authUser }) => {
      const { auth_token, ...user } = authUser;
      setAuthToken(auth_token);
      setActivity(dayjs().valueOf());
      updateCurrentClinicForUser(user);
      dispatch(updateCurrentUserId(user.id));
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createInviteAsync,
  acceptInviteAsync
};
