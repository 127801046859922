import React, { useCallback, useState, useRef } from 'react';
import { FormComponent, EmailInputGroup } from '@/forms';
import Modal from '../modal';
import Strings from './lang';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import api from '@/lib/api-request';

const searchUserEmail = (attributes) => {
  return api.v1.get('/users/recover', attributes);
};

const AccountRetrievalForm = React.forwardRef(({
  disabled = false,
  onSubmit = () => { },
  onClose = () => {}
}, ref) => {
  const [search, setSearch] = useState({ value: '', valid: false });
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (search.valid) {
      onSubmit(search.value);
    }
  }, [onSubmit, search.valid, search.value]);

  return (
    <FormComponent ref={ref} onSubmit={handleSubmit}>
      <EmailInputGroup
        required
        className="form-group search-input-group"
        labelText={Strings.accountRetrievalLabel}
        inputProps={{
          className: 'form-control',
          placeholder: Strings.accountRetrievalInputPlaceholder,
          value: search.value
        }}
        onUpdate={(value, valid) => setSearch({ value, valid })}
        messageText={Strings.invalidEmailMessage}
        messageClassName="alert alert-danger"
        touched={submitted}
      />
      <div className="form-footer">
        <button
          type="button"
          className="btn btn-default"
          disabled={disabled}
          onClick={onClose}
        >
          {Strings.cancelButtonText}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={disabled}
        >
          {Strings.recoveryButtonText}
        </button>
      </div>
    </FormComponent>
  );
});

const AccountRetrievalModal = ({
  isOpen,
  clinicId,
  onSuccess = () => { },
  onClose = () => { }
}) => {
  const formRef = useRef();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback((search) => {
    setActivity(true);
    setError(null);

    searchUserEmail({ clinic_id: clinicId, search }).then(() => {
      setActivity(false);
      const message = Strings.formatString(
        Strings.accountRetrievalSuccessTextWithEmail,
        search
      );
      onSuccess(message);
      onClose();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [clinicId, onClose, onSuccess]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      static={activity}
      onClose={handleClose}
    >
      <Modal.Header>
        <h1>{Strings.accountRetrievalLabel}</h1>
      </Modal.Header>
      <Modal.Body>
        <Activity active={activity}>
          <ErrorBanner error={error} />
          <p>{Strings.accountRecoveryHelpText}</p>
          <AccountRetrievalForm
            ref={formRef}
            disabled={activity}
            onSubmit={handleSubmit}
            onClose={onClose}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

export default AccountRetrievalModal;

export const AccountRetrievalModalButton = ({
  className,
  clinicId,
  onSuccess,
  children
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        className={className}
        onClick={() => setOpen(true)}
      >
        {children}
      </button>
      <AccountRetrievalModal
        isOpen={open}
        clinicId={clinicId}
        onSuccess={onSuccess}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
