import api from '@/lib/api-request';
import { updateNotification, updateNotifications } from '../actions/notifications';
import { updateUser } from '../actions/users';

const updateNotificationAsync = (userId, notificationId, attributes) => {
  const data = { type: 'notification', id: notificationId, attributes };

  return (dispatch, getState) => {
    return api.v1.patch(`/users/${userId}/notifications/${notificationId}`, data).then(({ data: results }) => {
      const { users = {} } = getState();
      const user = users[userId];
      if (user) {
        user.unread_notification_count -= user.unread_notification_count > 0 ? 1 : 0;
        dispatch(updateUser(user));
      }
      dispatch(updateNotification(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getNotificationsAsync = userId => {
  return (dispatch, getState) => {
    return api.v1.get(`/users/${userId}/notifications`).then(({ data: results }) => {
      const { users = {} } = getState();
      const user = users[userId];
      if (user) {
        user.unread_notification_count = results.length;
        dispatch(updateUser(user));
      }

      dispatch(updateNotifications(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  updateNotificationAsync,
  getNotificationsAsync
};
