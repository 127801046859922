import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { confirmation } from '../../confirmation';
import { convertToGuardianAsync } from '@/redux/thunks/registration';
import { RegistrationPaths } from '@/paths';
import { showAlert } from '../../alert-notifications';
import Activity from '../../activity';
import { getRegistrationReturning } from '@/utilities/registration-session';
import Icon from '../../icon';
import { useMount } from '@/hooks';
import Strings from './lang';

const BANNER_VISIBLE_DELAY = 300;

const ReturningCreateFamilyAccountBanner = ({ visible = false, onConvert, onClose }) => (
  <div className={classnames('create-family-banner', { visible })}>
    <div className="content-inner">
      <div className="content-header">
        <Icon name="users" />
      </div>
      <div className="content">
        {Strings.convertToFamilyAccountBannerMessage}
        <button 
          type="button" 
          className="edit-button"
          onClick={onConvert}
        >
          {Strings.convertToFamilyAccountBannerAction}
        </button>
        <div className="close-button">
          <button type="button" className="edit-button" onClick={onClose}>
            <Icon name="xmark" />
          </button>
        </div>
      </div>
    </div>
  </div>
);

const getSharedAttributes = (user = {}) => {
  const { mailing_address } = user;
  const { 
    street_1,
    street_2,
    city,
    state,
    country,
    postal_code
  } = mailing_address || {};

  return {
    tos_accepted: true,
    ...(mailing_address ? {
      mailing_address_attributes: {
        street_1,
        street_2,
        city,
        state,
        country,
        postal_code
      }
    } : {})
  };
};

const ConvertToFamilyAccountHandler = ({
  user,
  children
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bannerVisible, setBannerVisible] = useState(false);
  const [activity, setActivity] = useState(false);

  const onConfirmConvert = useCallback(() => {
    setActivity(true);

    const attributes = getSharedAttributes(user);

    dispatch(convertToGuardianAsync(attributes)).then(() => {
      setActivity(false);

      navigate(RegistrationPaths.guardian.about.link, {
        replace: true,
        state: { convertFromPlayer: true }
      });
    }).catch(error => {
      window.scrollTo(0, 0);
      setActivity(false);

      showAlert('error', {
        dismissable: true,
        autoDismiss: 5000,
        message: Strings.formatString(
          Strings.accountConvertFailedMessage,
          error.message || ''
        )
      });
    });
  }, [dispatch, navigate, user]);

  useMount(() => {
    setTimeout(() => {
      setBannerVisible(!!getRegistrationReturning());
    }, BANNER_VISIBLE_DELAY);
  });

  return (
    <Activity active={activity}>
      <div className="row">
        <div className="col-md-12">
          {children}
        </div>
        <ReturningCreateFamilyAccountBanner
          visible={bannerVisible}
          onClose={() => setBannerVisible(false)}
          onConvert={() => {
            confirmation(Strings.convertToFamilyAccountConfirmMessage, {
              title: Strings.convertToFamilyAccountConfirmTitle,
              onConfirm: onConfirmConvert
            });
          }}
        />
      </div>
    </Activity>
  );
};

export default ConvertToFamilyAccountHandler;
