const EN = {
  mfaTitle: '2-Step Verification',
  totpTitleLabel: 'Authenticator App',
  emailTitleLabel: 'Email OTP',
  mfaEnableDescription: 'Add extra security to your account by enabling 2-Step Verification. It helps secure your account by requiring an additional verification method when you sign in.',
  totpFullDescription: 'Use an Authenticator App to get Time-based One-Time Passcodes (TOTP) verification codes on your mobile device.',
  totpShortDescription: 'Use an Authenticator App to get verification codes on your mobile device',
  emailFullDescription: 'Get a One-Time Passcode (OTP) verification code delivered to the email address on your account.',
  emailShortDescription: 'Get a One-Time Passcode delivered to the email address on your account',
  enableMfaButton: 'Enable 2-Step Verification',
  recommendedLabel: 'Recommended',
  skipMfaButton: 'Setup Later',
  statusVerified: 'Verified',
  statusPending: 'Not Verified',
  statusDisabled: 'Disabled',
  mfaSetupLabel: 'Set Up {0}',
  mfaAddedLabel: 'Added {0}',
  removeLabel: 'Remove',
  removingLabel: 'Removing',
  continueVerificationLabel: 'Verify {0}',
  removeTrustedDevicesLabel: 'Remove All Trusted Devices',
  recoveryCodesRemainingLabel: 'Recovery Codes Remaining',
  mfaDevicesRemovedMessage: 'Trusted devices removed successfully',
  mfaDevicesErrorMessage: 'Failed to remove trusted devices: {0}',
  confirmRemoveDevicesTitle: 'Remove All Trusted Devices',
  confirmRemoveDevicesMessage: 'This will remove any devices that you\'ve previously trusted. You will be required to complete a 2-Step Verification the next time you sign in on any of these devices.',
  confirmRemoveDeviceButton: 'Remove Devices',
  mfaSelectMethodTitle: 'Choose a 2-Step Verification Method',
  mfaSelectMethodDescription: 'When you sign in, we\'ll ask for this method of verification. Choose the method that works best for you.',
  cancelButtonText: 'Cancel',
  continueButtonText: 'Continue',
  authAppRegistrationStep1Title: 'Set up in Authenticator App',
  authAppRegistrationStep1Description: 'Scan the <strong>QR Code</strong> below using an authenticator app like Google Authenticator, Microsoft Authenticator, Authy, etc. If you can\'t scan the QR code, use the <strong>Secret Key</strong> option next to the QR code',
  separatorLabel: 'OR',
  secretKeyLabel: 'Secret Key',
  authAppRegistrationStep2Title: 'Enter code from Authenticator App',
  authAppRegistrationStep2Description: 'After scanning the QR Code above, enter the 6-digit code displayed for {0} into the MFA input below.',
  verifyLabel: 'Verify',
  verifyingLabel: 'Verifying',
  authAppVerificationCompleteTitle: 'Verification Complete',
  authAppVerificationCompleteMessage: 'Your account is now protected with 2-Step Verification using an Authenticator App. Anytime you sign in, we will ask for this 2-Step Verification method.',
  authAppRecoveryCodesMessage: 'If you lose access to your Authenticator App, you can use one of the following recovery codes to regain access to your account. Download or copy the recovery codes below and place them somewhere safe and secure.',
  authAppRecoveryCodesWarning: 'You will not be able to see or retrieve these codes after this point.',
  downloadRecoveryCodesLabel: 'Download Recovery Codes',
  recoveryCodesConfirmation: 'I have downloaded/copied the above recovery codes to a safe and secure location',
  doneText: 'Done',
  emailRegistrationCompleteTitle: 'Email OTP Enabled',
  emailRegistrationCompleteMessage: 'Your account is now protected with 2-Step Verification using Email One-Time Passcode (OTP). Anytime you sign in, we will ask for this 2-Step Verification method.',
  removeMfaMethodTitle: 'Remove 2-Step Verification',
  removeVerifiedMfaMethodMessage: 'To remove 2-Step Verification from your account, we need to first confirm your identity. Please enter your current password below.',
  passwordLabel: 'Password',
  invalidPasswordError: 'Invalid Password',
  removeMfaMethodConfirmationMessage: 'Are you sure you remove this 2-Step Verification method?',
  emailNotConfirmedErrorMessage: 'Your email address must be confirmed to select this method'
};

export default EN;
