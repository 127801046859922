import api from '@/lib/api-request';
import { updateTaxItem, updateTaxes, removeTaxItem } from '../actions/taxes';

const createTaxRateAsync = (attributes) => {
  const data = { type: 'tax_rates', attributes };

  return dispatch => {
    return api.v1.post('/tax_rates', data).then(({ data: tax }) => {
      dispatch(updateTaxItem(tax.id, tax));
      return Promise.resolve(tax);
    });
  };
};

const updateTaxItemAsync = (taxId, attributes) => {
  const data = { type: 'tax_rates', id: taxId, attributes };

  return dispatch => {
    return api.v1.patch(`/tax_rates/${taxId}`, data).then(({ data: tax }) => {
      dispatch(updateTaxItem(taxId, tax));
      return Promise.resolve(tax);
    });
  };
};

const getTaxRatesAsync = () => {
  return dispatch => {
    return api.v1.get('/tax_rates').then(({ data: taxes }) => {
      dispatch(updateTaxes(taxes));
      return Promise.resolve(taxes);
    });
  };
};

const deleteTaxItemAsync = (taxId) => {
  return dispatch => {
    return api.v1.delete(`/tax_rates/${taxId}`).then(() => {
      dispatch(removeTaxItem(taxId));
      return Promise.resolve();
    });
  };
};

export {
  createTaxRateAsync,
  updateTaxItemAsync,
  getTaxRatesAsync,
  deleteTaxItemAsync
};
