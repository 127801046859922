/* eslint-disable no-console */
const isDebug = process.env.CCMI_DEBUG === 'true';

class Logger {
  #logs;
  #context;

  constructor(context) {
    this.#logs = [];
    this.#context = context;
  }

  log(...args) {
    if (isDebug) {
      this.#logs.push(args);
    }
  }

  flush(success = true) {
    if (isDebug) {
      let color = '#3CBE3F';
      let group = console.groupCollapsed;
      if (!success) {
        color = '#DB231F';
        group = console.group;
      }

      group(`%c ${this.#context.method}`, `color: ${color}`, this.#context.url);
      this.#logs.map(log => console.log(...log));
      console.groupEnd();
    }
  }
}

export default Logger;
