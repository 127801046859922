const FR = {
  title: 'Créez votre profile',
  completeTitle: 'Complète ton profil',
  bookingTitle: 'Informations de réservation',
  firstNameLabelText: 'Prénom',
  firstNameErrorText: 'Veuillez indiquer votre prénom.',
  lastNameLabelText: 'Nom de famille',
  lastNameErrorText: 'Veuillez indiquer votre nom de famille.',
  phoneLabelText: 'Numéro de téléphone',
  phoneErrorText: 'Veuillez écrire un numéro de téléphone valide',
  languageLabelText: 'Langue',
  languageValidationMessage: 'Veuillez choisir votre langue de préférence.',
  bioLabelText: 'Biographie professionnelle',
  credentialsLabelText: 'Qualifications (appuyez sur Entrée pour ajouter une qualification)',
  addCredentialInputPlaceholder: '(par exemple, D.C., M.D.) Appuyez sur Entrée pour ajouter une qualification',
  professionLabelText: 'Métier',
  professionPlaceholder: 'Sélectionnez une profession',
  professionErrorMessage: 'Veuillez sélectionner votre profession',
  treatmentServicesLabelText: 'Services de traitement',
  treatmentServicesPlaceholder: 'Sélectionnez tout ce qui s\'y rapporte',
  submitButtonText: 'Continuez',
  completionModalMessage: 'Il vous manque quelques informations pour compléter votre profil.',
  credentialsText: 'Qualifications',
  profilePhotoLabel: 'Photo de profil',
  enableMfaTitle: 'Activer l\'authentification multifacteur'
};
 
export default FR;
