import React from 'react';
import Icon from '@/components/icon';
import Strings from '../lang';

export const MfaSetupView = ({ skippable = false, onSkip, onStartSetup }) => {
  return (
    <div className="mfa-setup-view">
      <div className="text-center">
        {Strings.mfaEnableDescription}
      </div>
      <div className="mfa-options">
        <div className="mfa-option">
          <Icon name="qrcode" fontSize={32} />
          <div className="mfa-option-details">
            <div className="mfa-option-title">
              {Strings.totpTitleLabel}
            </div>
            <div className="mfa-option-description">
              {Strings.totpShortDescription}
            </div>
          </div>
        </div>
        <div className="mfa-options-divider" />
        <div className="mfa-option">
          <Icon name="at" fontSize={32} />
          <div className="mfa-option-details">
            <div className="mfa-option-title">
              {Strings.emailTitleLabel}
            </div>
            <div className="mfa-option-description">
              {Strings.emailShortDescription}
            </div>
          </div>
        </div>
      </div>
      <div className="mfa-actions-btns">
        <button type="button" className="btn btn-primary" onClick={onStartSetup}>
          {Strings.enableMfaButton}
        </button>
        {skippable && (
          <button type="button" className="btn btn-default btn-link btn-sm" onClick={onSkip}>
            {Strings.skipMfaButton}
          </button>
        )}
      </div>
    </div>
  );
};
