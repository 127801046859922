import { getAuthToken } from '@/tokens';

const CLIENT_NAME = 'network';
const API_TYPE = 'application/vnd.api+json';

// __APP_VERSION__ is defined in webpack config from process.env.npm_package_version
// eslint-disable-next-line no-undef
const APP_VERSION = __APP_VERSION__;

const defaultGetAuthToken = () => {
  return getAuthToken();
};

export const buildHeaders = (options) => {
  const {
    client = CLIENT_NAME,
    contentType = API_TYPE,
    accept = API_TYPE,
    getAuthToken = defaultGetAuthToken,
    extraHeaders = {}
  } = options;

  const headers = {
    'X-CC-Client': `${client}/${APP_VERSION}`,
    'Content-Type': contentType,
    Accept: accept,
    ...extraHeaders
  };

  const token = getAuthToken();

  if (token) {
    headers.Authorization = token;
  }

  return headers;
};
