import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { withRegistrationStep } from '../step-wrappers';
import replaceLinkParams from '@/utilities/replace-link-params';
import { RegistrationPaths } from '@/paths';
import DecoupleChildAccountModal from './decouple-child-account-modal';
import AddExistingChildModal from './add-existing-child-modal';
import PlayerBaselineBadge from '../../player/player-baseline-badge';
import { sortAscending } from '@/utilities/sort';
import Strings from './lang';

const AccountItem = ({
  account = {},
  parentId,
  onSelect = () => {}
}) => {
  const { person, active, baseline_date } = account || {};
  const { first_name, last_name } = person || {};
  const [modalOpen, setModalOpen] = useState(false);

  if (!active) {
    return null;
  }

  return (
    <li className="account">
      <div className="account-info" onClick={onSelect}>
        {!!person && (
          <div className="account-name">
            <span>
              {first_name} {last_name}
            </span>&nbsp;
            <PlayerBaselineBadge date={baseline_date} />
          </div>
        )}
        <div className="account-number">
          {account.account}
        </div>
      </div>
      <button 
        type="button" 
        title={Strings.guardianDecoupleInfoTitle} 
        className="btn guardian-child-rm-btn" 
        onClick={() => setModalOpen(true)}
      >
        {Strings.guardiansDecoupleBtn}
      </button>
      <DecoupleChildAccountModal 
        isOpen={modalOpen}
        parentId={parentId}
        child={account}
        onClose={() => setModalOpen(false)}
      />
    </li>
  );
};

const AccountsList = ({ 
  accounts = [], 
  parentId,
  onSelect = (() => {}) 
}) => (
  <ul className="accounts-list">
    {accounts.map((account, index) => {
      return (
        <AccountItem 
          key={account.id}
          parentId={parentId}
          account={account}
          onSelect={() => onSelect(index)}
        />
      );
    })}
  </ul>
);

const AddExistingAccountBanner = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="alert alert-info" style={{ textAlign: 'center' }}>
      <div>
        {Strings.addExisitingAccountInfoText}
      </div>
      <button type="button" className="edit-button" onClick={() => setOpen(true)}>
        {Strings.addExistingAccountButtonText}
      </button>
      <AddExistingChildModal 
        isOpen={open}
        onClose={() => setOpen(false)}
      />
    </div>  
  );
};

const GuardianAccountShowLists = ({
  currentUser = {},
  next
}) => {
  const navigate = useNavigate();
  const accounts = useMemo(() => currentUser.children?.sort(sortAscending.bind(null, 'id')), [currentUser.children]);

  const routeToChildAbout = useCallback((param) => {
    navigate(replaceLinkParams(RegistrationPaths.guardian.child.index.link, {
      child: param
    }));
  }, [navigate]);

  const routeToAddChild = useCallback(() => {
    routeToChildAbout('add');
  }, [routeToChildAbout]);

  return (
    <div>
      <div className="guardian-account-lists">
        <h1 className="display text-center">
          {Strings.guardianListTitle}
        </h1>
        <AccountsList 
          parentId={currentUser.id}
          accounts={accounts}
          onSelect={routeToChildAbout}
        />
        <AddExistingAccountBanner />
        <div className="guardian-btn">
          <button 
            type="button" 
            className="btn btn-primary" 
            onClick={routeToAddChild}
          >
            {Strings.guardiansAddBtn}
          </button>
          <button 
            type="button" 
            className="btn btn-default" 
            onClick={next}
          >
            {Strings.guardiansDoneBtn}
          </button>
        </div>
        
      </div>
    </div>
  );
};

export default withRegistrationStep('children')(GuardianAccountShowLists);
