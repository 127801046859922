import { UPDATE_MFA_METHOD, REMOVE_MFA_METHOD } from '../actions/mfa-method';

const updateMfaMethodReducer = (state = {}, action = {}) => {
  const { method = {} } = action;
  return {
    ...(state || {}),
    ...method
  };
};

const removeMfaMethodReducer = () => {
  return null;
};

const mfaMethod = (state = null, action = {}) => {
  switch (action.type) {
    case UPDATE_MFA_METHOD:
      return updateMfaMethodReducer(state, action);
    case REMOVE_MFA_METHOD:
      return removeMfaMethodReducer();
    default:
      return state;
  }
};

export default mfaMethod;
