const FR = {
  mfaTitle: 'Authentification multifacteur',
  totpTitleLabel: 'Application d\'authentification',
  emailTitleLabel: 'E-mail OTP',
  mfaEnableDescription: 'Ajoutez une sécurité supplémentaire à votre compte en activant l\'authentification multifacteur. Elle permet de sécuriser votre compte en exigeant une méthode de vérification supplémentaire lorsque vous vous connectez.',
  totpFullDescription: 'Utilisez une application d\'authentification pour obtenir des codes de vérification « Time-based One-Time Passcodes » (TOTP) sur votre appareil mobile.',
  totpShortDescription: 'Utilisez une app d\'authentification pour obtenir des codes de vérification sur votre appareil mobile',
  emailFullDescription: 'Recevez un code de vérification « One-Time Passcode » (OTP) envoyé à l\'adresse e-mail de votre compte.',
  emailShortDescription: 'Recevez un code d\'accès à usage unique à l\'adresse e-mail de votre compte',
  enableMfaButton: 'Activer l\'authentification multifacteur',
  recommendedLabel: 'Recommandé',
  skipMfaButton: 'Configurer plus tard',
  statusVerified: 'Vérifié',
  statusPending: 'Non vérifié',
  statusDisabled: 'Désactivé',
  mfaSetupLabel: 'Installation {0}',
  mfaAddedLabel: 'Ajouté {0}',
  removeLabel: 'Retirer',
  removingLabel: 'Suppression',
  continueVerificationLabel: 'Vérifier {0}',
  removeTrustedDevicesLabel: 'Supprimer les appareils approuvés',
  recoveryCodesRemainingLabel: 'Codes de récupération restants',
  mfaDevicesRemovedMessage: 'Les appareils de confiance ont été supprimés avec succès',
  mfaDevicesErrorMessage: 'Impossible de supprimer les périphériques approuvés: {0}',
  confirmRemoveDevicesTitle: 'Supprimer tous les appareils approuvés',
  confirmRemoveDevicesMessage: 'Cela supprimera tous les appareils auxquels vous faisiez confiance. Vous devrez effectuer une vérification en deux étapes la prochaine fois que vous vous connecterez sur l\'un de ces appareils.',
  confirmRemoveDeviceButton: 'Supprimer les appareils',
  mfaSelectMethodTitle: 'Choisissez une méthode d\'authentification multifacteur',
  mfaSelectMethodDescription: 'Lorsque vous vous connectez, nous vous demanderons cette méthode de vérification. Choisissez la méthode qui vous convient le mieux.',
  cancelButtonText: 'Annuler',
  continueButtonText: 'Continuer',
  authAppRegistrationStep1Title: 'Configuration dans l\'application Authenticator',
  authAppRegistrationStep1Description: 'Scannez le <strong>code QR</strong> ci-dessous à l\'aide d\'une application d\'authentification telle que Google Authenticator, Microsoft Authenticator, Authy, etc.Si vous ne pouvez pas scanner le code QR, utilisez l\'option <strong>Clé secrète</strong> à côté du code QR',
  separatorLabel: 'OU',
  secretKeyLabel: 'Clé secrète',
  authAppRegistrationStep2Title: 'Entrez le code de l\'application Authenticator',
  authAppRegistrationStep2Description: 'Après avoir scanné le code QR ci-dessus, saisissez le code à 6 chiffres affiché pour {0} dans l\'entrée MFA ci-dessous.',
  verifyLabel: 'Vérifier',
  verifyingLabel: 'Vérification',
  authAppVerificationCompleteTitle: 'Vérification terminée',
  authAppVerificationCompleteMessage: 'Votre compte est désormais protégé par une authentification multifacteur à l\'aide d\'une application Authenticator. Chaque fois que vous vous connectez, nous vous demanderons cette méthode.',
  authAppRecoveryCodesMessage: 'Si vous perdez l\'accès à votre application Authenticator, vous pouvez utiliser l\'un des codes de récupération suivants pour retrouver l\'accès à votre compte.Téléchargez ou copiez les codes de récupération ci-dessous et conservez-les dans un endroit sûr et sécurisé.',
  authAppRecoveryCodesWarning: 'Vous ne pourrez plus voir ni récupérer ces codes après ce point.',
  downloadRecoveryCodesLabel: 'Télécharger les codes de récupération',
  recoveryCodesConfirmation: 'J\'ai téléchargé/copié les codes de récupération ci-dessus dans un emplacement sûr et sécurisé',
  doneText: 'Fait',
  emailRegistrationCompleteTitle: 'E-mail OTP activé',
  emailRegistrationCompleteMessage: 'Votre compte est désormais protégé par une authentification multifacteur utilisant un « One-Time Passcode » (OTP) par e-mail. Chaque fois que vous vous connectez, nous vous demanderons cette méthode.',
  removeMfaMethodTitle: 'Supprimer l\'authentification multifacteur',
  removeVerifiedMfaMethodMessage: 'Pour supprimer l\'authentification multifacteur de votre compte, nous devons d\'abord confirmer votre identité. Veuillez saisir votre mot de passe actuel ci-dessous.',
  passwordLabel: 'Mot de passe',
  invalidPasswordError: 'Mot de passe invalide',
  removeMfaMethodConfirmationMessage: 'Êtes-vous sûr de supprimer cette méthode d\'authentification multifacteur?',
  emailNotConfirmedErrorMessage: 'Votre adresse email doit être confirmée pour sélectionner cette méthode'
};
 
export default FR;
