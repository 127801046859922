import {
  updateIdtClaim,
  addIdtClaimAppointment,
  updateIdtClaimAppointment,
  addIdtInitialReport,
  addIdtBlockReport,
  setClaimReportApproved,
  addClaimDocument
} from '../actions/idt-claims';
import api from '@/lib/api-request';

const getIdtClaimAsync = (claimId) => {
  return (dispatch) => {
    return api.v1.get(`/idt/claims/${claimId}`).then(({ data: result }) => {
      dispatch(updateIdtClaim(result.id, result));
      return Promise.resolve(result);
    });
  };
};

const activateIdtClaimAsync = (claimId, attributes) => {
  const data = { type: 'idt_claims', attributes };

  return (dispatch) => {
    return api.v1.patch(`/idt/claims/${claimId}/activate`, data).then(({ data: result }) => {
      dispatch(updateIdtClaim(result.id, result));
      return Promise.resolve(result);
    });
  };
};

const createIdtClaimAppointmentAsync = (claimId, blockId, attributes) => {
  const data = { type: 'idt_appointments', attributes };

  return (dispatch) => {
    return api.v1.post(`/idt/claims/${claimId}/blocks/${blockId}/appointments`, data).then(({ data: result }) => {
      dispatch(addIdtClaimAppointment(claimId, blockId, result));
      return Promise.resolve(result);
    });
  };
};

const updateIdtClaimAppointmentAsync = (claimId, blockId, apptId, attributes) => {
  const data = { type: 'idt_appointments', id: apptId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/idt/claims/${claimId}/blocks/${blockId}/appointments/${apptId}`, data).then(({ data: result }) => {
      dispatch(updateIdtClaimAppointment(claimId, blockId, result));
      return Promise.resolve(result);
    });
  };
};

const startIdtInitialReportAsync = (claimId) => {
  const data = { type: 'idt_reports' };

  return (dispatch) => {
    return api.v1.post(`/idt/claims/${claimId}/report`, data).then(({ data: result }) => {
      dispatch(addIdtInitialReport(claimId, result));
      return Promise.resolve(result);
    });
  };
};

const startIdtBlockReportAsync = (claimId, blockId) => {
  const data = { type: 'idt_reports' };

  return (dispatch) => {
    return api.v1.post(`/idt/claims/${claimId}/blocks/${blockId}/report`, data).then(({ data: result }) => {
      dispatch(addIdtBlockReport(claimId, blockId, result));
      return Promise.resolve(result);
    });
  };
};

const approveFinalReportAsync = (reportId, claimId, blockId) => {
  const data = { type: 'idt_reports', id: reportId };

  return (dispatch) => {
    return api.v1.patch(`/idt/reports/${reportId}/approve`, data).then(({ data: result }) => {
      dispatch(setClaimReportApproved(claimId, blockId));
      return Promise.resolve(result);
    });
  };
};

const uploadClaimDocumentAsync = (claimId, file) => {
  const formData = new FormData();
  formData.append('file', file);

  return (dispatch) => {
    return api.v1.uploadFile(`/idt/claims/${claimId}/documents`, formData).then(({ data: document }) => {
      dispatch(addClaimDocument(claimId, document));
      return Promise.resolve(document);
    });
  };
};

export {
  getIdtClaimAsync,
  activateIdtClaimAsync,
  createIdtClaimAppointmentAsync,
  updateIdtClaimAppointmentAsync,
  startIdtInitialReportAsync,
  startIdtBlockReportAsync,
  approveFinalReportAsync,
  uploadClaimDocumentAsync
};
