const EN = {
  title: 'Create Your Profile',
  completeTitle: 'Complete Your Profile',
  bookingTitle: 'Booking Information',
  firstNameLabelText: 'First Name',
  firstNameErrorText: 'Please enter your first name.',
  lastNameLabelText: 'Last Name',
  lastNameErrorText: 'Please enter your last name.',
  phoneLabelText: 'Phone',
  phoneErrorText: 'Please enter a valid phone number.',
  languageLabelText: 'Language',
  languageValidationMessage: 'Please select your language preference.',
  bioLabelText: 'Professional Bio',
  credentialsLabelText: 'Credentials (Press Enter to Add a Credential)',
  addCredentialInputPlaceholder: '(e.g. M.D., D.C.) Press enter to add a credential',
  professionLabelText: 'Profession',
  professionPlaceholder: 'Select Profession',
  professionErrorMessage: 'Please select your profession',
  treatmentServicesLabelText: 'Treatment Services',
  treatmentServicesPlaceholder: 'Select all that apply',
  submitButtonText: 'Continue',
  completionModalMessage: 'You are missing a few pieces of information to complete your profile.',
  credentialsText: 'Credentials',
  profilePhotoLabel: 'Profile Photo',
  enableMfaTitle: 'Enable 2-Step Verification'
};

export default EN;
