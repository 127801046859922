import request from './request';

export class ApiRequest {
  #url;

  constructor(baseURL) {
    this.#url = baseURL;
  }

  async get(endpoint, params = {}, options = {}) {
    return this.#request({
      ...options,
      method: 'GET',
      query: params,
      path: endpoint
    });
  }

  async post(endpoint, data = {}, options = {}) {
    return this.#request({
      ...options,
      method: 'POST',
      data,
      path: endpoint
    });
  }

  async patch(endpoint, data = {}, options = {}) {
    return this.#request({
      ...options,
      method: 'PATCH',
      data,
      path: endpoint
    });
  }

  async delete(endpoint, options = {}) {
    return this.#request({
      ...options,
      method: 'DELETE',
      path: endpoint
    });
  }

  async uploadFile(endpoint, formData, options = {}) {
    return this.post(endpoint, formData, options);
  }

  async #request(options = {}) {
    return request({
      ...options,
      baseURL: this.#url
    });
  }
}
