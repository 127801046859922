import { updateUserPosition } from '../actions/users';
import api from '@/lib/api-request';

const updatePositionAsync = (userId, positionId, attributes, options = {}) => {
  const data = { type: 'positions', id: positionId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/positions/${positionId}`, data, options).then(({ data: position }) => {
      dispatch(updateUserPosition(userId, position));
      return Promise.resolve(position);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createPositionsAsync = (userId, attributes, options = {}) => {
  const data = { type: 'positions', attributes };

  return (dispatch) => {
    return api.v1.post('/positions', data, options).then(({ data: position }) => {
      dispatch(updateUserPosition(userId, position));
      return Promise.resolve(position);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  updatePositionAsync,
  createPositionsAsync
};
