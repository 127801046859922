import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '@/lib/api-request';
import Activity from '../../activity';
import { ProductsList } from '../products';
import { ErrorBanner } from '../../errors';
import { Page } from '../../page';
import Tabs from '../../tabs';
import Modal from '../../modal';
import PartnerForm from './partner-form';
import Markdown from '../../markdown';
import { MarketplacePaths } from '@/paths';
import { UrlValidator } from '@/forms';
import replaceLinkParams from '@/utilities/replace-link-params';
import { showAlert } from '../../alert-notifications/alert';
import AccessControl from '../../access-control';
import { AdminRoleDescriptions } from '@/utilities/user-roles';
import Strings from '../lang';

const getPartnershipAsync = async (id) => {
  const { data } = await api.v1.get(`/marketplace/partners/${id}`);
  return data;
};

const updatePartnershipAsync = async (id, attributes) => {
  const { data } = await api.v1.patch(`/marketplace/partners/${id}`, { type: 'partners', id, attributes });
  return data;
};

const EditPartnerModal = ({
  isOpen = false,
  initialState,
  onClose,
  onSubmit
}) => {
  const handleSubmit = useCallback((attributes) => {
    onSubmit(attributes);
    onClose();
  }, [onClose, onSubmit]);

  return (
    <Modal
      className="partnership-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.editPartnershipModalTitle}</h2>
      </Modal.Header>
      <Modal.Body>
        <PartnerForm
          initialState={initialState}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

const EditPartner = ({
  partner = {},
  onSubmit = () => {}
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button 
        type="button" 
        className="btn btn-secondary btn-sm"
        onClick={() => setOpen(true)}
      >
        {Strings.editDetailsButton}
      </button>
      <EditPartnerModal
        isOpen={open}
        initialState={{
          name: partner.name,
          website: partner.website_url,
          websiteValid: UrlValidator.test(partner.website_url),
          description: partner.description
        }}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

const MarketplacePartnerProfile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPartner = useCallback((id) => {
    setError(null);
    setLoading(true);

    getPartnershipAsync(id).then(partner => {
      setPartner(partner);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, []);

  const updatePartner = useCallback((attrs) => {
    setLoading(true);
    setError(null);

    const attributes = {
      id: partner.id,
      name: attrs.name,
      description: attrs.description,
      website_url: attrs.website,
      logo_filename: attrs.logoFilename || null,
      logo_base64: attrs.logo || null
    };

    updatePartnershipAsync(partner.id, attributes).then(partner => {
      setLoading(false);
      setPartner(partner);

      if (params.partnerId !== partner.slug) {
        navigate(
          replaceLinkParams(MarketplacePaths.partners.show.link, { partnerId: partner.slug }),
          { replace: true }
        );
      }

      showAlert('success', {
        dismissable: true,
        autoDismiss: 3000,
        message: Strings.partnerUpdatedSuccessMessage
      });
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [navigate, params.partnerId, partner.id]);

  useEffect(() => {
    if (params.partnerId) {
      getPartner(params.partnerId);
    }
  }, [getPartner, params.partnerId]);

  return (
    <Page className="product-partner-profile">
      <Activity active={loading}>
        <ErrorBanner error={error} />
        <div className="partner-profile-header">
          <div className="partner-image">
            <img src={partner?.logo_url} alt={partner?.name} />
          </div>
          <div className="partner-details">
            <h1>{partner?.name}</h1>
            <div><a href={partner?.website_url}>{partner?.website_url}</a></div>
          </div>
          <AccessControl roles={AdminRoleDescriptions}>
            <div className="partner-actions">
              <EditPartner 
                partner={partner}
                onSubmit={updatePartner}
              />
            </div>
          </AccessControl>
        </div>
        <Tabs 
          items={[
            {
              key: 'products',
              label: Strings.productsTabLabel,
              component: (
                <ProductsList products={partner?.products || []} />
              )
            },
            {
              key: 'details',
              label: Strings.detailsTabLabel,
              hide: !partner?.description,
              component: (
                <Markdown html={false}>
                  {partner?.description}
                </Markdown>
              )
            }
          ]}
        />
      </Activity>
    </Page>
  );
};

export default MarketplacePartnerProfile;
