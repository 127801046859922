import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Else, If, Then } from 'react-if';
import { Page } from '@/components/page';
import { MfaSetupView } from '@/components/multifactor';
import { MfaSetupWalkthrough } from '@/components/multifactor/mfa-setup-walkthrough';
import { UserSelector } from '@/redux/selectors';
import { useMount } from '@/hooks';
import { DashboardPaths } from '@/paths';
import Strings from './lang';

const ClinicUserMfaOnboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [setup, setSetup] = useState(false);
  const currentUser = useSelector(UserSelector.getCurrentUser);

  const gotToDashboard = useCallback(() => {
    const nextPath = location.state?.nextPathname || DashboardPaths.index.route;
    navigate(nextPath, { replace: true });
  }, [location.state?.nextPathname, navigate]);

  useMount(() => {
    if (currentUser.mfa?.enabled) {
      navigate('/account?tab=2fa', { replace: true });
    }
  });

  return (
    <Page
      hideNavigationContent
      hideBannerIfClinicDeactivated
      className="registration-mfa"
    >
      <If condition={!setup}>
        <Then>
          <div className="mfa-onboard-view">
            <h1 className="display dot text-center">
              {Strings.enableMfaTitle}
            </h1>
            <MfaSetupView
              skippable
              onStartSetup={() => setSetup(true)}
              onSkip={gotToDashboard}
            />
          </div>
        </Then>
        <Else>
          <MfaSetupWalkthrough
            onDone={gotToDashboard}
            onCancel={gotToDashboard}
          />
        </Else>
      </If>
    </Page>
  );
};

export default ClinicUserMfaOnboarding;
