import api from '@/lib/api-request';
import { updateClinicUsers } from '../actions/clinic-users';

const getClinicUsersAsync = (clinicId) => {
  return (dispatch) => {
    return api.v1.get(`/clinics/${clinicId}/users/clinic_users`).then(({ data: users }) => {
      dispatch(updateClinicUsers(clinicId, users));
      return Promise.resolve(users);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicUsersAsync
};
