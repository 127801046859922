import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthenticationSidebar from './authentication-sidebar';
import { createRegistrationUserAsync } from '@/redux/thunks/registration';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import RegistrationAccountForm from './registration-account-form';
import { withRegistrationStep } from '../step-wrappers';
import Strings from './lang';
import { withRouter } from '@/utilities/router-utils';
import { setRegistrationReturning } from '@/utilities/registration-session';
import { userHasRoleMatchingDescription, RoleDescriptions } from '@/utilities/user-roles';
import RegistrationPaths from '@/paths/registration';

const EmailTakenErrorMessage = ({
  visible = false,
  email = '',
  onSignInClick
}) => {
  return visible && (
    <div className="alert alert-danger email-taken-error">
      <div className="title">
        {Strings.formatString(
          Strings.emailTakenErrorTitle,
          email || ''
        )}
      </div>
      <p className="message">
        {Strings.emailTakenErrorMessage}
      </p>
      <button type="button" className="edit-button" onClick={onSignInClick}>
        {Strings.signIntoAccountButtonText}
      </button>
    </div>
  );
};

class RegistrationAccount extends Component {
  constructor(props) {
    super(props);

    const { role } = props;

    this.register = this.register.bind(this);
    this.onAuthenticated = this.onAuthenticated.bind(this);

    this.state = {
      role,
      activity: false,
      error: null,
      email: null,
      sidebarVisible: false,
      emailTakenError: false
    };
  }

  render() {
    return (
      <div className="registration-account">
        <h1 className="display dot text-center">{this.getTitle()}</h1>
        <EmailTakenErrorMessage 
          visible={this.state.emailTakenError}
          email={this.state.email}
          onSignInClick={() => this.setState({ sidebarVisible: true })}
        />
        <ErrorBanner error={this.state.error} />
        <Activity active={this.state.activity}>
          <RegistrationAccountForm 
            role="player"
            onSubmit={this.register}
          />
        </Activity>
        <AuthenticationSidebar 
          visible={this.state.sidebarVisible}
          email={this.state.email}
          onAuthenticated={this.onAuthenticated}
          onClose={() => this.setState({ sidebarVisible: false })}
        />
      </div>
    );
  }

  getTitle() {
    const { role } = this.state;

    if (role === 'player') {
      return Strings.personalAccountTitle;
    } 
    
    if (role === 'guardian') {
      return Strings.guardianAccountTitle;
    }
      
    return Strings.title;
  }

  register(attrs) {
    this.setState({
      activity: true,
      email: null,
      error: null
    });

    const attributes = { ...attrs };

    attributes.role = this.state.role;
  
    this.props.createUser(attributes).then(() => {
      this.setState({ activity: false }, () => this.props.next());
    }).catch(error => {
      let { message } = error;
      let emailTakenError = false;

      if (error.message.includes('taken')) {
        emailTakenError = true;
        message = null;
      }

      this.setState({
        activity: false,
        error: message,
        emailTakenError,
        email: attributes.email
      });

      window.scrollTo(0, 0);
    });
  }

  onAuthenticated(user) {
    setRegistrationReturning(true);
    let nextPath = null;

    if (userHasRoleMatchingDescription(user, RoleDescriptions.Guardian)) {
      nextPath = RegistrationPaths.guardian.about.link;
    } else if (userHasRoleMatchingDescription(user, RoleDescriptions.Player)) {
      nextPath = RegistrationPaths.player.about.link;
    } 

    if (nextPath) {
      // eslint-disable-next-line no-magic-numbers
      setTimeout(() => this.props.router.replace(nextPath), 200);
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (attributes) => {
      return dispatch(createRegistrationUserAsync(attributes));
    }
  };
};

const ConnectedRegistrationAccount = connect(
  null,
  mapDispatchToProps
)(withRouter(RegistrationAccount));

const RegistrationAccountStep = withRegistrationStep('account')(ConnectedRegistrationAccount);

export default RegistrationAccountStep;
