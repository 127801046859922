import React from 'react';
import Modal from '@/components/modal';
import { MfaSetupWalkthrough } from '../mfa-setup-walkthrough';
import Icon from '@/components/icon';
import Strings from '../lang';

export const MfaSetupModal = ({
  isOpen = false,
  method,
  onClose
}) => {
  return (
    <Modal
      blur
      fullScreen
      isOpen={isOpen}
      className="mfa-setup-modal"
    >
      <Modal.Header>
        <h1>
          <Icon prefix="fas" name="shield-check" />&nbsp;
          {Strings.mfaTitle}
        </h1>
      </Modal.Header>
      <Modal.Body>
        <MfaSetupWalkthrough
          method={method}
          onCancel={onClose}
          onDone={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
