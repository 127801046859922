import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardPaths } from '@/paths';
import { updateUserAsync } from '@/redux/thunks/users';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { Page } from '../../page';
import ProgressDots from '../../progress-dots';
import RegistrationSteps from './registration-steps';
import CompletionModal from './completion-model';
import Strings from './lang';
import { useMount } from '@/hooks';
import { UserSelector } from '@/redux/selectors';

const isClinicianAtAnyClinics = (roles = []) => {
  return !!roles.find(role => (
    ['clinician', 'specialist'].includes(role.name)
      && role.resource_type === 'Clinic' 
      && !!role.resource_id
  ));
};

const getTotalSteps = (roles) => {
  if (isClinicianAtAnyClinics(roles)) {
    return RegistrationSteps.BOOKING_STEP;
  }

  return RegistrationSteps.PERSONAL_STEP;
};

const ClinicUserRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const [step, setStep] = useState(1);
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [completeModalOpen, setCompleteModalOpen] = useState(false);
  const totalSteps = useMemo(() => getTotalSteps(currentUser.roles), [currentUser.roles]);
  const completeProfile = location.state?.profileIncomplete ?? false;

  const next = useCallback(() => {
    const nextStep = step + 1;
    if (nextStep > totalSteps) {
      const nextPathname = location.state?.nextPathname || DashboardPaths.index.link;

      if (!currentUser.mfa?.enabled) {
        navigate('two-step-verification', { replace: true, state: { nextPathname } });
      } else {
        navigate(nextPathname, { replace: true });
      }
    } else {
      setStep(nextStep);
      window.scrollTo(0, 0);
    }
  }, [currentUser.mfa?.enabled, location.state?.nextPathname, navigate, step, totalSteps]);

  const handleSubmit = useCallback((attributes) => {
    if (!attributes) {
      next();
      return;
    }

    setActivity(true);
    setError(null);

    dispatch(updateUserAsync(currentUser.id, attributes)).then(() => {
      setActivity(false);
      next();
    }).catch(error => {
      setError(error.message);
      window.scrollTo(0, 0);
    });
  }, [currentUser.id, dispatch, next]);

  useMount(() => {
    if (completeProfile) {
      setTimeout(() => {
        setCompleteModalOpen(true);
      }, 100);
    }
  });

  return (
    <Page 
      hideNavigationContent
      hideBannerIfClinicDeactivated
      className="registration-about"
    >
      <h1 className="display dot text-center">
        {completeProfile ? Strings.completeTitle : Strings.title}
      </h1>
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <ProgressDots step={step} total={totalSteps} />
        </div>
        <div className="col-md-6 col-md-offset-3">
          <ErrorBanner error={error} />
          <Activity active={activity}>
            <RegistrationSteps
              step={step}
              touched={completeProfile}
              user={currentUser}
              onSubmit={handleSubmit}
            />
          </Activity>
        </div>
      </div>
      <CompletionModal 
        isOpen={completeModalOpen}
        onClose={() => setCompleteModalOpen(false)}
      />
    </Page>
  );
};

export default ClinicUserRegistration;
export { default as ClinicUserMfaOnboarding } from './mfa-onboarding';
