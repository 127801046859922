const EN = {
  photoHeadingText: 'Photo',
  activeClinicHeadingText: 'Current Clinic',
  personalHeadingText: 'Personal',
  contactHeadingText: 'Contact',
  physicianHeadingText: 'Physician',
  clinicsHeadingText: 'Clinics',
  medicalHeadingText: 'Medical',
  profileTabLabel: 'Profile',
  sportsTabLabel: 'Sports',
  medicalInfoTabLabel: 'Medical Info',
  baselineHistoryTabLabel: 'Baseline History',
  injuryHistoryTabLabel: 'Injury History',
  injuryReportsTabLabel: 'Injury Reports',
  updatePasswordTabLabel: 'Update Password',
  assignBagTagButtonText: 'Assign ID Card',
  patientNameRedactedText: 'Patient Profile',
  assignClinicModalTitle: 'Assign Current Clinic',
  assignClinicModalMessage: 'Are you sure you want to make {0} {1}\'s current clinic?',
  assignClinicModalConfirmButtonTitle: 'Assign',
  unassignedClinicMessageText: '{0}\'s current clinic has not been assigned yet.',
  unassignedClinicRedactedText: 'This patient',
  changeClinicLabel: 'Change Clinic',
  newInjuryLinkText: 'New Injury',
  activateUserButtonText: 'Activate Account',
  activateUserModalTitle: 'Activate Account',
  activateUserModalMessage: 'Are you sure you want to activate {0}\'s account?',
  activateUserModalConfirmButtonTitle: 'Activate',
  deactivateUserButtonText: 'Deactivate Account',
  deactivateUserModalTitle: 'Deactivate Account',
  deactivateUserModalMessage: 'Are you sure you want to deactivate {0}\'s account?',
  deactivateUserModalConfirmButtonTitle: 'Dectivate',
  modalNameRedactedText: 'this patient',
  accountDeactivatedBannerMessage: 'This account has been deactivated',
  noneText: 'None',
  saveButtonText: 'Save',
  patientInformationTitle: 'Patient Information',
  radioOptionNo: 'No',
  radioOptionYes: 'Yes',
  doesPatientHavePhysicianRadioLabel: 'Does this patient have a primary physician?',
  removeUserButtonText: 'Remove From Clinic',
  removeUserModalTitle: 'Remove From Clinic',
  confirmRemoveUserMessage: 'Are you sure you want to remove {0} as a user of this clinic?',
  removeUserSuccessMessage: '{0} has been removed from {1}',
  trainingExpiryLabelText: 'CCMI Training Expiry',
  trainingExpiredText: 'expired {0}',
  trainingExpiringText: 'expires {0}',
  bookingTabLabel: 'Booking Links',
  clinicalInfoTitle: 'Clinical',
  mfaTabTitle: '2-Step Verification',
  mfaStatusPending: 'Pending',
  mfaStatusVerified: 'Enabled',
  mfaStatusDisabled: 'Disabled',
  mfaVerifiedMessage: 'Your account is protected with 2-Step Verification. Anytime you sign in, we\'ll use the following method as a 2nd step in verifying your identity.',
  mfaPeningMessage: 'Your chosen method has not been verified. Verify this method below or remove it.'
};

export default EN;
