import React from 'react';
import Modal from '../modal';
import Strings from './lang';

const ConfirmationModal = ({
  isOpen,
  static: isStatic = true,
  title = null,
  message = null,
  type = 'primary',
  className,
  cancelButtonTitle = Strings.cancelButtonTitle,
  confirmButtonTitle = Strings.confirmButtonTitle,
  onConfirm = () => { },
  onCancel = () => { }
}) => {
  return (
    <Modal
      blur
      static={isStatic}
      className={className}
      isOpen={isOpen}
      onClose={isOpen ? onCancel : undefined}
    >
      {title && (
        <Modal.Header>
          <h1>{title}</h1>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="confirm-modal-content">
          {message}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-default"
          onClick={onCancel}
        >
          {cancelButtonTitle}
        </button>
        <button
          type="button"
          className={`btn btn-sm btn-${type}`}
          onClick={onConfirm}
        >
          {confirmButtonTitle}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
