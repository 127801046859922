import api from '@/lib/api-request';
import { updateSymptomsUpdates } from '../actions/symptoms-updates';

const getSymptomUpdatesAsync = (userId, injuryId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/symptom_updates`).then(({ data: results }) => {
      dispatch(updateSymptomsUpdates(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getSymptomUpdatesAsync
};
