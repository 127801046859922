import dayjs from 'dayjs';
import { updateCurrentUserId } from '../actions/users';
import {
  getDeviceToken,
  setDeviceToken,
  removeDeviceToken,
  setActivity,
  setAuthToken,
  updateCurrentClinicForUser,
} from '@/tokens';
import { setRememberedUser, removeRememberedUser } from '@/utilities/email-storage';
import { getDeviceFingerprint } from '@/utilities/fingerprint';
import { getCurrentUserAsync } from './users';
import api from '@/lib/api-request';

const getSessionHeaders = async (extra = {}) => {
  const deviceToken = getDeviceToken();
  const headers = extra ?? {};

  if (deviceToken) {
    const fingerprint = await getDeviceFingerprint();
    headers['Device-Fingerprint'] = fingerprint;
    headers['Device-Token'] = deviceToken;
  }

  return headers;
};

const createAuthSession = async (attributes) => {
  const headers = await getSessionHeaders();
  const data = { type: 'sessions', attributes };
  return api.auth.post('/session', data, { getAuthToken: () => null, extraHeaders: headers });
};

const onSessionCreate = (dispatch, data, rememberMe = null) => {
  setAuthToken(data.auth_token);
  setActivity(dayjs().valueOf());
  dispatch(updateCurrentUserId(data.user_id));

  return dispatch(getCurrentUserAsync()).then(user => {
    if (rememberMe) {
      setRememberedUser(user);
    } else if (rememberMe === false) {
      removeRememberedUser(user);
    }

    updateCurrentClinicForUser(user);

    return Promise.resolve(user);
  }).catch(error => {
    return Promise.reject(error);
  });
};

const createNetworkSessionAsync = (attributes = {}, rememberMe = null) => {
  return dispatch => {
    return createAuthSession(attributes).then(({ data: session }) => {
      if (session.auth_token) {
        return onSessionCreate(dispatch, session, rememberMe);
      }
      
      removeDeviceToken();

      return Promise.resolve(session);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getChallengeRequestOptions = async (token, trust = false) => {
  const options = { getAuthToken: () => token };

  if (trust) {
    const fingerprint = await getDeviceFingerprint();

    options.extraHeaders = {
      'Device-Fingerprint': fingerprint
    };
  }

  return options;
};

const verifySessionChallengeAsync = (token, attributes) => {
  const data = { type: 'mfa_challenges', attributes };

  return async dispatch => {
    const options = await getChallengeRequestOptions(token, attributes.trust);

    return api.auth.patch('/mfa/challenge/verify', data, options).then(({ data: auth, headers }) => {
      if (headers['device-token']) {
        setDeviceToken(headers['device-token']);
      }

      return onSessionCreate(dispatch, auth);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createNetworkSessionAsync,
  verifySessionChallengeAsync
};
