import React, { useCallback, useEffect, useState } from 'react';
import { If, Then, Else } from 'react-if';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import BaselineListItem from './baseline-list-item';
import replaceLinkParams from '@/utilities/replace-link-params';
import { ClinicPaths } from '@/paths';
import { confirmUnarchive } from '../baseline-archive';
import api from '@/lib/api-request';
import { unarchiveBaselineTestAsync } from '@/redux/thunks/baseline-tests';
import { showAlert } from '../../alert-notifications';
import Strings from './lang';
import Icon from '../../icon';

const getArchivedBaselinesAsync = async (userId) => {
  const { data } = await api.v1.get(`/users/${userId}/baselines/archived`);
  return data;
};

const ArchivedBaselinesList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [activity, setActivity] = useState(false);
  const [baselines, setBaselines] = useState([]);
  const [error, setError] = useState(null);

  const onUnarchive = useCallback((id) => {
    setActivity(true);
    setError(null);

    dispatch(unarchiveBaselineTestAsync(params.userId, id)).then(() => {
      setBaselines(prev => {
        const newBaselines = [...prev];
        const index = newBaselines.findIndex(baseline => baseline.id === id);

        if (index >= 0) {
          newBaselines.splice(index, 1);
        }

        return newBaselines;
      });

      setActivity(false);
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3500,
        message: Strings.unarchivedSuccessMessage
      });
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch, params.userId]);

  const handleUnarchive = useCallback((id) => {
    confirmUnarchive(() => onUnarchive(id));
  }, [onUnarchive]);

  const getArchivedBaselines = useCallback((userId) => {
    setActivity(true);
    setError(null);

    getArchivedBaselinesAsync(userId).then(baselines => {
      setBaselines(baselines);
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, []);

  useEffect(() => {
    if (params.userId) {
      getArchivedBaselines(params.userId);
    }
  }, [getArchivedBaselines, params.userId]);

  return (
    <div className="user-baselines">
      <div className="user-baselines-container">
        <Activity active={activity} static={!baselines.length}>
          <ErrorBanner error={error} />
          <div className="archived-baselines-header">
            <button 
              type="button" 
              className="btn-back no-print"
              onClick={() => navigate(-1)}
            >
              <Icon name="arrow-left" />
            </button>
            <h2>{Strings.archivedTestsTitle}</h2>
          </div>
          <If condition={baselines?.length > 0}>
            <Then>
              <ul className="user-baselines-list">
                {baselines?.map((baseline, index) => (
                  <li key={baseline.id}>
                    <BaselineListItem
                      baseline={baseline}
                      number={baselines.length - index}
                      link={
                        replaceLinkParams(
                          ClinicPaths.patientProfile.baselines.show.link,
                          { ...params, baselineId: baseline.id }
                        )
                      }
                      onUnarchive={handleUnarchive}
                    />
                  </li>
                ))}
              </ul>
            </Then>
            <Else>
              <p>{Strings.archivedListEmptyMessage}</p>
            </Else>
          </If>
        </Activity>
      </div>
    </div>
  );
};

export default ArchivedBaselinesList;
