import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MfaMethodSelect } from './mfa-method-select';
import { EmailRegistrationComplete } from './email-registration-complete';
import { AuthAppVerification } from './auth-app-verification';
import { UserSelector } from '@/redux/selectors/index';
import { AuthAppRecoveryCodesView } from './auth-app-recovery-codes-view';

export const MfaSetupWalkthrough = ({
  method: _propsMethod,
  onDone,
  onCancel = () => {}
}) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const [method, setMethod] = useState(() => _propsMethod);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [step, setStep] = useState(() => (
    method?.verified_at ? 'complete' : (method?.method_type ?? 'initial')
  ));

  return (
    <div className="mfa-setup-walkthrough">
      {step === 'initial' && (
        <div className="container-sm">
          <MfaMethodSelect
            userId={currentUser.id}
            emailConfirmed={currentUser.confirmed}
            onContinue={(method) => {
              setMethod(method);

              if (method.method_type === 'email') {
                setStep('email_complete');
              } else {
                setStep('totp');
              }
            }}
            onCancel={onCancel}
          />
        </div>
      )}
      {step === 'totp' && (
        <AuthAppVerification
          provisioningUrl={method.provisioning_uri}
          onVerified={({ recovery_codes = [], ...method }) => {
            setMethod(method);
            setRecoveryCodes(recovery_codes);
            setStep('totp_complete');
          }}
        />
      )}
      {step === 'email_complete' && (
        <div className="container-sm">
          <EmailRegistrationComplete onDone={onDone} />
        </div>
      )}
      {step === 'totp_complete' && (
        <AuthAppRecoveryCodesView
          recoveryCodes={recoveryCodes}
          onDone={onDone}
        />
      )}
    </div>
  );
};
