import api from '@/lib/api-request';
import { updateProgressReports } from '../actions/progress-reports';

const getProgressReportsAsync = (userId, injuryId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/progress_reports`).then(({ data: results }) => {
      dispatch(updateProgressReports(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getProgressReportAsync = (userId, injuryId, progressId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/progress_reports/${progressId}`).then(({ data: results }) => {
      dispatch(updateProgressReports(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getProgressReportsAsync,
  getProgressReportAsync
};
