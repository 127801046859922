import { buildHeaders } from './build-headers';
import query from './query';
import Logger from './logger';
import deserializer from './deserialize';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import { removeAuthData, removeCurrentClinicId } from '@/tokens';

class ApiError extends Error {
  constructor(status, message = '', errors = []) {
    super(message);
    this.status = status;
    this.errors = errors;
  }
}

const isNotAuthenticatedError = (error) => {
  if (error.status === HTTPStatusCodes.Unauthorized) {
    const { errors = [] } = error;

    return errors?.some(e => (
      ['not authenticated', 'token expired'].includes(e.title?.toLowerCase())
    )) ?? false;
  }

  return false;
};

const reduceErrorMessages = (errors = []) => {
  return errors.reduce((accumulator, error) => {
    const { title = 'Unknown Error' } = error;
    return `${accumulator}${title}\n`;
  }, '');
};

const createEndpoint = (url, path, q) => {
  const endpoint = [
    url,
    path.startsWith('/') ? path.substring(1) : path
  ].join('/');

  const queryString = q && query.serialize(q);

  if (queryString) {
    return `${endpoint}?${queryString}`;
  }

  return endpoint;
};

// Manually parse json in order to handle empty
// response body where the status is 204 (no content)
const parseJson = async (response) => {
  const text = await response.text();

  const jsonText = text.trim() || '{}';
  return JSON.parse(jsonText);
};

const request = async ({
  method = 'GET',
  baseURL,
  path,
  query = null,
  data,
  deserialize = true,
  getAuthToken,
  contentType,
  accept,
  client,
  extraHeaders,
  redirectNotAuthenticated = true
}) => {
  const endpoint = createEndpoint(baseURL, path, query);
  const headers = buildHeaders({
    getAuthToken,
    contentType,
    accept,
    client,
    extraHeaders
  });

  const fetchOptions = {
    method,
    headers
  };

  if (data instanceof FormData) {
    delete fetchOptions.headers['Content-Type'];
    fetchOptions.body = data;
  } else if (!['GET', 'HEAD', 'DELETE'].includes(method)) {
    fetchOptions.body = JSON.stringify({ data });
  }

  const logger = new Logger({
    method,
    url: endpoint
  });

  logger.log('Request:', {
    endpoint,
    data,
    headers: fetchOptions.headers,
  });
  
  try {
    const res = await fetch(endpoint, fetchOptions);
  
    const resHeaders = Object.fromEntries(res.headers);
  
    logger.log('Response:', {
      headers: resHeaders,
      url: res.url,
      status: res.status,
      statusText: res.statusText
    });
  
    if (res.status === HTTPStatusCodes.NoContent) {
      logger.flush();
      return {};
    }
  
    const json = await parseJson(res);
  
    logger.log('Raw Data:', json);
  
    if (res.ok) {
      let data = json;
      let meta;
  
      if (deserialize) {
        const result = deserializer(json);
  
        logger.log('Deserialized:', result);
  
        data = result.data;
        meta = result.meta;
      }
  
      logger.flush();
  
      return {
        data,
        meta,
        headers: resHeaders
      };
    }
  
    const { errors = [] } = json;

    throw new ApiError(
      res.status,
      errors?.length ? reduceErrorMessages(errors) : res.statusText,
      errors
    );
  } catch (e) {
    logger.log('Error:', e);
    logger.flush(false);

    if (isNotAuthenticatedError(e) && redirectNotAuthenticated) {
      removeAuthData();
      removeCurrentClinicId();

      const replacePath = window.location.pathname.startsWith('/register')
        ? '/register'
        : '/login';

      window.location.href = replacePath;
    }

    throw e;
  }
};

export default request;
