import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from '../../drawer';
import AuthenticationForm from '../../authentication/authentication-form';
import RegistrationPaths from '@/paths/registration';
import { REGISTRATION_PASSWORD_RESET_URL } from '@/utilities/urls';
import Activity from '../../activity';
import ErrorBanner from '../../errors/error-banner';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import { ErrorStrings } from '../../errors';
import { createRegistrationSessionAsync } from '@/redux/thunks/registration';
import Strings from './lang';
import Icon from '../../icon';

class AuthenticationSidebar extends Component { 
  constructor(props) {
    super(props);

    this.authenticate = this.authenticate.bind(this);

    this.state = {
      authenticating: false,
      error: null
    };
  }
  
  render() {
    const { authenticating, error } = this.state;
    const {
      visible = false,
      email = null,
      onClose = () => {}
    } = this.props;

    return (
      <Drawer
        blur
        isOpen={visible}
        overlayDisabled={authenticating}
        contentClassName="authentication-drawer"
        onClose={onClose}
      >
        <Activity active={authenticating}>
          <div className="returning-section">
            <button 
              type="button" 
              className="close" 
              onClick={onClose}
            >
              <Icon name="xmark" />
            </button>
            <div className="authentication">
              <div className="brand-logo">
                <img src="/images/logo/color-full-animated.svg" alt="CCMI Logo" />
              </div>
              <h1 className="display text-center">
                {Strings.signInTitle}
              </h1>
              <ErrorBanner error={error} />
              <AuthenticationForm 
                displayRememberMe={false}
                email={email}
                portal="player"
                onSubmit={this.authenticate}
                resetPasswordState={{
                  nextLocation: RegistrationPaths.index.link,
                  resetUrl: REGISTRATION_PASSWORD_RESET_URL
                }}
              />
            </div>
          </div>
        </Activity>
      </Drawer>
    );
  }

  authenticate(attributes) {
    this.setState({
      authenticating: true,
      error: null
    });

    this.props.authenticate(attributes).then((user) => {
      this.props.onClose();
      this.setState({ authenticating: false }, () => {
        this.props.onAuthenticated(user);
      });
    }).catch(error => {
      let { message } = error; 
      if (error.status === HTTPStatusCodes.Unauthorized) {
        message = ErrorStrings.error401Signin;
      }

      this.setState({
        authenticating: false,
        error: message
      });
    });
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (attributes) => {
      return dispatch(createRegistrationSessionAsync(attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AuthenticationSidebar);
