import api from '@/lib/api-request';
import { updateClinicPatients } from '../actions/clinic-patients';

const getClinicPatientsAsync = (clinicId) => {
  return (dispatch) => {
    return api.v1.get(`/clinics/${clinicId}/users`).then(({ data: users }) => {
      dispatch(updateClinicPatients(clinicId, users));
      return Promise.resolve({ clinicId, users });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicPatientsAsync
};
