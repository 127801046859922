/* eslint-disable operator-assignment */
/* eslint-disable no-magic-numbers */
function getArchitecture() {
  const f = new Float32Array(1);
  const u8 = new Uint8Array(f.buffer);
  f[0] = Infinity;
  f[0] = f[0] - f[0];

  return u8[3];
}

function getTimezone() {
  const DateTimeFormat = window.Intl?.DateTimeFormat;
  if (DateTimeFormat) {
    return new DateTimeFormat().resolvedOptions().timeZone;
  }

  return new Date().getTimezoneOffset();
}

function getTouchCapability() {
  let touchEvent = false;

  try {
    document.createEvent('TouchEvent');
    touchEvent = true;
  } catch {
    touchEvent = false;
  }

  return touchEvent;
}

function getTimezoneOffset() {
  const currentYear = new Date().getFullYear();
  // Account for timezones that use Daylight Savings times
  return Math.max(
    new Date(currentYear, 0, 1).getTimezoneOffset(),
    new Date(currentYear, 6, 1).getTimezoneOffset()
  );
}

function getPlatform() {
  if (navigator.userAgentData) {
    const platformInfo = navigator.userAgentData.platform || 'unknown';
    return platformInfo;
  }
  // Fallback for older browsers
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes('win')) return 'Windows';
  if (userAgent.includes('mac')) return 'macOS';
  if (userAgent.includes('linux')) return 'Linux';
  if (/android/i.test(userAgent)) return 'Android';
  if (/iphone|ipad|ipod/i.test(userAgent)) return 'iOS';
  return 'unknown';
}

function getCanvasFingerprint() {
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.textBaseline = 'top';
    ctx.font = '14px \'Arial\'';
    ctx.fillStyle = '#f60';
    ctx.fillRect(0, 0, 100, 50);
    ctx.fillStyle = '#069';
    ctx.fillText('fingerprint', 10, 10);
    return canvas.toDataURL(); // Encodes the canvas content into a data URL
  } catch {
    return 'no-canvas';
  }
}

async function hashStringSHA256(data) {
  // Hash the data using SHA-256
  const hashBuffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(data)
  );

  // Convert the hash to a hex string
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
}

async function generateFingerprint() {
  // Collect browser properties
  const components = [
    navigator.userAgent, // User-agent string
    navigator.language, // Browser language
    screen.colorDepth, // Screen color depth
    screen.height, // Screen height
    screen.width, // Screen width
    screen.pixelDepth, // Screen pixel depth
    getArchitecture(), // cpu architecture
    getTimezone(), // Timezone
    getTimezoneOffset(), // Timezone Offset
    getTouchCapability(), // Touch capability (true/false)
    navigator.hardwareConcurrency, // Number of CPU cores
    navigator.deviceMemory || 0, // Approximate device memory (if available)
    getPlatform(), // OS platform
    navigator.maxTouchPoints, // Maximum touch points
    getCanvasFingerprint(), // Canvas fingerprint
    navigator.vendor
  ];

  return hashStringSHA256(
    components.join('::')
  );
}

let fingerprint = null;

export async function getDeviceFingerprint() {
  if (fingerprint) return fingerprint;

  fingerprint = await generateFingerprint();

  return fingerprint;
}
