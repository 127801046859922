import api from '@/lib/api-request';
import { updateInjury, updateInjuries } from '../actions/injuries';

const createInjuryAsync = (userId, attributes) => {
  const data = { type: 'injury', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injuries`, data).then(({ data: results }) => {
      dispatch(updateInjury(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateInjuryAsync = (userId, injuryId, attributes) => {
  const data = { type: 'injury', id: injuryId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}`, data).then(({ data: results }) => {
      dispatch(updateInjury(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuryAsync = (userId, injuryId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}`).then(({ data: results }) => {
      dispatch(updateInjury(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuriesAsync = userId => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries`).then(({ data: results }) => {
      dispatch(updateInjuries(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getActiveInjuryAsync = (userId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/active`).then(({ data: results }) => {
      if (results?.id) {
        dispatch(updateInjury(userId, results));
        return Promise.resolve(results);
      }
      
      return Promise.resolve(null);
    }).catch(error => {
      Promise.reject(error);
    });
  };
};

export {
  getActiveInjuryAsync,
  createInjuryAsync,
  updateInjuryAsync,
  getInjuryAsync,
  getInjuriesAsync
};
