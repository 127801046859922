import api from '@/lib/api-request';
import { updateClinicPlayersSearch } from '../actions/clinic-players-search';

const searchClinicPlayersAsync = (attributes = {}) => {
  return (dispatch) => {
    return api.v1.get('/search/clinics/players', attributes).then(({ data: results }) => {
      dispatch(updateClinicPlayersSearch(attributes, results));
      return Promise.resolve({ attributes, results });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  searchClinicPlayersAsync
};
