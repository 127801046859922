import { sortAscending } from '@/utilities/sort';
import { UPDATE_USER, UPDATE_USER_POSITION, UPDATE_USER_CHILDREN } from '../actions/users';
import { REMOVE_MFA_METHOD, UPDATE_MFA_METHOD } from '../actions/mfa-method';

const updateUserReducer = (state = {}, action = {}) => {
  const { user = {} } = action;
  const { id } = user; 
  return (id) ? { ...state, [id]: user } : state;
};

const updateUserPositionReducer = (state = {}, action = {}) => {
  const { userId = 0, position = {} } = action;
  const { id = 0, primary = '', secondary = '' } = position;
  const user = state[userId];
  if (user) {
    // Create a new teams array, updating the team with the specified position:
    const teams = (user.teams || []).map(team => {
      if (team.position && team.position.id === id) {
        return { ...team, position: { ...team.position, primary, secondary } };
      } 
      return team;
    });
    return { ...state, [userId]: { ...user, teams } };
  } 
  return state;
};

const updateUserChildrenReducer = (state = {}, action = {}) => {
  const { userId = 0, child = {} } = action;
  const guardian = state[userId];

  if (guardian) {
    return {
      [userId]: {
        ...guardian,
        children: [
          child,
          ...(guardian.children || []),
        ].sort(sortAscending.bind(null, 'id'))
      }
    };
  } 
  return state;
};

const getMfaStatus = (method) => {
  if (!method) {
    return 'disabled';
  }

  return method.verified_at ? 'verified' : 'pending';
};

const updateUserMfaReducer = (state = {}, action = {}) => {
  const { userId, method } = action;
  const { [userId]: user } = state;

  if (!user) return state;

  return {
    ...state,
    [userId]: {
      ...user,
      mfa: {
        enabled: !!method,
        status: getMfaStatus(method)
      }
    }
  };
};

const users = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_USER:
      return updateUserReducer(state, action);
    case UPDATE_USER_POSITION:
      return updateUserPositionReducer(state, action);
    case UPDATE_USER_CHILDREN:
      return updateUserChildrenReducer(state, action);
    case UPDATE_MFA_METHOD:
      return updateUserMfaReducer(state, action);
    case REMOVE_MFA_METHOD:
      return updateUserMfaReducer(state, action);
    default:
      return state;
  }
};

export default users;
