import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { updateMedicalInfo } from '../actions/medical-info';

const createMedicalInfoAsync = (userId, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'medical_infos', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/medical_info`, data, { query }).then(({ data: results }) => {
      dispatch(updateMedicalInfo(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateMedicalInfoAsync = (userId, id, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'medical_infos', id, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/medical_info`, data, { query }).then(({ data: results }) => {
      dispatch(updateMedicalInfo(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getMedicalInfoAsync = (userId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/medical_info`).then(({ data: results }) => {
      dispatch(updateMedicalInfo(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createMedicalInfoAsync,
  updateMedicalInfoAsync,
  getMedicalInfoAsync
};
