import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../page';
import Activity from '../../activity';
import UserBaselinesTable from './user-baselines-table';
import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { useIsMounted, useMount } from '@/hooks';
import replaceLinkParams from '@/utilities/replace-link-params';
import { UserPaths } from '@/paths';
import { ErrorBanner } from '../../errors';
import { ContinueBaselineModalButton } from './continue-baseline-modal';
import Strings from './lang';
import Icon from '../../icon';

const REFRESH_THROTTLE = 2000;

const getBaselines = async () => {
  const clinicId = getCurrentClinicId() || 0;
  const { data } = await api.v1.get(`/clinics/${clinicId}/baselines/in_progress`);
  return data;
};

const Header = ({ 
  refreshDisabled = false, 
  onRefresh 
}) => {
  return (
    <div className="continue-baseline-header">
      <div className="header-left">
        <h1 className="display">
          {Strings.title}
        </h1>
      </div>
      <div className="header-right">
        <div className="text-center">
          <small>{Strings.actionGroupLabel}</small>
        </div>
        <div className="button-group">
          <button 
            type="button" 
            className="btn btn-danger btn-sm"
            disabled={refreshDisabled}
            onClick={onRefresh}
          >
            <Icon name="arrows-rotate" />&nbsp;&nbsp;{Strings.refreshListButton}
          </button>
          <ContinueBaselineModalButton className="btn btn-sm btn-default">
            {Strings.searchPatientsButton}
          </ContinueBaselineModalButton>
        </div>
      </div>
    </div>
  );
};

const ContinueBaseline = () => {
  const navigate = useNavigate();
  const [baselines, setBaselines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMounted = useIsMounted();
  const [refreshDisabled, setRefreshDisabled] = useState(false);

  const onSelect = useCallback((baseline) => {
    const { id, user } = baseline;
    navigate(
      replaceLinkParams(UserPaths.baselines.steps.continue.link, {
        userId: user.id,
        baselineId: id
      }),
      {
        state: { referrer: window.location.pathname }
      }
    );
  }, [navigate]);

  const loadBaselines = useCallback(() => {
    setLoading(true);
    setError(null);

    getBaselines().then(baselines => {
      setBaselines(baselines);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, []);

  const onRefresh = useCallback(() => {
    if (refreshDisabled) return;
    setRefreshDisabled(true);
    loadBaselines();

    setTimeout(() => {
      if (isMounted) {
        setRefreshDisabled(false);
      }
    }, REFRESH_THROTTLE);
  }, [isMounted, loadBaselines, refreshDisabled]);

  useMount(() => {
    loadBaselines();
  });

  return (
    <Page className="continue-baseline-page">
      <Header 
        refreshDisabled={loading || refreshDisabled}
        onRefresh={onRefresh}
      />
      <ErrorBanner error={error} />
      <p>
        {Strings.tableHeader}
      </p>
      <Activity active={loading} static>
        <UserBaselinesTable 
          baselines={baselines} 
          onSelect={onSelect}
        />
      </Activity>
    </Page>
  );
};

export default ContinueBaseline;
