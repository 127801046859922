const FR = {
  title: 'Connexion',
  resetLinkText: 'Oublié votre mot de passe?',
  emailLabelText: 'Courriel',
  emailInputPlaceholder: 'nom@exemple.com',
  passwordLabelText: 'Mot de passe',
  passwordInputPlaceholder: 'motdepasse123',
  rememberEmailLabelText: 'Se souvenir de moi',
  submitButtonText: 'Connexion',
  logoutText: 'Déconnexion',
  clinicsTitle: 'Bienvenue',
  clinicsMessage: 'À partir de quelle clinique travaillez-vous, aujourd\'hui?',
  clinicsButtonText: 'Terminer',
  salesText: 'Le portail de connexion est approuvé pour les cliniques de Complete Concussion Management et leurs partenaires. Pour plus d’information, communiquez avec {0}CCMI{1}.',
  switchAccountMessageText: 'Connecté en tant que {0}.',
  switchAccountLinkText: 'Cliquez ici pour changer de compte.',
  areYouAPatientText: 'Êtes-vous un patient?',
  registerHereLinkText: 'Connectez-vous ou créez un compte ici',
  newSignInText: 'Nouvelle connexion',
  mfaChallengeTitle: 'Authentification multifacteur',
  totpChallengeMessage: 'Utilisez votre <strong>Authenticator</strong> enregistré pour fournir un code de vérification ci-dessous ou entrez un code de récupération.',
  emailChallengeMessage: 'Un message avec un code de vérification a été envoyé à votre <strong>adresse e-mail</strong>. Saisissez le code pour continuer.',
  mfaCodeInputLabel: 'Code MFA',
  invalidMfaCodeFormat: 'Format de code non valide',
  trustDeviceLabel: 'Ne me demandez pas de vérification sur ce navigateur pendant 30 jours',
  verifyLabel: 'Vérifier',
  verifyingLabel: 'Vérification'
};

export default FR;
