import api from '@/lib/api-request';
import { removeMfaMethod, updateMfaMethod } from '../actions/mfa-method';
import HTTPStatusCodes from '@/utilities/http-status-codes';

const getMfaMethodAsync = (userId) => {
  return dispatch => {
    return api.auth.get('/mfa/method').then(({ data: mfaMethod }) => {
      dispatch(updateMfaMethod(userId, mfaMethod));
      return Promise.resolve(mfaMethod);
    }).catch(error => {
      if (error.status === HTTPStatusCodes.NotFound) {
        dispatch(removeMfaMethod(userId));
        return Promise.resolve(null);
      }

      return Promise.reject(error);
    });
  };
};

const createMfaMethodAsync = (userId, attributes) => {
  const data = { type: 'mfa_methods', attributes };

  return dispatch => {
    return api.auth.post('/mfa/method', data).then(({ data: mfaMethod }) => {
      dispatch(updateMfaMethod(userId, mfaMethod));
      return Promise.resolve(mfaMethod);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const verifyMfaMethodAsync = (userId, attributes = {}) => {
  const data = { type: 'mfa_methods', attributes };

  return dispatch => {
    return api.auth.post('/mfa/method/verify', data).then(({ data: mfaMethod }) => {
      const { recovery_codes, ...method } = mfaMethod;
      dispatch(updateMfaMethod(userId, method));
      return Promise.resolve({ recovery_codes, ...method });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const disableMfaMethodAsync = (userId, attributes) => {
  const data = { type: 'mfa_methods', attributes };
  
  return dispatch => {
    return api.auth.post('/mfa/method/disable', data).then(() => {
      dispatch(removeMfaMethod(userId));
      return Promise.resolve();
    }).catch(error => { 
      return Promise.reject(error);
    });
  };
};

export {
  getMfaMethodAsync,
  createMfaMethodAsync,
  disableMfaMethodAsync,
  verifyMfaMethodAsync
};
