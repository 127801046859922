const EN = {
  bulkPurchaseHeading: 'Buy in Bulk and Save!',
  bulkPurchaseSubheading: 'Credits never expire. Credits are good for one baseline test and unlimited injuries for a given patient within a one year period.',
  bulkPurchaseFullPriceText: 'Full price for a credit is {0} {1}',
  bulkPurchaseModalAgreementText: 'By selecting "Add to Invoice", I agree to pay this balance in full by the end of the current billing month. Payment is due at that time. If payment is not received by the due date, I understand that interest charges of 15% per month will be applied to the remaining balance until it is paid in full.',
  creditLabel: 'Credit',
  discountLabel: '{0}% Discount',
  savingsLabel: 'Save',
  purchaseCreditsTitle: 'Buy {0} Credits',
  balanceAddedLabel: 'Added Balance: {0} {1}',
  applicableTaxesText: 'Plus applicable taxes',
  cancelButtonText: 'Cancel',
  addToInvoiceButtonText: 'Add to Invoice',
  addingCreditsLoadingTitle: 'Adding Credits',
  addingCreditsLoadingMessage: 'This may take a few seconds',
  creditsAddedSuccessTitle: 'Credits Added Successfully!',
  creditsAddedSuccessMessage: '{0} credits have been added to your invoice',
  creditsAddedErrorTitle: 'Something Went Wrong',
  creditsAddedErrorMessage: 'Error: {0}',
  tryLaterMessage: 'Please Try Again Later',
  closeButtonText: 'Close',
  creditQuantityLabel: 'Number of Credits',
  creditQuantityErrorMessage: 'Value must be greater than 0',
  costAmountLabel: 'Price of credits (Price is in {0})',
  costAmountErrorMessage: 'Please enter a value greater than or equal to 0',
  totalCostLabel: 'Total Cost',
  pushCreditsButtonText: 'Push Credits'
};

export default EN;
