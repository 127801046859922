const EN = {
  title: 'Sign In',
  resetLinkText: 'Forgot password?',
  emailLabelText: 'Email',
  emailInputPlaceholder: 'user@example.com',
  passwordLabelText: 'Password',
  passwordInputPlaceholder: 'password123',
  rememberEmailLabelText: 'Remember Me',
  submitButtonText: 'Sign In',
  logoutText: 'Sign Out',
  clinicsTitle: 'Welcome',
  clinicsMessage: 'Which clinic are you working from today?',
  clinicsButtonText: 'Finish',
  salesText: 'Login portal is for recognized Complete Concussion Management clinics and partners. Contact {0}CCMI{1} for more information.',
  switchAccountMessageText: 'Signed in as {0}.',
  switchAccountLinkText: 'Click here to switch accounts.',
  areYouAPatientText: 'Are you a patient?',
  registerHereLinkText: 'Sign In or Create Account Here',
  newSignInText: 'New Sign In',
  mfaChallengeTitle: '2-Step Verification',
  totpChallengeMessage: 'Use your registered <strong>Authenticator</strong> to provide a verification code below, or enter a recovery code.',
  emailChallengeMessage: 'A message with a verification code has been sent to your <strong>email</strong>. Enter the code to continue.',
  mfaCodeInputLabel: 'MFA Code',
  invalidMfaCodeFormat: 'Invalid Code Format',
  trustDeviceLabel: 'Do not ask me for verification on this browser for 30 days',
  verifyLabel: 'Verify',
  verifyingLabel: 'Verifying'
};

export default EN;
