import React, { useEffect, useState } from 'react';
import Icon from '@/components/icon';
import { CheckboxInputGroup } from '@/forms';
import Strings from '../lang';

const makeCodeFileUrl = (codes = []) => {
  const data = new Blob([codes.join('\n')], { type: 'text/plain', endings: 'native' });
  return URL.createObjectURL(data);
};

const useRecoveryCodesUrl = (codes = []) => {
  const url = makeCodeFileUrl(codes);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [url]);

  return url;
};

export const AuthAppRecoveryCodesView = ({
  recoveryCodes = [],
  onDone
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const codesUrl = useRecoveryCodesUrl(recoveryCodes);

  return (
    <div className="mfa-auth-app-recovery-codes">
      <h2 className="text-center">
        <Icon name="check-circle" />&nbsp;&nbsp;
        {Strings.authAppVerificationCompleteTitle}
      </h2>
      <div>
        <p>{Strings.authAppVerificationCompleteMessage}</p>
        <p>
          {Strings.authAppRecoveryCodesMessage}&nbsp;
          <strong><em>{Strings.authAppRecoveryCodesWarning}</em></strong>
        </p>
      </div>
      <div className="recovery-codes-list">
        <code>
          {recoveryCodes.map((code, i) => (
            <div key={i}>{code}</div>
          ))}
        </code>
        <div className="download-btn-container">
          <a
            href={codesUrl}
            download="complete-concussions-recovery-codes.txt"
            className="edit-buton"
            onClick={() => setConfirmed(true)}
          >
            <Icon name="download" />&nbsp;&nbsp;
            <span>{Strings.downloadRecoveryCodesLabel}</span>
          </a>
        </div>
      </div>
      <CheckboxInputGroup
        inputValid
        className="recovery-codes-confirmation"
        labelText={Strings.recoveryCodesConfirmation}
        inputProps={{
          checked: confirmed,
          onChange: e => {
            setConfirmed(e.target.checked);
          }
        }}
      />
      <div className="flex-row justify_center align_center">
        <button
          type="button"
          className="btn btn-primary"
          disabled={!confirmed}
          onClick={onDone}
        >
          {Strings.doneText}
        </button>
      </div>
    </div>
  );
};
