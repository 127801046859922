import api from '@/lib/api-request';
import { updateUserBaselineTests } from '../actions/user-baseline-tests';

const getUserBaselineTestsAsync = (userId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/baselines`).then(({ data: results }) => {
      dispatch(updateUserBaselineTests(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getUserBaselineTestsAsync
};
