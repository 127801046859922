import api from '@/lib/api-request';
import { updateClinicStatistics } from '../actions/clinic-statistics';

const getClinicStatisticsAsync = (clinicId) => {
  return (dispatch) => {
    return api.v1.get(`/clinics/${clinicId}/statistics`).then(({ data: stats }) => {
      dispatch(updateClinicStatistics(clinicId, stats));
      return Promise.resolve({ clinicId, stats });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicStatisticsAsync
};
