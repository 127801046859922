import React, { useState } from 'react';
import BalanceStats from './balance-stats';
import { useMount } from '@/hooks';
import api from '@/lib/api-request';
import { ErrorBanner } from '../errors';
import Strings from './lang';

const getClinicBalanceStats = async () => {
  const { data } = await api.v1.get('/clinics/balances');
  return data;
};

const ClinicBalances = () => {
  const [stats, setStats] = useState({});
  const [error, setError] = useState(null);

  useMount(() => {
    setError(null);

    getClinicBalanceStats().then(stats => {
      setStats(stats);
    }).catch(error => {
      setError(error.message);
    });
  });

  if (error) {
    return (
      <ErrorBanner 
        error={Strings.formatString(Strings.clinicBalanceError, error)} 
      />
    );
  }

  return (
    <BalanceStats stats={stats} />
  );
};

export default ClinicBalances;
