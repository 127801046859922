import { combineReducers } from 'redux';
import { SESSION_SIGNOUT } from '../actions/session';
import users from './users';
import clinics from './clinics';
import clinicBalance from './clinic-balance';
import clinicInvoices from './clinic-invoices';
import clinicPlayersSearch from './clinic-players-search';
import clinicPatients from './clinic-patients';
import clinicStatistics from './clinic-statistics';
import clinicUsers from './clinic-users';
import medicalInfo from './medical-info';
import baselineTests from './baseline-tests';
import userBaselineTests from './user-baseline-tests';
import batches from './batches';
import injuries from './injuries';
import injuryReports from './injury-reports';
import voms from './voms';
import injuryAssessments from './injury-assessments';
import buffalo from './buffalo';
import blackhawks from './blackhawks';
import soapNotes from './soap-notes';
import symptomsUpdates from './symptoms-updates';
import notifications from './notifications';
import progressReports from './progress-reports';
import teamLeaders from './team-leaders';
import allClinicsStatistics from './all-clinics-statistics';
import guardiansAccount from './guardians-account';
import registration from './registration';
import taxes from './taxes';
import drive from './drive';
import danaBatteries from './dana-batteries';
import referrals from './referrals';
import specialistNotes from './specialist-notes';
import products from './products';
import ovsTests from './ovs-tests';
import clinicIdtClaims from './clinic-idt-claims';
import idtClaims from './idt-claims';
import session from './session';
import mfaMethod from './mfa-method';

const appReducer = combineReducers({
  users,
  clinics,
  clinicBalance,
  clinicInvoices,
  clinicPlayersSearch,
  clinicPatients,
  clinicStatistics,
  clinicUsers,
  medicalInfo,
  baselineTests,
  userBaselineTests,
  batches,
  injuries,
  injuryReports,
  voms,
  injuryAssessments,
  buffalo,
  blackhawks,
  soapNotes,
  notifications,
  progressReports,
  symptomsUpdates,
  teamLeaders,
  allClinicsStatistics,
  guardiansAccount,
  registration,
  taxes,
  drive,
  danaBatteries,
  referrals,
  specialistNotes,
  products,
  ovsTests,
  clinicIdtClaims,
  idtClaims,
  session,
  mfaMethod
});

const rootReducer = (state = {}, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case SESSION_SIGNOUT:
      newState = {};
      break;
    default:
      break;
  }

  return appReducer(newState, action);
};

export default rootReducer;
