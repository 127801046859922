import React, { useCallback, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { createNetworkSessionAsync } from '@/redux/thunks/sessions';
import { getRememberedUsers, removeRememberedUser } from '@/utilities/email-storage';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import {
  DashboardPaths,
  AuthenticationPaths,
  RegistrationPaths,
  ClinicUserOnboardingPaths
} from '@/paths';
import { Page } from '../page';
import { ErrorBanner, ErrorStrings } from '../errors';
import AuthenticationForm from './authentication-form';
import RememberedUsersAuthentication from './remembered-users-authentication';
import Activity from '../activity';
import { RoleDescriptions, userHasRoleMatchingOneOfDescriptions } from '@/utilities/user-roles';
import Strings from './lang';
import MfaChallenge from './mfa-challenge';

/**
 * To Disable any 'Remember Me' functionality (remembered users list and remember me button), 
 * set this variable to false,
 */
const REMEMBER_ME_ENABLED = true;
const CCMI_URL = 'https://completeconcussions.com/';

const isProfileComplete = (user) => {
  const isClinician = userHasRoleMatchingOneOfDescriptions(
    user,
    [RoleDescriptions.Clinician, RoleDescriptions.Specialist, RoleDescriptions.IDTClinician]
  );

  if (
    (!user?.person?.first_name || !user?.person?.last_name)
    || (isClinician && !user?.clinical_info?.profession)
  ) {
    return false;
  }

  return true;
};

const next = (user, navigate, location) => {
  const { nextPathname } = location?.state || {};

  if (!nextPathname && !isProfileComplete(user)) {
    navigate(ClinicUserOnboardingPaths.index.link, {
      replace: true,
      state: {
        profileIncomplete: true,
        nextPathname: user.clinics?.length > 1
          ? AuthenticationPaths.clinics.link
          : DashboardPaths.index.link
      }
    });
  } else if (user.clinics && user.clinics.length > 1) {
    navigate(AuthenticationPaths.clinics.link, { state: { nextPathname }, replace: true });
  } else if (nextPathname) {
    navigate(nextPathname, { replace: true });
  } else {
    navigate(DashboardPaths.index.link, { replace: true });
  }
};

const Authentication = () => {
  const loading = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [rememberedUsers, setRememberedUsers] = useState(() => getRememberedUsers());
  const [newLogin, setNewLogin] = useState(rememberedUsers.length === 0);
  const [activity, setActivity] = useState(false);
  const [mfaChallenge, setMfaChallenge] = useState(null);
  const [error, setError] = useState(null);

  const authenticate = useCallback(async ({ email, password }, rememberEmail = false) => {
    if (loading.current) return;

    loading.current = true;
    setActivity(true);
    setError(null);

    dispatch(createNetworkSessionAsync({ email, password }, rememberEmail)).then((data) => {
      loading.current = false;
      setActivity(false);

      if (data.mfa_challenge) {
        setMfaChallenge({
          type: data.method,
          token: data.challenge_token
        });
      } else {
        next(data, navigate, location);
      }
    }).catch(error => {
      loading.current = false;
      let { message } = error;
      if (error.status === HTTPStatusCodes.Unauthorized) {
        message = ErrorStrings.error401Signin;
      }

      setActivity(false);
      setError(message);
    });
  }, [dispatch, location, navigate]);

  const onMfaVerified = useCallback(user => {
    next(user, navigate, location);
  }, [location, navigate]);

  const onResetLogin = useCallback(() => {
    setMfaChallenge(null);
    setNewLogin(rememberedUsers?.length === 0);
    setError(null);
    setActivity(false);
  }, [rememberedUsers?.length]);

  const onNewLogin = useCallback(() => {
    setError(null);
    setNewLogin(true);
  }, []);

  const onRemoveRememberedUser = useCallback((user) => {
    if (removeRememberedUser(user)) {
      const users = getRememberedUsers();
      setRememberedUsers(users);
      setNewLogin(users.length === 0);
      setError(null);
    }
  }, []);

  return (
    <Page
      hideNavigationBar
      showHeaderImage
      hideBannerIfClinicDeactivated
      className="authentication"
    >
      <If condition={!!mfaChallenge}>
        <Then>
          <div>
            <h1 className="display dot text-center">
              {Strings.mfaChallengeTitle}
            </h1>
            <MfaChallenge
              challenge={mfaChallenge}
              onVerified={onMfaVerified}
              onReset={onResetLogin}
            />
          </div>
        </Then>
        <Else>
          <div>
            <div className="row">
              <div className="col-md-4 col-md-offset-4">
                <h1 className="display dot">{Strings.title}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-md-offset-4 patient-registration-info">
                <small>
                  {Strings.areYouAPatientText}&nbsp;
                  <Link to={RegistrationPaths.index.link} className="semi-bold">
                    {Strings.registerHereLinkText}
                  </Link>
                </small>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-md-offset-4">
                <ErrorBanner error={error} />
                <Activity active={activity}>
                  <If condition={!newLogin && REMEMBER_ME_ENABLED}>
                    <Then>
                      <RememberedUsersAuthentication
                        rememberedUsers={rememberedUsers}
                        onRemoveUser={onRemoveRememberedUser}
                        onAuthenticate={authenticate}
                        onNewLogin={onNewLogin}
                      />
                    </Then>
                    <Else>
                      <AuthenticationForm
                        displayRememberMe={REMEMBER_ME_ENABLED}
                        onSubmit={authenticate}
                      />
                    </Else>
                  </If>
                </Activity>
                <p className="authentication-sales">
                  <small
                    dangerouslySetInnerHTML={{
                      // Set the link tags as strings and inject as HTML to avoid escaing:
                      __html: Strings.formatString(Strings.salesText, `<a href="${CCMI_URL}" target="_blank">`, '</a>')
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </Else>
      </If>
    </Page>
  );
};

export default Authentication;
