import api from '@/lib/api-request';
import { updateAllClinicsStatistics } from '../actions/all-clinics-statistics';

const getAllClinicsStatisticsAsync = () => {
  return (dispatch) => {
    return api.v1.get('/clinics/statistics').then(({ data: stats }) => {
      dispatch(updateAllClinicsStatistics(stats));
      return Promise.resolve(stats);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getAllClinicsStatisticsAsync
};
