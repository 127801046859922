import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { updateBuffaloTest } from '../actions/buffalo';

const getBuffaloTestAsync = (userId, injuryId, buffaloId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/buffalo_tests/${buffaloId}`).then(({ data: results }) => {
      dispatch(updateBuffaloTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createBuffaloTestAsync = (userId, injuryId, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'buffalo_tests', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/buffalo_tests`, data, { query }).then(({ data: results }) => {
      dispatch(updateBuffaloTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateBuffaloTestAsync = (userId, injuryId, buffaloId, attributes) => {
  const data = { type: 'buffalo_tests', id: buffaloId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}/buffalo_tests/${buffaloId}`, data).then(({ data: results }) => {
      dispatch(updateBuffaloTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getBuffaloTestAsync,
  createBuffaloTestAsync,
  updateBuffaloTestAsync
};
