const FR = {
  bulkPurchaseHeading: 'Achetez en bloc et épargnez !',
  bulkPurchaseSubheading: 'Les Crédits n’expirent jamais. Les Crédits sont valables pour un test de référence et peuvent servir pour toutes les blessures d’un patient, comprises dans la même année.',
  bulkPurchaseFullPriceText: 'Le plein prix d’un crédit est de {0} {1}',
  bulkPurchaseModalAgreementText: 'En sélectionnant « Ajouter à la facture », j\'accepte de régler ce solde en totalité avant la fin du mois de facturation en cours. Le paiement est dû à cette date. En cas de non-paiement à la date d\'échéance, des intérêts de 15% par mois seront appliqués au solde restant jusqu\'à son règlement intégral.',
  creditLabel: 'Crédit',
  discountLabel: '{0}% de réduction',
  savingsLabel: 'Sauver',
  purchaseCreditsTitle: 'Acheter {0} crédits',
  balanceAddedLabel: 'Solde ajouté: {0} {1}',
  applicableTaxesText: 'Plus applicable taxes',
  cancelButtonText: 'Annuler',
  addToInvoiceButtonText: 'Ajouter à la facture',
  addingCreditsLoadingTitle: 'Ajout de crédits',
  addingCreditsLoadingMessage: 'Cela peut prendre quelques secondes',
  creditsAddedSuccessTitle: 'Crédits ajoutés avec succès!',
  creditsAddedSuccessMessage: '{0} crédits ont été ajoutés à votre facture',
  creditsAddedErrorTitle: 'Quelque chose ne va pas',
  creditsAddedErrorMessage: 'Erreur: {0}',
  tryLaterMessage: 'Veuillez réessayer plus tard',
  closeButtonText: 'Fermer',
  creditQuantityLabel: 'Nombre de crédits',
  creditQuantityErrorMessage: 'La valeur doit être supérieure à 0',
  costAmountLabel: 'Prix ​​des crédits (le prix est en {0})',
  costAmountErrorMessage: 'Veuillez saisir une valeur supérieure ou égale à 0',
  totalCostLabel: 'Coût total',
  pushCreditsButtonText: 'Crédits push'
};

export default FR;
