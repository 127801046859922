import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { updateSpecialistNote, updateSpecialistNotes } from '../actions/specialist-notes';

const getSpecialistNoteAsync = (userId, injuryId, noteId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/specialist_notes/${noteId}`).then(({ data: results }) => {
      dispatch(updateSpecialistNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getSpecialistNotesAsync = (userId, injuryId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/specialist_notes`).then(({ data: results }) => {
      dispatch(updateSpecialistNotes(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createSpecialistNoteAsync = (userId, injuryId, attrs) => {
  const attributes = { ...attrs };
  if (!attributes.clinic_id) {
    attributes.clinic_id = getCurrentClinicId();
  }

  const data = { type: 'specialist_notes', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/specialist_notes`, data).then(({ data: results }) => {
      dispatch(updateSpecialistNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateSpecialistNoteAsync = (userId, injuryId, noteId, attributes) => {
  const data = { type: 'specialist_notes', id: noteId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}/specialist_notes/${noteId}`, data).then(({ data: results }) => {
      dispatch(updateSpecialistNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getSpecialistNoteAsync,
  getSpecialistNotesAsync,
  createSpecialistNoteAsync,
  updateSpecialistNoteAsync
};
