import api from '@/lib/api-request';
import { updateReferrals, updateReferral } from '../actions/referrals';
import { addInjuryReferral, updateInjuryReferral } from '../actions/injuries';

const getClinicReferralsAsync = (clinicId, open = null) => {
  return dispatch => {
    return api.v1.get(`/clinics/${clinicId}/referrals`, { open }).then(({ data: referrals }) => {
      dispatch(updateReferrals(referrals));
      return Promise.resolve(referrals);
    });
  };
};

const getInjuryReferralsAsync = (userId, injuryId) => {
  return dispatch => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/referrals`).then(({ data: referrals }) => {
      dispatch(updateReferrals(referrals));
      return Promise.resolve(referrals);
    });
  };
};

const createReferralAsync = (attributes) => {
  const data = { type: 'referrals', attributes };

  return dispatch => {
    return api.v1.post('/referrals', data).then(({ data: referral }) => {
      dispatch(updateReferral(referral));
      dispatch(addInjuryReferral(referral.user.id, referral.injury_id, referral));

      return Promise.resolve(referral);
    });
  };
};

const getReferralAsync = (referralId) => {
  return dispatch => {
    return api.v1.get(`/referrals/${referralId}`).then(({ data: referral }) => {
      dispatch(updateReferral(referral));
      return Promise.resolve(referral);
    });
  };
};

const createReferralStatusUpdateRequest = (type) => (referralId, attributes) => {
  const data = { type: 'referrals', id: referralId, attributes };

  return dispatch => {
    return api.v1.patch(`/referrals/${referralId}/${type}`, data).then(({ data: referral }) => {
      dispatch(updateReferral(referral));
      dispatch(updateInjuryReferral(referral.user.id, referral.injury_id, referral));
      return Promise.resolve(referral);
    });
  };
};

const acceptReferralAsync = createReferralStatusUpdateRequest('accept');
const rejectReferralAsync = createReferralStatusUpdateRequest('reject');
const cancelReferralAsync = createReferralStatusUpdateRequest('cancel');

export {
  getClinicReferralsAsync,
  getInjuryReferralsAsync,
  getReferralAsync,
  createReferralAsync,
  acceptReferralAsync,
  rejectReferralAsync,
  cancelReferralAsync
};
