import React, { useCallback, useState } from 'react';
import BulkCreditPurchaseModal from './bulk-credits-purchase-modal';
import Strings from './lang';
import { useMount } from '@/hooks';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import api from '@/lib/api-request';

const ProductCard = ({
  currency,
  clinicId,
  quantity,
  total,
  savings,
  price,
  discount,
  onPurchased
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="product-card">
      <div className="product-details">
        <div className="product-heading">{quantity}</div>
        <div className="text-muted detail-item">
          {price} / {Strings.creditLabel}
        </div>
        <div className="success detail-item bold">
          {Strings.formatString(
            Strings.discountLabel,
            discount || 0
          )}
        </div>
        <div className="text-muted detail-item">
          {Strings.savingsLabel} <strong>{savings}</strong>
        </div>
      </div>
      <button 
        type="button" 
        className="btn btn-sm btn-success"
        onClick={() => setOpen(true)}
      >
        {Strings.formatString(
          Strings.purchaseCreditsTitle,
          quantity
        )}
      </button>
      <BulkCreditPurchaseModal 
        isOpen={open}
        clinicId={clinicId}
        quantity={quantity}
        totalCost={total}
        currency={currency}
        onPurchased={onPurchased}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

const BulkCreditProducts = ({ clinicId, onPurchased = () => {} }) => {
  const [bulkPricing, setBulkPricing] = useState(null);
  const [loading, setLoading] = useState(!bulkPricing);
  const [error, setError] = useState(null);

  const getBulkCreditPricing = useCallback(() => {
    setLoading(true);

    api.v1.get(`/clinics/${clinicId}/bulk_tokens/pricing`).then(({ data }) => {
      setBulkPricing(data);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [clinicId]);

  useMount(() => {
    if (!bulkPricing) {
      getBulkCreditPricing();
    }
  });

  if (error) {
    return (
      <ErrorBanner error={error} />
    );
  }

  return (
    <Activity active={loading} static={!bulkPricing}>
      <div>
        <h1 className="text-center primary">
          {Strings.bulkPurchaseHeading}
        </h1>
        <div className="text-center">
          {Strings.bulkPurchaseSubheading}
        </div>
        <div className="text-center">
          {Strings.formatString(
            Strings.bulkPurchaseFullPriceText,
            bulkPricing?.base_price ?? '',
            bulkPricing?.currency ?? ''
          )}
        </div>
        <div className="credit-products">
          {bulkPricing?.pricing?.map((prices, index) => (
            <ProductCard 
              key={index}
              clinicId={clinicId}
              currency={bulkPricing.currency}
              quantity={prices.quantity}
              total={prices.total}
              savings={prices.savings}
              price={prices.price}
              discount={prices.discount}
              onPurchased={onPurchased}
            />
          ))}
        </div>
      </div>
    </Activity>
  );
};

export default BulkCreditProducts;
