import api from '@/lib/api-request';
import { addBatch, updateBatch, updateBatches } from '../actions/batches';

const createBatchAsync = (attributes) => {
  const data = { type: 'batches', attributes };
  return (dispatch) => {
    return api.v1.post('/batches', data).then(({ data: result }) => {
      dispatch(addBatch(result));
      return Promise.resolve(result);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getBatchAsync = (batchId) => {
  return (dispatch) => {
    return api.v1.get(`/batches/${batchId}`).then(({ data: result }) => {
      dispatch(updateBatch(result));
      return Promise.resolve(result);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getBatchesAsync = () => {
  return (dispatch) => {
    return api.v1.get('/batches').then(({ data: results }) => {
      dispatch(updateBatches(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createBatchAsync,
  getBatchAsync,
  getBatchesAsync
};
