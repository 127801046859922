import createService from './create-service';

const api = {
  auth: createService({
    baseURL: process.env.CCMI_API_ENDPOINT_HOST,
    service: 'auth',
    version: 'v1'
  }),
  v1: createService({
    baseURL: process.env.CCMI_API_ENDPOINT_HOST,
    version: 'v1'
  }),
};

export default api;
