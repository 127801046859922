import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMount } from '@/hooks';
import { getCurrentUserAsync } from '@/redux/thunks/users';
import Activity from '../activity';
import { SessionContext, useSessionContext } from './context';
import {
  getActivity,
  getAuthToken,
  removeAuthData,
  removeCurrentClinicId
} from '@/tokens';
import { activityLasped } from '@/utilities/user-activity';
import { sessionSignout } from '@/redux/actions/session';
import { AuthenticationPaths } from '@/paths';
import api from '@/lib/api-request';
import log from '@/utilities/log';

const logOutAsync = (token) => {
  const getAuthToken = token ? () => token : undefined;
  return api.auth.delete('/session', { getAuthToken });
};

const hasActivityLapsed = () => {
  const activity = getActivity() || 0;
  return activityLasped(activity);
};

const sessionExpired = (token) => {
  try {
    const { exp } = JSON.parse(window.atob(token.split('.')[1]));
    const expDate = dayjs.unix(exp);
    const activityExpired = hasActivityLapsed();

    if (!expDate.isValid() || dayjs().isSameOrAfter(expDate, 'day') || activityExpired) {
      removeAuthData();
      removeCurrentClinicId();

      if (activityExpired) {
        logOutAsync(token).catch((e) => {
          log('[Logout Failed]:', e.message);
        });
      }
      
      return true;
    }

    return false;
  } catch (e) {
    return true;
  }
};

const shouldLoadCurrentUser = () => {
  const token = getAuthToken();
  return !!token && !sessionExpired(token);
};

const CurrentUserLoader = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(() => shouldLoadCurrentUser());

  const logout = useCallback(() => {
    setLoading(true);
    logOutAsync().then(() => {
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    }).finally(() => {
      removeAuthData();
      removeCurrentClinicId();
      dispatch(sessionSignout());
      navigate(AuthenticationPaths.index.link);
    });
  }, [dispatch, navigate]);

  const contextValue = useMemo(() => ({ logout }), [logout]);

  useMount(() => {
    if (loading) {
      // Any error will remove authentication and the route permissions will
      // redirect accordingly, so don't redirect on Not Authenticated errors
      dispatch(getCurrentUserAsync({ redirectNotAuthenticated: false })).then(() => {
        setLoading(false);
      }).catch((e) => {
        if (e.status) {
          removeAuthData();
        }
        
        setLoading(false);
      });
    }
  });

  if (loading) {
    return (
      <div className="current-user-loader">
        <Activity
          static
          active
          titleComponent={(
            <h1 className="display">
              Complete Concussions System
            </h1>
          )}
        />
      </div>
    );
  }

  return (
    <SessionContext.Provider value={contextValue}>
      <>{children}</>
    </SessionContext.Provider>
  );
};

export default CurrentUserLoader;
export { useSessionContext };
