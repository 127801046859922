import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { If, Then, Else } from 'react-if';
import {
  MfaSetupView,
  MfaMethodCard,
  MfaSetupModal,
  RemoveMfaModal
} from '../multifactor';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import Icon from '../icon';
import { useMount } from '@/hooks';
import { UserSelector } from '@/redux/selectors';
import { getMfaMethodAsync } from '@/redux/thunks/mfa-method';
import Strings from './lang';

const getStatusIcon = (status) => {
  switch (status) {
    case 'verified':
      return 'check-circle';
    case 'pending':
      return 'exclamation-triangle';
    case 'disabled':
    default:
      return 'xmark-circle';
  }
};

const getStatusString = (status) => ({
  verified: Strings.mfaStatusVerified,
  pending: Strings.mfaStatusPending,
  disabled: Strings.mfaStatusDisabled
}[status]);

const MfaEnabledView = ({ method, onRemove, onVerifySetup }) => {
  return (
    <div>
      <p>
        {method?.verified_at
          ? Strings.mfaVerifiedMessage
          : Strings.mfaPeningMessage}
      </p>
      {!!method && (
        <MfaMethodCard
          method={method}
          onVerifySetup={onVerifySetup}
          onRemove={onRemove}
        />
      )}
    </div>
  );
};

const MfaProfileTabView = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const mfaMethod = useSelector(state => state.mfaMethod);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [setupModalOpen, setSetupModalOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const getMfaMethod = useCallback(() => {
    setLoading(true);
    setError(null);

    dispatch(getMfaMethodAsync(currentUser.id)).then(() => {
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [currentUser.id, dispatch]);

  useMount(() => {
    if (currentUser.mfa.enabled && !mfaMethod) {
      getMfaMethod();
    }
  });

  if (loading) {
    return (
      <Activity active static />
    );
  }

  return (
    <>
      <div className="mfa-profile-tab-view">
        <div className="mfa-profile-tab-view-header">
          <div className="mfa-profile-tab-view-title">
            {Strings.mfaTabTitle}
          </div>
          <div className={classNames('mfa-status', currentUser.mfa?.status)}>
            <Icon name={getStatusIcon(currentUser.mfa?.status)} />&nbsp;
            <span className="text-muted">{getStatusString(currentUser.mfa?.status)}</span>
          </div>
        </div>
        <If condition={!!currentUser.mfa?.enabled}>
          <Then>
            <ErrorBanner error={error} />
            <Activity static active={loading}>
              {!!mfaMethod && (
                <MfaEnabledView
                  method={mfaMethod}
                  onRemove={() => setRemoveModalOpen(true)}
                  onVerifySetup={() => setSetupModalOpen(true)}
                />
              )}
            </Activity>
          </Then>
          <Else>
            <MfaSetupView onStartSetup={() => setSetupModalOpen(true)} />
          </Else>
        </If>
      </div>
      <MfaSetupModal
        method={mfaMethod}
        isOpen={setupModalOpen}
        onClose={() => setSetupModalOpen(false)}
      />
      <RemoveMfaModal
        userId={currentUser.id}
        method={mfaMethod}
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
      />
    </>
  );
};

export default MfaProfileTabView;
