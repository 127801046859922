import api from '@/lib/api-request';
import { updateTeamLeaders } from '../actions/team-leaders';

const getTeamLeadersAsync = () => {
  return (dispatch) => {
    return api.v1.get('/users/leaders').then(({ data: teamLeaders }) => {
      dispatch(updateTeamLeaders(teamLeaders));
      return Promise.resolve(teamLeaders);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getTeamLeadersAsync
};
