import React, { forwardRef } from 'react';
import { OTPInput as BaseOTPInput, REGEXP_ONLY_DIGITS } from 'input-otp';

const DEFAULT_MAX_LENGTH = 6;

function FakeCaret() {
  return (
    <div className="fake-caret">
      <div className="cursor" />
    </div>
  );
}

const OtpInput = forwardRef(({
  value = '',
  length = DEFAULT_MAX_LENGTH,
  disabled = false,
  onChange
}, ref) => {
  return (
    <BaseOTPInput
      ref={ref}
      value={value}
      inputMode="numeric"
      disabled={disabled}
      pattern={REGEXP_ONLY_DIGITS}
      containerClassName="otp-input-container"
      maxLength={length}
      minLength={length}
      onChange={onChange}
      render={({ slots }) => (
        <div className="otp-inputs">
          {slots.map((slot, idx) => (
            <div key={idx} className="otp-input" data-focus={slot.isActive ? true : undefined}>
              {slot.char ?? slot.placeholderChar}
              {slot.hasFakeCaret && <FakeCaret />}
            </div>
          ))}
        </div>
      )}
    />
  );
});

export default OtpInput;
