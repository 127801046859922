import Strings from './lang';

export const getMethodTypeLabel = (type) => ({
  totp: Strings.totpTitleLabel,
  email: Strings.emailTitleLabel
}[type]);

export const getMethodTypeFullDescription = (type) => ({
  totp: Strings.totpFullDescription,
  email: Strings.emailFullDescription
}[type]);

export const getVerificationStatusLabel = (status) => ({
  verified: Strings.statusVerified,
  pending: Strings.statusPending,
  disabled: Strings.statusDisabled
}[status]);

export const METHOD_TYPE_ICON = {
  totp: 'qrcode',
  email: 'at'
};
