import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import CreatableSelect from 'react-select/creatable';
import classnames from 'classnames';
import CreatePartnerModal from './create-partner-modal';
import { getStyles } from '../../react-select';
import { useUniqueId } from '@/hooks';
import api from '@/lib/api-request';
import Strings from '../lang';

const createOption = (partner) => ({
  id: partner.id,
  label: partner.name,
  value: partner.slug
});

const PartnerSelector = ({
  value = null,
  className,
  touched: propsTouched = false,
  valid = true,
  onChange = () => { }
}) => {
  const id = useUniqueId();
  const [modalOpen, setModalOpen] = useState(false);
  const [touched, setTouched] = useState(propsTouched);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [, setError] = useState(null);
  const [creatableName, setCreatableName] = useState(null);
  const [options, setOptions] = useState(() => (value ? [createOption(value)] : []));

  const loadOptions = useCallback(() => {
    if (loaded) return;

    setLoading(true);

    api.v1.get('/marketplace/partners').then(({ data: partners }) => {
      setLoaded(true);
      setOptions(partners.map(createOption));
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [loaded]);

  const onCreateOption = useCallback((inputValue) => {
    setCreatableName(inputValue);
    setModalOpen(true);
  }, []);

  const addPartnerOption = useCallback((option) => {
    const newOption = { ...option, label: option.name, value: option.name.split(' ').join('-').toLowerCase() };
    setOptions(prev => ([...prev, newOption]));
    onChange(newOption);
  }, [onChange]);

  useEffect(() => {
    if (!touched && propsTouched !== touched) {
      setTouched(propsTouched);
    }
  }, [propsTouched, touched]);

  return (
    <>
      <div
        className={classnames(
          'form-input-group form-group multi-select',
          className,
          { invalid: touched && !valid }
        )}
      >
        <label htmlFor={id}>
          {Strings.partnershipSelectLabel}
        </label>
        <CreatableSelect
          id={id}
          isClearable
          closeMenuOnSelect
          value={value}
          allowCreateWhileLoading={false}
          options={options}
          isLoading={loading}
          placeholder={Strings.partnershipSelectPlaceholder}
          styles={getStyles()}
          onFocus={loadOptions}
          onBlur={() => setTouched(true)}
          onCreateOption={onCreateOption}
          onChange={onChange}
        />
      </div>
      <CreatePartnerModal
        isOpen={modalOpen}
        initialName={creatableName}
        onCreate={addPartnerOption}
        onClose={() => {
          setCreatableName(null);
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default PartnerSelector;
