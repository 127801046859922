import api from '@/lib/api-request';
import {
  addRegistrationSession,
  getCurrentRegistrationSession,
  removeAllRegistrationSessionData,
  setRegistrationSession
} from '@/utilities/registration-session';
import { updateGuardianAuth } from '../actions/guardians-account';
import { updateUser, updateUserChildren } from '../actions/users';
import { CONFIRMATION_URL } from '@/utilities/urls';
import log from '@/utilities/log';
import { sessionSignout } from '../actions/session';

const REGISTRATION_CLIENT = 'patient';
const defaultGetAuthToken = () => getCurrentRegistrationSession()?.token;

const defaultOptions = {
  client: REGISTRATION_CLIENT,
  getAuthToken: defaultGetAuthToken
};

const createRegistrationSessionAsync = (attributes = {}) => {
  return async (dispatch) => {
    const data = { type: 'sessions', attributes };

    return api.v1.post('/sessions', data, { client: REGISTRATION_CLIENT, getAuthToken: () => null }).then(({ data }) => {
      setRegistrationSession(data);

      const { auth_token, ...user } = data;

      if (user.children) {
        dispatch(updateGuardianAuth(user));
      }

      dispatch(updateUser(user));

      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const deleteRegistrationSessionAsync = (opts = {}) => {
  const options = { ...defaultOptions, ...opts };

  return dispatch => {
    return api.v1.delete('/sessions', options).catch(e => {
      log('Logout failed', e.message);
      return Promise.resolve();
    }).finally(() => {
      removeAllRegistrationSessionData();
      dispatch(sessionSignout());
    });
  };
};

const createRegistrationUserAsync = (attributes = {}, options = {}) => {
  return (dispatch) => {
    const data = { type: 'users', attributes: { ...attributes, confirmation_url: CONFIRMATION_URL } };

    return api.v1.post('/users', data, { client: REGISTRATION_CLIENT, getAuthToken: () => null, ...options }).then(({ data }) => {
      setRegistrationSession(data);

      if (attributes.role === 'guardian') {
        dispatch(updateGuardianAuth(data));
      }

      dispatch(updateUser(data));
      return Promise.resolve(data);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getRegistrationUserAsync = (id, options = {}) => {
  const opts = { ...defaultOptions, ...options };

  return dispatch => {
    return api.v1.get(`/users/${id}`, null, opts).then(({ data }) => {
      dispatch(updateUser(data));
      return Promise.resolve(data);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateRegistrationUserAsync = (id, attrs = {}) => {
  const data = { type: 'users', id, attributes: { ...attrs, confirmation_url: CONFIRMATION_URL } };

  return (dispatch) => {
    return api.v1.patch(`/users/${id}`, data, defaultOptions).then(({ data }) => {
      dispatch(updateUser(data));
      return Promise.resolve(data);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const decoupleChildAccountAsync = (id, attributes) => {
  const data = { type: 'users', id, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${id}/decouple`, data, defaultOptions).then(({ data: user }) => {
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const convertToGuardianAsync = (attributes = {}) => {
  const data = { type: 'users', attributes };

  return (dispatch) => {
    return api.v1.post('users/convert/guardian', data, defaultOptions).then(({ data: user }) => {
      setRegistrationSession(user);
      dispatch(updateGuardianAuth(user));
      dispatch(updateUser(user));
      return Promise.resolve(user);
    });
  };
};

const createAddChildAsync = (parentId, userAttributes = {}) => {
  const data = { type: 'users', attributes: { ...userAttributes, confirmation_url: CONFIRMATION_URL } };

  return (dispatch) => {
    return api.v1.post('users/add_child', data, defaultOptions).then(({ data: user }) => {
      addRegistrationSession(user, true);
      dispatch(updateUser(user));
      dispatch(updateUserChildren(parentId, user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const addExistingChildAsync = (parentId, attributes) => {
  const data = { type: 'users', attributes };

  return dispatch => {
    return api.v1.post('users/add_child/existing', data, defaultOptions).then(({ data: user }) => {
      addRegistrationSession(user, false);
      dispatch(updateUser(user));
      dispatch(updateUserChildren(parentId, user));
    });
  };
};

export {
  createRegistrationSessionAsync,
  deleteRegistrationSessionAsync,
  createRegistrationUserAsync,
  getRegistrationUserAsync,
  updateRegistrationUserAsync,
  decoupleChildAccountAsync,
  convertToGuardianAsync,
  createAddChildAsync,
  addExistingChildAsync
};
