import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import replaceLinkParams from '@/utilities/replace-link-params';
import { updateRegistrationRole } from '@/redux/actions/registration';
import { RegistrationSteps, findCurrentStepIndex } from './helpers';
import { RegistrationPaths } from '@/paths';
import { AlertBanner } from '../../alert-notifications';
import Activity from '../../activity';
import { 
  getRegistrationCurrentUser, 
  switchRegistrationAuthContext, 
  getRegistrationReturning,
  setRegistrationEmailSent,
  getRegistrationEmailSent,
  getCurrentRegistrationSession
} from '@/utilities/registration-session';
import Strings from './lang';
import { RegistrationProvider } from './context';
import { useMount } from '@/hooks';
import api from '@/lib/api-request';

const ConvertSuccessBanner = ({ onDismiss }) => {
  return (
    <div className="create-family-success-banner">
      <AlertBanner 
        type="success"
        options={{
          centered: true,
          autoDismiss: 0,
          message: Strings.accountConvertedSuccessBannerTitle
        }}
        onDismiss={onDismiss}
      />
    </div>
  );
};

const STEPS = RegistrationSteps.guardian;

const GuardianRegistrationRouteHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  const { step } = useSelector(state => state.registration || {});
  const guardian = useSelector(state => state.users[getRegistrationCurrentUser()] || {});
  const [activity, setActivity] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(() => !!location?.state?.convertFromPlayer);
  const stepIndex = useMemo(() => findCurrentStepIndex(STEPS, step), [step]);

  const sendRegistrationCompletion = useCallback((email) => {
    return api.v1.post(
      getRegistrationReturning() ? '/users/renewal' : '/users/completion',
      { type: 'users' },
      {
        client: 'patient',
        getAuthToken: () => getCurrentRegistrationSession()?.token,
        query: { email }
      }
    );
  }, []);

  const completeRegistration = useCallback((email) => {
    const emailSent = getRegistrationEmailSent();

    if (emailSent) {
      return Promise.resolve();
    }

    return sendRegistrationCompletion(email);
  }, [sendRegistrationCompletion]);

  const onRegistrationFinished = useCallback(() => {
    setActivity(true);

    completeRegistration(guardian.email).then(() => {
      setRegistrationEmailSent(true);
      setActivity(false);
      navigate(RegistrationPaths.finish.link);
    }).catch(() => {
      setActivity(false);
      navigate(RegistrationPaths.finish.link);
    });
  }, [completeRegistration, navigate, guardian.email]);

  const handleAboutNextStep = useCallback(() => {
    let path;

    if (guardian.children && guardian.children.length > 0) {
      path = RegistrationPaths.guardian.children.link;
    } else {
      path = replaceLinkParams(RegistrationPaths.guardian.child.index.link, {
        child: 'add'
      });
    }

    navigate(path);
  }, [guardian.children, navigate]);

  const onNext = useCallback(() => {
    const stepData = STEPS[stepIndex + 1];

    if (bannerVisible) {
      setBannerVisible(false);
    }

    if (step === 'about') {
      handleAboutNextStep();
    } else if (stepData) {
      navigate(stepData.path);
    } else {
      onRegistrationFinished();
    }
  }, [bannerVisible, handleAboutNextStep, navigate, onRegistrationFinished, step, stepIndex]);

  useMount(() => {
    dispatch(updateRegistrationRole('guardian'));
    switchRegistrationAuthContext(getRegistrationCurrentUser());
    setMounted(true);
  });

  if (!mounted) return null;

  return (
    <Activity active={activity}>
      <div className="row">
        {bannerVisible && (
          <ConvertSuccessBanner
            onDismiss={() => setBannerVisible(false)}
          />
        )}
        <div className="col-md-12">
          <RegistrationProvider
            next={onNext}
            role="guardian"
          >
            <Outlet />
          </RegistrationProvider>
        </div>
      </div>
    </Activity>
  );
};

export default GuardianRegistrationRouteHandler;
