import React, { useCallback, useMemo, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import Activity from '../activity';
import StripePaymentForm from './stripe-payment-form';
import withStripe from './stripe-provider';
import PaymentSuccess from './payment-success';
import PaymentFailed from './payment-failed';
import PaymentAmount from './payment-amount';
import { Money } from '@/utilities/currency';
import api from '@/lib/api-request';

const createPaymentIntent = async (clinicId, invoiceId, attributes) => {
  const { data } = await api.v1.post(
    `/clinics/${clinicId}/invoices/${invoiceId}/start_payment`,
    { type: 'invoices', attributes },
    { deserialize: false }
  );
  return data;
};

const StripePayment = ({
  balance,
  currency,
  clinicId,
  invoiceId,
  onPaymentSuccess = () => {}
}) => {
  const stripe = useStripe();
  const rawBalance = useMemo(() => Money.unformat(balance, currency), [balance, currency]);
  const [amount, setAmount] = useState(rawBalance);
  const [activity, setActivity] = useState(false);
  const [paymentResult, setPaymentResult] = useState(null);
  const [paymentError, setPaymentError] = useState(null);

  const handleSubmit = useCallback(paymentMethod => {
    setActivity(true);

    createPaymentIntent(clinicId, invoiceId, { amount }).then(intent => {
      return stripe.confirmCardPayment(intent.client_secret, {
        payment_method: paymentMethod
      }).then(response => {
        if (response.error) return Promise.reject(response.error);
        return Promise.resolve(response.paymentIntent);
      });
    }).then(result => {
      setActivity(false);
      setPaymentResult(result);
      onPaymentSuccess(result);
    }).catch(error => {
      setActivity(false);
      setPaymentError(error);
    });
  }, [amount, clinicId, invoiceId, onPaymentSuccess, stripe]);

  const resetPaymentResponse = useCallback(() => {
    setPaymentResult(null);
    setPaymentError(null);
  }, []);

  if (paymentError) {
    return (
      <PaymentFailed 
        error={paymentError}
        onRetry={resetPaymentResponse}
      />
    );
  }

  if (paymentResult) {
    return (
      <PaymentSuccess clinicId={clinicId} />
    );
  }

  return (
    <Activity active={activity || !stripe} className="clearfix">
      <PaymentAmount
        balance={rawBalance}
        amount={amount}
        currency={currency}
        onUpdateAmount={setAmount}
      />
      <StripePaymentForm 
        amount={amount}
        currency={currency}
        onSubmit={handleSubmit}
      />
    </Activity>
  );
};

export default withStripe(StripePayment);
