import React, {
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import OtpInput from '@/components/otp-input';
import Icon from '@/components/icon';
import { UserSelector } from '@/redux/selectors';
import { verifyMfaMethodAsync } from '@/redux/thunks/mfa-method';
import { ErrorBanner } from '@/components/errors';
import Strings from '../lang';

const CODE_LENGTH = 6;

export const AuthAppVerification = ({ provisioningUrl, onVerified }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const otpInputRef = useRef();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isValid = code?.length === CODE_LENGTH && /^\d+$/.test(code);
  const uriParams = useMemo(() => {
    return Object.fromEntries(new URL(provisioningUrl).searchParams.entries());
  }, [provisioningUrl]);

  const verifyCode = useCallback(() => {
    if (!isValid || loading) return;

    setLoading(true);
    setError(null);

    dispatch(verifyMfaMethodAsync(currentUser.id, { code })).then(method => {
      setLoading(false);
      onVerified(method);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
      setCode('');
      setTimeout(() => {
        otpInputRef.current.focus();
      // eslint-disable-next-line no-magic-numbers
      }, 200);
    });
  }, [code, currentUser.id, dispatch, isValid, loading, onVerified]);

  return (
    <div className="mfa-auth-app-verification">
      <div className="step-stack">
        <div className="step-title">1. {Strings.authAppRegistrationStep1Title}</div>
        <div dangerouslySetInnerHTML={{ __html: Strings.authAppRegistrationStep1Description }} />
        <div className="code-setup">
          <QRCodeSVG
            value={provisioningUrl}
            size={200}
          />
          <div>{Strings.separatorLabel}</div>
          <div>
            <div className="semi-bold">{Strings.secretKeyLabel}</div>
            <div>
              <code>{uriParams.secret}</code>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="step-stack">
        <div className="step-title">2. {Strings.authAppRegistrationStep2Title}</div>
        <div>
          {Strings.formatString(Strings.authAppRegistrationStep2Description, uriParams.issuer)}
        </div>
        <ErrorBanner error={error} />
        <div className="otp-verification-input">
          <OtpInput
            ref={otpInputRef}
            value={code}
            disabled={loading}
            length={CODE_LENGTH}
            onChange={setCode}
          />
        </div>
      </div>
      <div className="verify-btn">
        <button
          type="button"
          className="btn btn-primary"
          disabled={!isValid || loading}
          onClick={verifyCode}
        >
          {loading ? (
            <>
              <span>{Strings.verifyingLabel}</span>&nbsp;&nbsp;
              <Icon spinPulse prefix="fad" name="loader" />
            </>
          ) : (
            <>
              <Icon name="check-circle" />&nbsp;&nbsp;
              <span>{Strings.verifyLabel}</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};
