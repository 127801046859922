import { ApiRequest } from './api-request';

const createService = ({
  baseURL,
  service,
  version
}) => {
  const endpoint = [
    baseURL,
    service,
    version
  ].filter(Boolean).join('/');

  return new ApiRequest(endpoint);
};

export default createService;
