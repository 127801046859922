import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useDispatch } from 'react-redux';
import Activity from '../../activity';
import { ErrorBanner, ErrorStrings } from '../../errors';
import MobileAppAd from './mobile-app-ad';
import LanguageSelector from './language-selector';
import { AuthenticationForm } from '../../authentication';
import { createRegistrationSessionAsync } from '@/redux/thunks/registration';
import { RegistrationPaths } from '@/paths';
import { setRegistrationReturning, removeAllRegistrationSessionData } from '@/utilities/registration-session';
import { REGISTRATION_PASSWORD_RESET_URL } from '@/utilities/urls';
import { userHasRoleMatchingDescription, RoleDescriptions } from '@/utilities/user-roles';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import { setInterfaceLanguage, getInterfaceLanguage } from '@/utilities/localization';
import Strings from './lang';
import Icon from '../../icon';
import { useMount } from '@/hooks';

const updateLanguage = (value) => {
  setInterfaceLanguage(value);
  // eslint-disable-next-line no-self-assign
  window.location = window.location;
};

const RegistrationLanding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const language = useMemo(() => getInterfaceLanguage(), []);

  const nextReturning = useCallback((user) => {
    setRegistrationReturning(true);

    if (userHasRoleMatchingDescription(user, RoleDescriptions.Guardian)) {
      navigate(RegistrationPaths.guardian.about.link);
    } else if (userHasRoleMatchingDescription(user, RoleDescriptions.Player)) {
      navigate(RegistrationPaths.player.about.link);
    }
  }, [navigate]);

  const authenticate = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createRegistrationSessionAsync(attributes)).then((user) => {
      setActivity(false);
      nextReturning(user);
    }).catch(error => {
      let { message } = error;
      if (error.status === HTTPStatusCodes.Unauthorized) {
        message = ErrorStrings.error401Signin;
      }

      setActivity(false);
      setError(message);
    });
  }, [dispatch, nextReturning]);

  useMount(() => {
    removeAllRegistrationSessionData();
  });

  return (
    <div className="container-fluid registration-landing">
      <div className="language-selector-container">
        <LanguageSelector
          value={language}
          onSelect={updateLanguage}
        />
      </div>

      <div className="row">
        <div className="col-md-4 returning-section">
          <div className="authentication">
            <div className="brand-logo">
              <img src="/images/logo/color-full-animated.svg" alt="CCMI Logo" />
            </div>
            <h1 className="display dot text-center">
              {Strings.signInTitle}
            </h1>
            <ErrorBanner error={error} />
            <Activity active={activity}>
              <AuthenticationForm
                displayRememberMe={false}
                portal="player"
                onSubmit={authenticate}
                resetPasswordState={{
                  nextLocation: RegistrationPaths.index.link,
                  resetUrl: REGISTRATION_PASSWORD_RESET_URL
                }}
              />
            </Activity>
            <ScrollLink
              smooth
              className="create-account-btn"
              to="createAccount"
              offset={60}
              duration={500}
            >
              {Strings.createAccountButtonTitle}
            </ScrollLink>
          </div>
        </div>
        <div className="col-md-8 account-section">
          <div id="createAccount" className="registration-accounts">
            <h1 className="display dot bold">
              {Strings.createAccountTitle}
            </h1>
            <h3>{Strings.newPatientsInfoText}</h3>
            <div className="account-cards">
              <div className="row">
                <div className="col-md-6">
                  <div className="account-card">
                    <div className="account-card-header">
                      <h2>{Strings.personalAccountTitle}</h2>
                    </div>
                    <hr className="brand-separator" />
                    <div className="account-card-content">
                      <p className="text-muted">
                        {Strings.personalAccountDescription}
                      </p>
                    </div>
                    <div className="account-card-footer">
                      <Link
                        to={RegistrationPaths.player.index.link}
                        onClick={() => setRegistrationReturning(false)}
                        className="btn btn-secondary"
                      >
                        {Strings.getStartedLinkText}
                        <Icon name="arrow-right-long" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="account-card">
                    <div className="account-card-header">
                      <h2>{Strings.guardianAccountTitle}</h2>
                    </div>
                    <hr className="brand-separator" />
                    <div className="account-card-content">
                      <p className="text-muted">
                        {Strings.guardianAccountDescription}
                      </p>
                    </div>
                    <div className="account-card-footer">
                      <Link
                        to={RegistrationPaths.guardian.index.link}
                        onClick={() => setRegistrationReturning(false)}
                        className="btn btn-secondary"
                      >
                        {Strings.getStartedLinkText}
                        <Icon name="arrow-right-long" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileAppAd />
    </div>
  );
};

export default RegistrationLanding;
