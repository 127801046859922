import api from '@/lib/api-request';
import { updateOVSTest } from '../actions/ovs-tests';

const getOVSTestAsync = (userId, injuryId, ovsId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/ovs_tests/${ovsId}`).then(({ data: results }) => {
      dispatch(updateOVSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createOVSTestAsync = (userId, injuryId, attributes) => {
  const data = { type: 'orthostatic_vital_signs_tests', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/ovs_tests`, data).then(({ data: results }) => {
      dispatch(updateOVSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateOVSTestAsync = (userId, injuryId, ovsId, attributes) => {
  const data = { type: 'orthostatic_vital_signs_tests', id: ovsId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}/ovs_tests/${ovsId}`, data).then(({ data: results }) => {
      dispatch(updateOVSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getOVSTestAsync,
  createOVSTestAsync,
  updateOVSTestAsync
};
