import api from '@/lib/api-request';
import { updateClinicBalance } from '../actions/clinic-balance';

const getClinicBalanceAsync = (clinicId) => {
  return (dispatch) => {
    return api.v1.get(`/clinics/${clinicId}/balances`).then(({ data: results }) => {
      dispatch(updateClinicBalance(clinicId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicBalanceAsync
};
