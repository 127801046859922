import React from 'react';
import Icon from '@/components/icon';
import Strings from '../lang';

export const EmailRegistrationComplete = ({ onDone }) => {
  return (
    <div className="mfa-email-setup-complete">
      <Icon name="check-circle" />
      <h2 className="dot">Email OTP Enabled</h2>
      <div className="text-center">
        {Strings.emailRegistrationCompleteMessage}
      </div>
      <button type="button" className="btn btn-primary" onClick={onDone}>
        {Strings.doneText}
      </button>
    </div>
  );
};
