import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { If, Then, Else } from 'react-if';
import dayjs from 'dayjs';
import { getCurrentClinicId } from '@/tokens';
import { getClinicAsync } from '@/redux/thunks/clinics';
import { 
  getUserAsync, updateUserAsync, activateUserAsync, removeUserFromClinicAsync 
} from '@/redux/thunks/users';
import api from '@/lib/api-request';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Tabs from '../tabs';
import { confirmation } from '../confirmation';
import { PasswordUpdateForm, PasswordUpdateMessage } from '../passwords';
import Icon from '../icon';
import Activity from '../activity';
import AccessControl from '../access-control';
import BookingLinks from '../booking-links';
import UserHierarchyControl from '../user-hierarchy-control';
import ShowClinicianProfile from './show-clinician-profile';
import MfaProfileTabView from './mfa-profile-tab-view';
import { showAlert } from '../alert-notifications';
import QuickActions from '../quick-actions';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { convertToTrainingExpiryDate } from '@/utilities/user-training-expiry';
import { withRouter } from '@/utilities/router-utils';
import {
  RoleNames,
  RoleResourceTypes,
  RoleDescriptions,
  NoResourceTypeClinicUserRoles,
  userHasRoleRequiringTraining,
  makeRoleDescription,
  userHasRoleMatchingOneOfDescriptions,
  AdminRoleDescriptions,
  IDTRoleDescriptions
} from '@/utilities/user-roles';
import Strings from './lang';
import { ClinicSelector, UserSelector } from '@/redux/selectors';

const getTabId = (key) => {
  return ['profile', 'booking', 'password', '2fa'].indexOf(key) >= 0 ? key : 'profile';
};

const MAX_WARNING_DAYS = 60;

const makeTrainingExpiryColors = (expiry) => {
  const today = dayjs();
  if (today.isAfter(expiry)) {
    return 'red';
  } 
  
  if (today.add(MAX_WARNING_DAYS, 'days').isAfter(expiry)) {
    return 'yellow';
  } 
    
  return null;
};

const trainingExpiryViewerRoles = (clinicId, isOwnProfile = false) => {
  return makeUserProfileEditorRoles(clinicId, isOwnProfile);
};

const makeUserProfileEditorRoles = (clinicId, isOwnProfile = false) => {
  let roles = [
    RoleDescriptions.SuperAdmin,
    RoleDescriptions.SalesAdmin,
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId)
  ];

  if (isOwnProfile) {
    roles = roles.concat([...NoResourceTypeClinicUserRoles, ...IDTRoleDescriptions]);
  }

  return roles;
};

const defaultEditKeys = {
  editPersonal: false,
  editClinical: false,
  editContact: false,
  editClinics: false,
  editPhoto: false,
};

const getStateFromProps = (props) => {
  const {
    params = {},
    queryParams = {},
    user = {},
    currentUser = {},
    clinic = {}
  } = props;

  const { userId = currentUser?.id, clinicId = 0 } = params;

  return {
    userId,
    clinicId,
    user,
    clinic,
    selectedTabKey: getTabId(queryParams?.tab)
  };
};

const getMfaStatusColor = (enabled = false, status = 'disabled') => {
  if (enabled) {
    return status === 'pending' ? '#f6a700' : '#308f5d';
  }

  return '#606060';
};

const MfaTabLabel = ({ mfa }) => {
  const color = getMfaStatusColor(mfa.enabled, mfa.status);
  const icon = mfa.status === 'verified' ? 'shield-check' : 'shield';

  return (
    <span>
      <Icon prefix="fas" name={icon} color={color} />&nbsp;&nbsp;
      <span>{Strings.mfaTabTitle}</span>
    </span>
  );
};

class UserProfile extends Component {
  static getDerivedStateFromProps(props, state) {
    const nextTab = props.queryParams?.tab ?? 'profile';
    return nextTab && nextTab !== state.selectedTabKey
      ? { selectedTabKey: nextTab }
      : null;
  }

  constructor(props) {
    super(props);

    this.onSelectTab = this.onSelectTab.bind(this);
    this.onClickPhotoEditButton = this.onClickEditButton.bind(this, 'editPhoto');
    this.onClickPersonalEditButton = this.onClickEditButton.bind(this, 'editPersonal');
    this.onClickContactEditButton = this.onClickEditButton.bind(this, 'editContact');
    this.onClickClinicsEditButton = this.onClickEditButton.bind(this, 'editClinics');
    this.onClickClinicalEditButton = this.onClickEditButton.bind(this, 'editClinical');
    this.updateUser = this.updateUser.bind(this);
    this.onCompletePasswordUpdate = this.onCompletePasswordUpdate.bind(this);
    this.updatePassword = this.updatePassword.bind(this);

    this.state = {
      ...getStateFromProps(props),
      ...defaultEditKeys,
      userActivity: false,
      passwordUpdated: false,
      passwordActivity: false,
      error: null
    };
  }

  componentDidMount() {
    this.getUserAndClinic(this.state.userId, this.state.clinicId);
  }

  componentDidUpdate(prevProps) {
    const { params: prevParams = {} } = prevProps;
    const { params, currentUser = {} } = this.props;
    const { 
      userId: prevUserId = currentUser?.id, 
      clinicId: prevClinicId = 0 
    } = prevParams || {};
    const { userId = currentUser?.id, clinicId = 0 } = params || {};

    if (prevUserId !== userId || prevClinicId !== clinicId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...getStateFromProps(this.props) });
      this.getUserAndClinic(userId, clinicId);
    }
  }

  render() {
    const { currentUser = {} } = this.props;
    const { user, clinicId } = this.state;
    const person = user.person || {};
    const active = user.active || false;
    const isCurrentUser = user.id === currentUser.id;

    return (
      <Page className={classnames('user-profile', { 'inactive-profile': user.active === false })}>

        <If condition={user.active === false}>
          <Then>
            <div className="account-deactivated-banner">
              <div className="banner-content">
                <Icon name="circle-info" /> {Strings.accountDeactivatedBannerMessage}
              </div>
            </div>
          </Then>
        </If>

        <div className="row">
          <div className="col-md-8">
            <h1 className="display">
              {person.first_name} {person.last_name}
            </h1>
          </div>
        </div>

        <If condition={userHasRoleRequiringTraining(user)}>
          <Then>
            <AccessControl roles={trainingExpiryViewerRoles(clinicId, currentUser.id === user.id)}>
              <div className="row">
                <div className="col-md-12">
                  <label>{Strings.trainingExpiryLabelText}:</label>&nbsp;&nbsp;
                  {this.renderTrainingExpiry()}
                </div>
              </div>
            </AccessControl>  
          </Then>
        </If>

        <div className="row">
          <div className="col-md-8">
            <ErrorBanner error={this.state.error} />

            <Tabs 
              selectedKey={this.state.selectedTabKey}
              onSelectTab={this.onSelectTab}
              items={[
                {
                  key: 'profile',
                  label: Strings.profileTabLabel,
                  component: (
                    <Activity active={this.state.userActivity}>
                      <ShowClinicianProfile
                        editorRoles={makeUserProfileEditorRoles(
                          clinicId, 
                          isCurrentUser
                        )}
                        user={user}
                        editPhoto={this.state.editPhoto}
                        editPersonal={this.state.editPersonal}
                        editClinical={this.state.editClinical}
                        editContact={this.state.editContact}
                        editClinics={this.state.editClinics} 
                        onClickPhotoEditButton={this.onClickPhotoEditButton}
                        onClickPersonalEditButton={this.onClickPersonalEditButton}
                        onClickClinicalEditButton={this.onClickClinicalEditButton}
                        onClickContactEditButton={this.onClickContactEditButton}
                        onClickClinicsEditButton={this.onClickClinicsEditButton}
                        onSubmit={this.updateUser}
                      />
                    </Activity>
                  )
                },
                {
                  key: 'booking',
                  label: Strings.bookingTabLabel,
                  hide: !userHasRoleMatchingOneOfDescriptions(
                    this.state.user, 
                    [RoleDescriptions.Clinician, RoleDescriptions.Specialist]
                  ),
                  component: (
                    <BookingLinks 
                      userId={user.id} 
                      editable={(
                        isCurrentUser 
                        || userHasRoleMatchingOneOfDescriptions(currentUser, AdminRoleDescriptions)
                      )}
                    />
                  )
                },
                {
                  key: 'password',
                  hide: !isCurrentUser,
                  label: Strings.updatePasswordTabLabel,
                  component: this.renderPasswordUpdate()
                },
                {
                  key: '2fa',
                  hide: !isCurrentUser,
                  label: (
                    <MfaTabLabel mfa={currentUser.mfa} />
                  ),
                  component: (
                    <MfaProfileTabView />
                  )
                }
              ]}
            />

          </div>

          <div className="col-md-4">
            {/* Right column */}
            <QuickActions>

              <If condition={!isCurrentUser}>
                <Then>
                  <AccessControl 
                    roles={[
                      RoleDescriptions.SuperAdmin,
                      RoleDescriptions.SalesAdmin
                    ]}
                  >
                    <li>
                      <button
                        type="button"
                        className="btn btn-default" 
                        onClick={this.confirmActivateUser.bind(this, !active)}
                      >
                        {(active) 
                          ? Strings.deactivateUserButtonText 
                          : Strings.activateUserButtonText}
                      </button>
                    </li>
                  </AccessControl>
                </Then>
              </If>

              <UserHierarchyControl
                user={user}
                resourceType={RoleResourceTypes.Clinic}
                resourceId={clinicId}
                exceptWhen={
                  (userHasRoleMatchingOneOfDescriptions(user, [
                    RoleDescriptions.Leader,
                    RoleDescriptions.Player
                  ]) 
                  && !userHasRoleMatchingOneOfDescriptions(currentUser, [
                    RoleDescriptions.SuperAdmin,
                    RoleDescriptions.SalesAdmin
                  ])) 
                  || !this.userBelongsToClinic()
                }
              >
                <li>
                  <button 
                    type="button"
                    className="btn btn-default" 
                    onClick={this.confirmRemoveUser.bind(this)}
                  >
                    {Strings.removeUserButtonText}
                  </button>
                </li>
              </UserHierarchyControl>
            </QuickActions>
          </div> {/* end col */}
        </div> {/* end row */}
      </Page>
    );
  }

  renderTrainingExpiry() {
    const { user } = this.state;
    const trainedAt = dayjs(user.trained_at);

    if (trainedAt.isValid()) {
      const expiry = convertToTrainingExpiryDate(trainedAt);
      const color = makeTrainingExpiryColors(expiry);
      const extraText = color === 'red' || color === 'yellow' ? ` (${Strings.formatString(
        (color === 'red' ? Strings.trainingExpiredText : Strings.trainingExpiringText),
        expiry.fromNow()
      )})` : '';

      return <span className={color}>{expiry.format(DISPLAY_DATE_FORMAT)}{extraText}</span>;
    } 

    return <span className="red"><i>Training Incomplete</i></span>;
  }

  renderPasswordUpdate() {
    return (
      <Activity active={this.state.passwordActivity}>
        <If condition={this.state.passwordUpdated}>
          <Then>
            <PasswordUpdateMessage onComplete={this.onCompletePasswordUpdate} />
          </Then>
          <Else>
            <PasswordUpdateForm onSubmit={this.updatePassword} />
          </Else>
        </If>
      </Activity>
    );
  }

  onClickEditButton(key) {
    this.setState(({ [key]: editValue }) => ({
      [key]: !editValue
    }));
  }

  onSelectTab(selectedTabKey) {
    const { router } = this.props;

    router.setQueryParams({
      tab: getTabId(selectedTabKey)
    });
  }

  onCompletePasswordUpdate() {
    this.setState({
      passwordUpdated: false,
      selectedTabKey: 'profile'
    });
  }

  getUserAndClinic(userId, clinicId) {
    this.setState({
      userActivity: true,
      error: null
    });

    const requests = this.getRequests(userId, clinicId);

    Promise.all(requests).then(([user = {}, clinic = {}]) => {
      this.setState({
        userActivity: false,
        user,
        clinic
      });
    }).catch(error => {
      this.setState({
        userActivity: false,
        error: error.message
      });
    });
  }

  getRequests(userId, clinicId) {
    const requests = [
      this.props.getUser(userId)
    ];

    if (clinicId > 0) {
      requests.push(this.props.getClinic(clinicId));
    } else {
      requests.push(Promise.resolve({}));
    }

    return requests;
  }

  updateUser(attributes) {
    this.setState({
      userActivity: true,
      error: null
    });

    this.props.updateUser(this.state.userId, attributes).then(user => {
      this.setState({
        userActivity: false,
        user,
        ...defaultEditKeys
      });
    }).catch(error => {
      this.setState({
        userActivity: false,
        error: error.message
      });
    });
  }

  updatePassword(attributes) {
    this.setState({
      passwordActivity: true,
      error: null
    });

    const data = { type: 'passwords', attributes };

    api.v1.patch('/passwords', data).then(() => {
      this.setState({ passwordActivity: false, passwordUpdated: true });
    }).catch(error => {
      this.setState({
        passwordActivity: false,
        error: error.message
      });
    });
  }

  activateUser(active) {
    this.setState({
      userActivity: true,
      error: null
    });

    this.props.activateUser(this.state.userId, { active }).then(user => {
      this.setState({
        userActivity: false,
        user
      });
    }).catch(error => {
      this.setState({
        userActivity: false,
        error: error.message
      });
    });
  }

  confirmActivateUser(active) {
    const { user } = this.state;
    const { person = {} } = user;

    const message = Strings.formatString(
      (active)
        ? Strings.activateUserModalMessage
        : Strings.deactivateUserModalMessage,
      `${person.first_name} ${person.last_name}`
    );

    const title = active
      ? Strings.activateUserModalTitle
      : Strings.deactivateUserModalTitle;

    const confirmButtonTitle = active
      ? Strings.activateUserModalConfirmButtonTitle
      : Strings.deactivateUserModalConfirmButtonTitle;

    confirmation(message, {
      title,
      confirmButtonTitle,
      onConfirm: () => {
        this.activateUser(active);
      }
    });
  }

  confirmRemoveUser() {
    const { user } = this.state;
    const { person = {} } = user;

    const message = Strings.formatString(
      Strings.confirmRemoveUserMessage,
      `${person.first_name} ${person.last_name}`
    );

    confirmation(message, {
      title: Strings.removeUserModalTitle,
      confirmButtonTitle: Strings.removeUserButtonText,
      onConfirm: () => {
        this.removeUserFromClinic();
      }
    });
  }

  removeUserFromClinic() {
    this.setState({
      userActivity: true,
      error: null
    });

    const { user, clinicId } = this.state;
    const attributes = {
      user_id: user.id
    };

    this.props.removeUserFromClinic(clinicId, attributes).then(user => {
      this.setState({
        user, 
        userActivity: false
      });

      const { clinic } = this.state;
      const { person = {} } = user;

      showAlert('success', {
        autoDismiss: 4000,
        dismissable: true,
        message: Strings.formatString(
          Strings.removeUserSuccessMessage,
          `${person.first_name} ${person.last_name}`,
          clinic.name
        )
      });
    }).catch(error => {
      this.setState({
        userActivity: false,
        error: error.message
      });
    });
  }

  userBelongsToClinic() {
    const { user, clinicId } = this.state;
    const userClinics = (user.clinics || []).filter(Boolean);
    return userClinics.find(clinic => clinic.id === clinicId) !== undefined;
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentUser = UserSelector.getCurrentUser(state) || {};
  const user = UserSelector.getUser(
    state,
    { userId: ownProps.params?.userId ?? currentUser?.id }
  );
  const clinic = ClinicSelector.getClinic(state, { id: ownProps.params.clinicId });
  
  return {
    user,
    clinic,
    currentUser,
    currentClinicId: getCurrentClinicId()
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: id => {
      return dispatch(getUserAsync(id));
    },
    updateUser: (id, attributes) => {
      return dispatch(updateUserAsync(id, attributes));
    },
    activateUser: (id, attributes) => {
      return dispatch(activateUserAsync(id, attributes));
    },
    removeUserFromClinic: (clinicId, attributes) => {
      return dispatch(removeUserFromClinicAsync(clinicId, attributes));
    },
    getClinic: id => {
      return dispatch(getClinicAsync(id));
    }
  };
};

const ConnectedUserProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);

const RoutableUserProfile = withRouter(ConnectedUserProfile);

export default RoutableUserProfile;
