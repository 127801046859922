import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { updateBlackhawksTest } from '../actions/blackhawks';

const getBlackhawksTestAsync = (userId, injuryId, blackhawksId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/blackhawks_tests/${blackhawksId}`).then(({ data: results }) => {
      dispatch(updateBlackhawksTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createBlackhawksTestAsync = (userId, injuryId, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'blackhawks_tests', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/blackhawks_tests`, data, { query }).then(({ data: results }) => {
      dispatch(updateBlackhawksTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateBlackhawksTestAsync = (userId, injuryId, blackhawksId, attributes) => {
  const data = { type: 'blackhawks_tests', id: blackhawksId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}/blackhawks_tests/${blackhawksId}`, data).then(({ data: results }) => {
      dispatch(updateBlackhawksTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getBlackhawksTestAsync,
  createBlackhawksTestAsync,
  updateBlackhawksTestAsync
};
