import api from '@/lib/api-request';
import { updateInjuryReports, updateInjuryReport } from '../actions/injury-reports';

const getInjuryReportsAsync = userId => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injury_reports`).then(({ data: results }) => {
      dispatch(updateInjuryReports(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuryReportAsync = (userId, reportId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injury_reports/${reportId}`).then(({ data: results }) => {
      dispatch(updateInjuryReport(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateInjuryReportAsync = (userId, reportId, attributes) => {
  const data = { type: 'injury_reports', id: reportId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injury_reports/${reportId}`, data).then(({ data: results }) => {
      dispatch(updateInjuryReport(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getInjuryReportsAsync,
  getInjuryReportAsync,
  updateInjuryReportAsync
};
