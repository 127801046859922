import api from '@/lib/api-request';
import { updateDanaBattery } from '../actions/dana-batteries';

const getDanaBattery = (userId, batteryId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/dana_batteries/${batteryId}`).then(({ data: battery }) => {
      dispatch(updateDanaBattery(userId, battery));
      return Promise.resolve(battery);
    });
  };
};

export {
  getDanaBattery
};
