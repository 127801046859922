import api from '@/lib/api-request';
import { updateClinic, updateClinics } from '../actions/clinics';

const getClinicAsync = (id) => {
  return (dispatch) => {
    return api.v1.get(`/clinics/${id}`).then(({ data: clinic }) => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createClinicAsync = (attributes = {}) => {
  const data = { type: 'clinics', attributes };

  return (dispatch) => {
    return api.v1.post('/clinics', data).then(({ data: clinic }) => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateClinicAsync = (id, attributes = {}) => {
  const data = { type: 'clinics', id, attributes };

  return (dispatch) => {
    return api.v1.patch(`/clinics/${id}`, data).then(({ data: clinic }) => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const activateClinicAsync = (id, attributes = {}) => {
  const data = { type: 'clinics', id, attributes };

  return (dispatch) => {
    return api.v1.patch(`/clinics/${id}/activate`, data).then(({ data: clinic }) => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const activateMapleAsync = (id, attributes) => {
  const data = { type: 'clinic', id, attributes };

  return (dispatch) => {
    return api.v1.patch(`/clinics/${id}/activate_maple`, data).then(({ data: clinic }) => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    });
  };
};

const getClinicsAsync = (filters = {}) => {
  const query = { page: 1, active: true, ...filters };

  return (dispatch) => {
    return api.v1.get('/clinics', query).then(({ data, meta }) => {
      dispatch(updateClinics(data));
      return Promise.resolve({ 
        clinics: data, 
        pagination: meta 
      });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicAsync,
  createClinicAsync,
  updateClinicAsync,
  activateClinicAsync,
  activateMapleAsync,
  getClinicsAsync
};
