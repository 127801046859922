import api from '@/lib/api-request';
import { updateUser, updateCurrentUserId } from '../actions/users';
import { updateGuardianAuth } from '../actions/guardians-account';
import { CONFIRMATION_URL } from '@/utilities/urls';

const getCurrentUserAsync = (options = {}) => {
  return (dispatch) => {
    return api.v1.get('/account', null, options).then(({ data }) => {
      dispatch(updateUser(data));
      dispatch(updateCurrentUserId(data.id));
      return Promise.resolve(data);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getUserAsync = (id, options = {}) => {
  return (dispatch) => {
    return api.v1.get(`/users/${id}`, null, options).then(({ data }) => {
      dispatch(updateUser(data));
      return Promise.resolve(data);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateUserAsync = (id, userAttributes = {}, options = {}) => {
  // Inject the confirmation url into all user update calls:
  const data = { type: 'users', id, attributes: { ...userAttributes, confirmation_url: CONFIRMATION_URL } };

  return (dispatch) => {
    return api.v1.patch(`/users/${id}`, data, options).then(({ data: user }) => {
      if (userAttributes.role === 'guardian') {
        dispatch(updateGuardianAuth(user));
      }
  
      dispatch(updateUser(user));
  
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const activateUserAsync = (id, attributes = {}) => {
  const data = { type: 'users', id, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${id}/activate`, data).then(({ data: user }) => {
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const removeUserFromClinicAsync = (clinicId, attributes) => {
  const data = { type: 'clinics', attributes };

  return (dispatch) => {
    return api.v1.post(`clinics/${clinicId}/remove_user`, data).then(({ data: user }) => {
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getUserAsync,
  getCurrentUserAsync,
  updateUserAsync,
  activateUserAsync,
  removeUserFromClinicAsync
};
