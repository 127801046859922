import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormComponent, FormInputGroup, CheckboxInputGroup } from '@/forms';
import OtpInput from '../otp-input';
import { verifySessionChallengeAsync } from '@/redux/thunks/sessions';
import { ErrorBanner } from '../errors';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import { showAlert } from '../alert-notifications';
import Icon from '../icon';
import Strings from './lang';

const getOtpMethodTypeMessage = (type) => ({
  totp: Strings.totpChallengeMessage,
  email: Strings.emailChallengeMessage
}[type]);

const MfaCodeInput = ({ type, code, onChange }) => {
  if (type === 'email') {
    return (
      <div className="otp-input-container">
        <OtpInput
          value={code}
          length={6}
          onChange={onChange}
        />
      </div>
    );
  }

  return (
    <FormInputGroup
      required
      className="form-group"
      labelText={Strings.mfaCodeInputLabel}
      inputType="text"
      inputProps={{
        className: 'form-control',
        value: code,
        onChange: (e) => onChange(e.target.value)
      }}
      messageText={Strings.invalidMfaCodeFormat}
      messageClassName="alert alert-danger"
      inputValid
      touched={false}
    />
  );
};

const CODE_LENGTH = 6;
const MAX_CODE_LENGTH = 20;

const isCodeValid = (type, code) => {
  if (type === 'email') {
    return code.length === CODE_LENGTH;
  }

  const isCode = /^\d+$/.test(code);

  return (isCode && code.length === CODE_LENGTH)
    || (!isCode && code.length > 0 && code.length <= MAX_CODE_LENGTH);
};

const MfaChallenge = ({
  challenge,
  onVerified,
  onReset
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState('');
  const [trust, setTrust] = useState(false);
  const isValid = useMemo(() => isCodeValid(challenge.type, code), [code, challenge.type]);

  const verifyChallenge = useCallback(() => {
    if (loading) return;

    setLoading(true);
    setError(null);

    dispatch(verifySessionChallengeAsync(challenge.token, { code, trust })).then(user => {
      setLoading(false);
      onVerified(user);
    }).catch(error => {
      setLoading(false);
      setCode('');

      if (error.status === HTTPStatusCodes.UnprocessableEntity) {
        setError(error.message);
      } else {
        showAlert('error', {
          dismissable: true,
          autoDismiss: 6000,
          message: error.message
        });
        onReset();
      }
    });
  }, [challenge.token, code, dispatch, loading, onReset, onVerified, trust]);

  const handleSubmit = useCallback(() => {
    if (!isValid) return;

    verifyChallenge();
  }, [isValid, verifyChallenge]);

  return (
    <div className="mfa-verification-form">
      <div
        className="text-muted text-center"
        dangerouslySetInnerHTML={{ __html: getOtpMethodTypeMessage(challenge.type) }}  
      />

      <ErrorBanner error={error} />

      <FormComponent onSubmit={handleSubmit}>
        <MfaCodeInput
          type={challenge.type}
          code={code}
          onChange={setCode}
        />
        <CheckboxInputGroup
          inputValid
          className="form-group"
          labelText={Strings.trustDeviceLabel}
          inputProps={{
            className: 'form-control',
            checked: trust,
            onChange: e => {
              setTrust(e.target.checked);
            }
          }}
        />
        <div className="form-footer">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!isValid}
          >
            {loading ? (
              <>
                <span>{Strings.verifyingLabel}</span>&nbsp;&nbsp;
                <Icon spinPulse prefix="fad" name="loader" />
              </>
            ) : (
              <span>{Strings.verifyLabel}</span>
            )}
          </button>
        </div>
      </FormComponent>
    </div>
  );
};

export default MfaChallenge;
