import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { updateVOMSTest } from '../actions/voms';

const getVOMSTestAsync = (userId, injuryId, vomsId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/voms_tests/${vomsId}`).then(({ data: results }) => {
      dispatch(updateVOMSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createVOMSTestAsync = (userId, injuryId, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'voms_tests', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injuries/${injuryId}/voms_tests`, data, { query }).then(({ data: results }) => {
      dispatch(updateVOMSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateVOMSTestAsync = (userId, injuryId, vomsId, attributes) => {
  const data = { type: 'voms_tests', id: vomsId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}/voms_tests/${vomsId}`, data).then(({ data: results }) => {
      dispatch(updateVOMSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getVOMSTestAsync,
  createVOMSTestAsync,
  updateVOMSTestAsync
};
