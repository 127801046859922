import api from '@/lib/api-request';
import {
  updateClinicInvoices, 
  updateClinicInvoice, 
  deleteInvoiceLineItem, 
  addInvoiceLineItem,
  updateInvoiceLineItem
} from '../actions/clinic-invoices';
import { updateClinic } from '../actions/clinics';

const getInvoicesAsync = (clinicId, status) => {
  const query = { status };

  return (dispatch) => {
    return api.v1.get(`/clinics/${clinicId}/invoices`, query).then(({ data: results }) => {
      dispatch(updateClinicInvoices(clinicId, results));
      return Promise.resolve(results);
    });
  };
};

const getInvoiceAsync = (clinicId, invoiceId) => {
  return (dispatch) => {
    return api.v1.get(`/clinics/${clinicId}/invoices/${invoiceId}`).then(({ data: invoice }) => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createInvoiceAsync = (clinicId, attributes) => {
  const data = { type: 'invoices', attributes };

  return dispatch => {
    return api.v1.post(`/clinics/${clinicId}/invoices`, data).then(({ data: invoice }) => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const updateInvoiceAsync = (clinicId, invoiceId, attributes) => {
  const data = { type: 'invoices', id: invoiceId, attributes };

  return dispatch => {
    return api.v1.patch(`/clinics/${clinicId}/invoices/${invoiceId}`, data).then(({ data: invoice }) => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createBulkCreditsAsync = (clinicId, attrs) => {
  const { invoiceId, ...rest } = attrs;
  const data = { type: 'invoices', attributes: { type: 'Token', ...rest } };
  const path = invoiceId
    ? `/clinics/${clinicId}/invoices/${invoiceId}/bulk_purchase_tokens`
    : `/clinics/${clinicId}/bulk_purchase_tokens`;
  
  return (dispatch, getState) => {
    return api.v1.post(path, data).then(({ data: invoice }) => {
      const { clinics } = getState();
      const clinic = clinics[clinicId];
      
      if (clinic) {
        const clinicUpdate = {
          ...clinic,
          credits_available: (clinic.credits_available || 0) + attrs.quantity
        };

        dispatch(updateClinic(clinicUpdate));
      }

      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createBulkFreeCreditsAsync = (clinicId, attributes) => {
  const data = { type: 'clinics', attributes };

  return (dispatch) => {
    return api.v1.post(`/clinics/${clinicId}/bulk_free_tokens`, data).then(({ data: invoice }) => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createBulkImpactBaselinesAsync = (clinicId, attributes) => {
  const data = { type: 'invoices', attributes };

  return (dispatch) => {
    return api.v1.post(`/clinics/${clinicId}/bulk_impact_baselines`, data).then(({ data: invoice }) => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createPaymentAdjustmentAsync = (clinicId, invoiceId, attributes) => {
  const data = { type: 'clinics', attributes };

  return (dispatch) => {
    return api.v1.post(`/clinics/${clinicId}/invoices/${invoiceId}/adjustment`, data).then(({ data: invoice }) => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      
      return Promise.resolve(invoice);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createInvoiceLineItemAsync = (clinicId, invoiceId, attributes) => {
  const data = { type: 'line_items', attributes };

  return dispatch => {
    return api.v1.post(`/clinics/${clinicId}/invoices/${invoiceId}/line_items`, data).then(({ data: lineItem }) => {
      dispatch(addInvoiceLineItem(clinicId, invoiceId, lineItem));
      return Promise.resolve(lineItem);
    });
  };
};

const updateInvoiceLineItemAsync = (clinicId, invoiceId, lineItemId, attributes) => {
  const data = { type: 'line_items', attributes, id: lineItemId };

  return dispatch => {
    return api.v1.patch(`/clinics/${clinicId}/invoices/${invoiceId}/line_items/${lineItemId}`, data).then(({ data: lineItem }) => {
      dispatch(updateInvoiceLineItem(clinicId, invoiceId, lineItem));
      return Promise.resolve(lineItem);
    });
  };
};

const deleteInvoiceLineItemAsync = (clinicId, invoiceId, lineItem) => {
  return dispatch => {
    return api.v1.delete(`/clinics/${clinicId}/invoices/${invoiceId}/line_items/${lineItem.id}`).then(() => {
      dispatch(deleteInvoiceLineItem(clinicId, invoiceId, lineItem));
      return Promise.resolve();
    });
  };
};

export {
  getInvoicesAsync,
  getInvoiceAsync,
  createInvoiceAsync,
  updateInvoiceAsync,
  createBulkCreditsAsync,
  createBulkFreeCreditsAsync,
  createBulkImpactBaselinesAsync,
  createPaymentAdjustmentAsync,
  createInvoiceLineItemAsync,
  updateInvoiceLineItemAsync,
  deleteInvoiceLineItemAsync
};
