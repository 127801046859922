import api from '@/lib/api-request';
import { addClinicIdtClaims } from '../actions/clinic-idt-claims';

const getClinicIdtClaimsAsync = (clinicId, status) => {
  return (dispatch) => {
    return api.v1.get(`/clinics/${clinicId}/idt_claims`, { status }).then(({ data: results }) => {
      dispatch(addClinicIdtClaims(clinicId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicIdtClaimsAsync
};
