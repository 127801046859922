import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@/components/modal';
import { FormComponent, PasswordInputGroup } from '@/forms';
import { ErrorBanner } from '@/components/errors';
import { disableMfaMethodAsync } from '@/redux/thunks/mfa-method';
import Icon from '@/components/icon';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import Strings from '../lang';

const RemoveLabel = ({ loading = false }) => {
  return loading ? (
    <>
      <span>{Strings.removingLabel}</span> & nbsp;& nbsp;
      <Icon spinPulse prefix="fad" name="loader" />
    </>
  ) : (
    <span>{Strings.removeLabel}</span>
  );
};

const RemoveVerifiedMfa = ({ loading = false, onSubmit, onCancel }) => {
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(() => {
    if (!valid || loading) return;

    setSubmitted(true);
    setPassword('');

    onSubmit({ password });
  }, [loading, onSubmit, password, valid]);

  return (
    <div>
      <p>{Strings.removeVerifiedMfaMethodMessage}</p>
      <FormComponent onSubmit={handleSubmit}>
        <PasswordInputGroup
          className="form-group"
          labelText={Strings.passwordLabel}
          inputProps={{
            className: 'form-control',
            placeholder: 'e.g. ********',
            value: password,
            disabled: loading
          }}
          onUpdate={(password, passwordValid) => {
            setPassword(password);
            setValid(passwordValid);
          }}
          messageClassName="alert alert-danger"
          touched={submitted}
        />
        <div className="actions-footer">
          <button type="button" className="btn btn-default" disabled={loading} onClick={onCancel}>
            {Strings.cancelButtonText}
          </button>
          <button type="submit" disabled={!valid || loading} className="btn btn-danger">
            <RemoveLabel loading={loading} />
          </button>
        </div>
      </FormComponent>
    </div>
  );
};

const ConfirmRemoveMfa = ({ loading, onConfirm, onCancel }) => {
  return (
    <div>
      <p>{Strings.removeMfaMethodConfirmationMessage}</p>
      <div className="actions-footer">
        <button type="button" className="btn btn-default" disabled={loading} onClick={onCancel}>
          {Strings.cancelButtonText}
        </button>
        <button type="button" disabled={loading} className="btn btn-danger" onClick={() => onConfirm()}>
          <RemoveLabel loading={loading} />
        </button>
      </div>
    </div>
  );
};

const RemoveMfaModalBody = ({ userId, method, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const removeMfa = useCallback((attributes = {}) => {
    if (loading) return;

    setLoading(true);
    setError(null);

    dispatch(disableMfaMethodAsync(userId, attributes)).then(() => {
      setLoading(false);
      onClose();
    }).catch(error => {
      setError(
        error.status === HTTPStatusCodes.Unauthorized
          ? Strings.invalidPasswordError
          : error.message
      );
      setLoading(false);
    });
  }, [dispatch, loading, onClose, userId]);

  if (!method) {
    return null;
  }

  return (
    <div>
      <ErrorBanner error={error} />
      {method.verified_at ? (
        <RemoveVerifiedMfa
          loading={loading}
          onSubmit={removeMfa}
          onCancel={onClose}
        />
      ) : (
        <ConfirmRemoveMfa
          loading={loading}
          onConfirm={removeMfa}
          onCancel={onClose}
        />
      )}
    </div>
  );
};

export const RemoveMfaModal = ({
  userId,
  isOpen,
  onClose,
  method
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className="remove-mfa-modal"
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.removeMfaMethodTitle}</h2>
      </Modal.Header>
      <Modal.Body>
        <RemoveMfaModalBody
          method={method}
          userId={userId}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
