import api from '@/lib/api-request';
import { updateProduct, updateProducts, removeProduct } from '../actions/products';

const getProductAsync = (productId) => {
  return (dispatch) => {
    return api.v1.get(`/marketplace/products/${productId}`).then(({ data: product }) => {
      dispatch(updateProduct(product));
      return Promise.resolve(product);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getProductsAsync = (query = {}) => {
  return (dispatch) => {
    return api.v1.get('/marketplace/products', query).then(({ data, meta }) => {
      dispatch(updateProducts(data));
      return Promise.resolve({
        products: data,
        pagination: meta
      });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateProductAsync = (productId, attributes) => {
  const data = { type: 'products', id: productId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/marketplace/products/${productId}`, data).then(({ data: product }) => {
      dispatch(updateProduct(product));
      return Promise.resolve(product);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createProductAsync = (attributes) => {
  const data = { type: 'products', attributes };

  return (dispatch) => {
    return api.v1.post('/marketplace/products', data).then(({ data: product }) => {
      dispatch(updateProduct(product));
      return Promise.resolve(product);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const deleteProductAsync = (productId) => {
  return (dispatch) => {
    return api.v1.delete(`/marketplace/products/${productId}`).then(() => {
      dispatch(removeProduct(productId));
      return Promise.resolve();
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  updateProductAsync,
  getProductAsync,
  getProductsAsync,
  createProductAsync,
  deleteProductAsync
};
