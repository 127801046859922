import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem
} from './local-storage';

const CURRENT_USER_EMAIL_KEY = 'com.ccmi.users.current.email';
const REMEBERED_USER_LOGINS = 'com.ccmi.users.remembered';

const createRememberedUser = (user) => {
  const { person = {} } = user || {};

  if (!person) {
    return null;
  }

  return {
    photo_url: person.photo_url,
    email: user.email,
    first_name: person.first_name || '',
    last_name: person.last_name || ''
  };
};

const getRememberedUsers = () => JSON.parse(getLocalStorageItem(REMEBERED_USER_LOGINS) || '[]');
const findRememberedUserIndex = (users, email) => (
  users.findIndex(user => (user || {}).email === email)
);

const setRememberedUser = (user) => {
  const remembered = getRememberedUsers();
  const index = findRememberedUserIndex(remembered, user.email);
  if (index < 0) {
    const rememberedUser = createRememberedUser(user);
    if (rememberedUser) {
      return setLocalStorageItem(
        REMEBERED_USER_LOGINS,
        JSON.stringify([
          rememberedUser,
          ...remembered
        ])
      );
    }

    return false;
  }

  return true;
};

const removeRememberedUser = (user) => {
  const remembered = getRememberedUsers() || [];

  const index = findRememberedUserIndex(remembered, user.email);
  if (index >= 0) {
    remembered.splice(index, 1);
    return setLocalStorageItem(REMEBERED_USER_LOGINS, JSON.stringify(remembered));
  }

  return true;
};

const getCurrentUserEmail = () => getLocalStorageItem(CURRENT_USER_EMAIL_KEY);
const setCurrentUserEmail = (email) => setLocalStorageItem(CURRENT_USER_EMAIL_KEY, email);
const removeCurrentUserEmail = () => removeLocalStorageItem(CURRENT_USER_EMAIL_KEY);

export {
  getCurrentUserEmail,
  setCurrentUserEmail,
  removeCurrentUserEmail,
  getRememberedUsers,
  setRememberedUser,
  removeRememberedUser
};
