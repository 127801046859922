import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { connect } from 'react-redux';
import Modal from '../modal';
import Icon from '../icon';
import { createBulkCreditsAsync } from '@/redux/thunks/clinic-invoices';
import Strings from './lang';

const ConfirmationView = ({
  totalCost,
  currency,
  quantity,
  onConfirm,
  onCancel
}) => {
  return (
    <div className="confirmation-view">
      <div className="confirmation-view-content">
        <h1 className="display primary">
          {Strings.formatString(
            Strings.purchaseCreditsTitle,
            quantity
          )}
        </h1>
        <div>
          <strong>
            {Strings.bulkPurchaseModalAgreementText}
          </strong>
        </div>
      </div>
      <div className="balance-footer">
        <div className="text-right">
          <strong>
            {Strings.formatString(Strings.balanceAddedLabel, totalCost, currency)}
          </strong>
          <div><small><i>({Strings.applicableTaxesText})</i></small></div>
        </div>
      </div>
      <div className="confirmation-view-footer">
        <button
          type="button"
          className="btn btn-default"
          onClick={onCancel}
        >
          {Strings.cancelButtonText}
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={onConfirm}
        >
          {Strings.addToInvoiceButtonText}
        </button>
      </div>
    </div>
  );
};

const LoadingView = () => (
  <div className="loading-view">
    <h1>
      <Icon prefix="fad" name="loader" spinPulse className="primary" />&nbsp;&nbsp;
      {Strings.addingCreditsLoadingTitle}
    </h1>
    <div>{Strings.addingCreditsLoadingMessage}</div>
  </div>
);

const StatusView = ({
  title,
  statusModifier,
  message,
  icon,
  onClose
}) => (
  <div className="status-view">
    <div className="status-view-content">
      <div>
        <Icon name={icon} size="3x" className={statusModifier} />
      </div>
      <h1 className={statusModifier}>{title}</h1>
      <div>{message}</div>
    </div>
    <div className="status-view-footer">
      <button
        type="button"
        className="btn btn-default"
        onClick={onClose}
      >
        {Strings.closeButtonText}
      </button>
    </div>
  </div>
);

const ErrorMessage = ({ error }) => (
  <>
    <p>
      <strong>
        {Strings.formatString(Strings.creditsAddedErrorMessage, error)}
      </strong>
    </p>
    <p>{Strings.tryLaterMessage}</p>
  </>
);

const ViewSteps = {
  Confirm: 1,
  Loading: 2,
  Success: 3,
  Error: 4
};

const BulkCreditPurchaseModal = ({
  isOpen,
  clinicId,
  currency,
  quantity,
  totalCost,
  createBulkCredits,
  onClose = () => { },
  onPurchased = () => { }
}) => {
  const [step, setStep] = useState(ViewSteps.Confirm);
  const [error, setError] = useState(null);
  const [purchased, setPurchased] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
    if (purchased) {
      // eslint-disable-next-line no-magic-numbers
      setTimeout(onPurchased, 200);
    }
  }, [onClose, onPurchased, purchased]);

  const onAddToInvoice = useCallback(() => {
    setStep(ViewSteps.Loading);
    setError(null);

    createBulkCredits(clinicId, { quantity }).then(() => {
      setStep(ViewSteps.Success);
      setPurchased(true);
    }).catch(error => {
      setError(error.message);
      setStep(ViewSteps.Error);
    });
  }, [clinicId, createBulkCredits, quantity]);

  useEffect(() => {
    if (isOpen) {
      setStep(ViewSteps.Confirm);
      setError(null);
      setPurchased(false);
    }
  }, [isOpen]);

  return (
    <Modal
      blur
      static={step === ViewSteps.Loading}
      isOpen={isOpen}
      onClose={handleClose}
      className="bulk-credits-purchase"
    >
      <Modal.Body>
        <div className="views-container">
          {step === ViewSteps.Confirm && (
            <ConfirmationView
              totalCost={totalCost}
              quantity={quantity}
              currency={currency}
              onConfirm={onAddToInvoice}
              onCancel={onClose}
            />
          )}
          {step === ViewSteps.Loading && (
            <LoadingView />
          )}
          {step === ViewSteps.Success && (
            <StatusView
              title={Strings.creditsAddedSuccessTitle}
              message={Strings.formatString(Strings.creditsAddedSuccessMessage, quantity)}
              statusModifier="success"
              icon="circle-check"
              onClose={handleClose}
            />
          )}
          {step === ViewSteps.Error && (
            <StatusView
              title={Strings.creditsAddedErrorTitle}
              message={(
                <ErrorMessage error={error} />
              )}
              statusModifier="error"
              icon="circle-xmark"
              onClose={onClose}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createBulkCredits: (clinicId, attributes) => {
      return dispatch(createBulkCreditsAsync(clinicId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(BulkCreditPurchaseModal);
