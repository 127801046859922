import api from '@/lib/api-request';
import { getCurrentClinicId } from '@/tokens';
import { updateInjuryAssessment } from '../actions/injury-assessments';

const createInjuryAssessmentAsync = (userId, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'injury_assessments', attributes };

  return (dispatch) => {
    return api.v1.post(`/users/${userId}/injury_assessments`, data, { query }).then(({ data: results }) => {
      dispatch(updateInjuryAssessment(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateInjuryAssessmentAsync = (userId, injuryId, assessmentId, attributes) => {
  const query = { current_clinic_id: getCurrentClinicId() };
  const data = { type: 'injury_assessments', id: assessmentId, attributes };

  return (dispatch) => {
    return api.v1.patch(`/users/${userId}/injuries/${injuryId}/injury_assessments/${assessmentId}`, data, { query }).then(({ data: results }) => {
      dispatch(updateInjuryAssessment(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuryAssessmentAsync = (userId, injuryId, assessmentId) => {
  return (dispatch) => {
    return api.v1.get(`/users/${userId}/injuries/${injuryId}/injury_assessments/${assessmentId}`).then(({ data: results }) => {
      dispatch(updateInjuryAssessment(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createInjuryAssessmentAsync,
  updateInjuryAssessmentAsync,
  getInjuryAssessmentAsync
};
