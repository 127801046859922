import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { If, Then, Else } from 'react-if';
import { useParams } from 'react-router-dom';
import BalanceTestChart from './chart';
import DateRangeSelector, { initialRange } from '../date-range-selector';
import TestTypeSelector from './test-type-selector';
import api from '@/lib/api-request';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { useMount } from '@/hooks';
import Strings from './lang';

const getBalanceTests = async (userId, attributes) => {
  const { data } = await api.v1.get(`/users/${userId}/eq_balance_tests`, attributes);
  return data;
};

const getBalanceTestBaseline = async (userId) => {
  const { data } = await api.v1.get(`/users/${userId}/eq_balance_tests/baseline`);
  return data;
};

const getAttributes = (range) => {
  const attributes = {
    after: dayjs(range.startDate).format('YYYY-MM-DD')
  };

  if (range.rangeKey === 'custom') {
    attributes.before = dayjs(range.endDate).format('YYYY-MM-DD');
  }

  return attributes;
};

const BalanceTests = () => {
  const params = useParams();
  const [testType, setTestType] = useState(null);
  const [dateRange, setDateRange] = useState(initialRange);
  const [loading, setLoading] = useState(true);
  const [balanceTests, setBalanceTests] = useState([]);
  const [baseline, setBaseline] = useState({});
  const [error, setError] = useState(null);

  const filteredTests = useMemo(() => {
    return testType === null ? balanceTests : balanceTests.filter(test => (
      test.post_injury === testType
    ));
  }, [testType, balanceTests]);

  const loadTests = useCallback((range) => {
    setLoading(true);
    setError(null);
    const attributes = getAttributes(range);

    getBalanceTests(params.userId, attributes).then((tests) => {
      setBalanceTests(tests);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [params.userId]);

  const onRangeChange = useCallback((range) => {
    setDateRange(range);
    loadTests(range);
  }, [loadTests]);

  useMount(() => {
    loadTests(dateRange);
    getBalanceTestBaseline(params.userId).then(baseline => {
      setBaseline(baseline);
    }).catch(() => {});
  });

  return (
    <div>
      <div className="balance-tests-header">
        <div>
          <h1>{Strings.balanceTestTitle}</h1>
        </div>
        <TestTypeSelector
          value={testType}
          onSelect={setTestType}
        />
        <DateRangeSelector
          range={dateRange}
          btnClassName="btn btn-light btn-sm"
          onChange={onRangeChange}
        />
      </div>
      <ErrorBanner error={error} />
      <Activity
        active={loading}
        static={!filteredTests.length}
        titleComponent={<h2>{Strings.loadingTestsMessage} ...</h2>}
      >
        <If condition={filteredTests.length > 0}>
          <Then>
            <BalanceTestChart
              baseline={baseline}
              tests={filteredTests}
            />
          </Then>
          <Else>
            <div>{Strings.testsEmptyMessage}</div>
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default BalanceTests;
