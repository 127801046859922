import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Icon from '@/components/icon';
import { createMfaMethodAsync } from '@/redux/thunks/mfa-method';
import ErrorBanner from '@/components/errors/error-banner';
import { getMethodTypeFullDescription, getMethodTypeLabel } from '../utils';
import Strings from '../lang';

const OPTION_ICON = {
  totp: 'qrcode',
  email: 'at'
};

const MfaOption = ({
  value,
  disabled = false,
  recommended = false,
  selected = false,
  errorMessage = null,
  onSelect
}) => {
  return (
    <label aria-disabled={disabled} className={classNames('method-option-label', { selected, disabled })}>
      <div className="radio-wrapper">
        <input
          type="radio"
          disabled={disabled}
          checked={selected}
          value={value}
          onChange={(e) => {
            onSelect(e.currentTarget.value);
          }}
        />
      </div>
      <div className="option-details-container">
        <Icon name={OPTION_ICON[value]} />
        <div className="option-title">
          <div className="option-title-text">{getMethodTypeLabel(value)}</div>
          {recommended && (
            <div className="label label-primary rounded">
              {Strings.recommendedLabel}
            </div>
          )}
        </div>
        <div>{getMethodTypeFullDescription(value)}</div>
        {errorMessage && (
          <div className="alert alert-danger">
            {errorMessage}
          </div>
        )}
      </div>
    </label>
  );
};

export const MfaMethodSelect = ({
  userId,
  emailConfirmed = false,
  onCancel = () => {},
  onContinue = () => {}
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSelectMethod = useCallback(() => {
    if (loading) return;

    setLoading(true);
    setError(null);

    dispatch(createMfaMethodAsync(userId, { method_type: selected })).then((method) => {
      setLoading(false);
      onContinue(method);
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch, loading, onContinue, selected, userId]);

  return (
    <div className="mfa-select-method-container">
      <h2 className="dot">{Strings.mfaSelectMethodTitle}</h2>
      <div>{Strings.mfaSelectMethodDescription}</div>
      <ErrorBanner error={error} />
      <div className="mfa-methods-container">
        <div role="radiogroup">
          <MfaOption
            recommended
            selected={selected === 'totp'}
            disabled={loading}
            value="totp"
            onSelect={setSelected}
          />
          <div role="separator" className="options-separator" />
          <MfaOption
            selected={selected === 'email'}
            disabled={!emailConfirmed || loading}
            value="email"
            errorMessage={!emailConfirmed
              ? Strings.emailNotConfirmedErrorMessage
              : null}
            onSelect={setSelected}
          />
        </div>
      </div>
      <div className="mfa-select-actions">
        <button
          type="button"
          className="btn btn-light"
          disabled={loading}
          onClick={onCancel}
        >
          {Strings.cancelButtonText}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!selected || loading}
          onClick={onSelectMethod}
        >
          <span>{Strings.continueButtonText}</span>&nbsp;&nbsp;
          {loading ? (
            <Icon
              spinPulse
              prefix="fad"
              name="loader"
            />
          ) : (
            <Icon name="arrow-right-long" />
          )}
        </button>
      </div>
    </div>
  );
};
